import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITour } from "./TourEndpoint";
import { ITourist } from "./TouristEndpoint";
import { ToursEndpoint } from "./ToursEndpoint";

export const TourCreateEndpoint = new DstSnRcHttp<
  ITour,
  ITour
>({
  method: HTTP_METHODS.post,
  url: '/tours',
  body: (tourist: ITourist) => tourist
})

TourCreateEndpoint.transformUpdateFor(
  ToursEndpoint,
  (oldData, newTourist) => [newTourist, ...oldData]
)
