import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export interface ITouristSmall {
  id: number
  surname: string       // фамилия
  first_name: string    // имя
  second_name: string   // отчество
  phone: string
  birthday: number
  archived: boolean,
  instagram: string,
  sex: string,
  status: string,
  telegram: string,
  tourist_comment: string
  vk: string,
  email: string
}

export const TouristsEndpoint = new DstSnRcHttp<
  null,
  ITouristSmall[]
>({
  method: HTTP_METHODS.get,
  url: '/tourists/all'
})
