import { Typography, List, Popover, ListItemButton, Radio, ListItem } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react'
import TextField from '../formik/TextField';
import MonthPicker from '../widgets/MonthPicker';
import DatePicker from '../widgets/DatePicker';

export const TouristBirthdayFilter = ({
  onClose,
  anchorEl,
  onThisTourAvailable,
  filterState,
  onChange,
  opened,
}) => {
  const { t } = useTranslation()

  const clickAge = useCallback(
    () => onChange({ ...filterState, by: filterState.by !== 'age' ? 'age' : null }),
    [filterState, onChange]
  )

  const clickBirthday = useCallback(
    () => onChange({ ...filterState, by: filterState.by !== 'birthday' ? 'birthday' : null }),
    [filterState, onChange]
  )

  const clickBirthdayMonth = useCallback(
    () => onChange({ ...filterState, by: filterState.by !== 'birthdayMonth' ? 'birthdayMonth' : null }),
    [filterState, onChange]
  )

  const clickThisTour = useCallback(
    () => onChange({ ...filterState, by: filterState.by !== 'thisTour' ? 'thisTour' : null }),
    [filterState, onChange]
  )

  const onChangeAgeStart = useCallback(
    e => onChange({ ...filterState, by: 'age', ageStart: e.target.value }),
    [filterState, onChange]
  )

  const onChangeAgeEnd = useCallback(
    e => onChange({ ...filterState, by: 'age', ageEnd: e.target.value }),
    [filterState, onChange]
  )

  const onChangeBirthdayStart = useCallback(
    moment => onChange({ ...filterState, by: 'birthday', birthdayStart: moment }),
    [filterState, onChange]
  )

  const onChangeBirthdayEnd = useCallback(
    moment => onChange({ ...filterState, by: 'birthday', birthdayEnd: moment }),
    [filterState, onChange]
  )

  const onChangeBirthdayMonthStart = useCallback(
    moment => onChange({
      ...filterState, 
      by: 'birthdayMonth', 
      birthdayMonthStart: moment,
      birthdayMonthEnd: filterState.birthdayMonthEnd ?? moment
    }),
    [filterState, onChange]
  )

  const onChangeBirthdayMonthEnd = useCallback(
    moment => onChange({
      ...filterState,
      by: 'birthdayMonth',
      birthdayMonthEnd: moment,
      birthdayMonthStart: filterState.birthdayMonthStart ?? moment
    }),
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItem>
          <Radio
            color='primary'
            checked={filterState.by === 'age'}
            onClick={clickAge}
          />
          <Linely centered>
            <Typography>
              {t('Common.age')}
            </Typography>
            <TextField
              sx={{
                width: 170
              }}
              size="small"
              type='number'
              label={t('Common.from.label')}
              value={filterState.ageStart}
              onChange={onChangeAgeStart}
            />
            <TextField
              sx={{
                width: 170
              }}
              size="small"
              type='number'
              label={t('Common.to.label')}
              value={filterState.ageEnd}
              onChange={onChangeAgeEnd}
            />
          </Linely>
        </ListItem>
        <ListItem>
          <Radio
            color='primary'
            checked={filterState.by === 'birthday'}
            onClick={clickBirthday}
          />
          <Linely centered>
            <Typography>
              {t('Common.birthday')}
            </Typography>
            <DatePicker
              disableFuture
              label={t('Common.from.label')}
              value={filterState.birthdayStart}
              onChange={onChangeBirthdayStart}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
            <DatePicker
              disableFuture
              label={t('Common.to.label')}
              value={filterState.birthdayEnd}
              onChange={onChangeBirthdayEnd}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
          </Linely>
        </ListItem>
        <ListItem>
          <Radio
            color='primary'
            checked={filterState.by === 'birthdayMonth'}
            onClick={clickBirthdayMonth}
          />
          <Linely centered>
            <Typography>
              {t('Tourist.filters.birthdayMonth.title')}
            </Typography>
            <MonthPicker
              label={t('Common.from.label')}
              value={filterState.birthdayMonthStart}
              onChange={onChangeBirthdayMonthStart}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
            <MonthPicker
              label={t('Common.to.label')}
              value={filterState.birthdayMonthEnd}
              onChange={onChangeBirthdayMonthEnd}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
          </Linely>
        </ListItem>
        {onThisTourAvailable && (
          <ListItemButton
            onClick={clickThisTour}
          >
            <Radio
              color='primary'
              checked={filterState.by === 'thisTour'}
            />
            <Typography>
              {t('Reserves.birthdayOfThisTour')}
            </Typography>
          </ListItemButton>
        )}
      </List>
    </Popover>
  )
}
