import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { IAuthUserEndpointData } from "./AuthUserEndpoint";

export const AuthTokenRefreshEndpoint = new DstSnRcHttp<
  null,
  IAuthUserEndpointData,
  null,
  any
>({
  method: HTTP_METHODS.post,
  url: '/auth/refresh',
  body: () => ({
    access: localStorage.getItem('accessToken'),
    refresh: localStorage.getItem('refreshToken')
  })
})
