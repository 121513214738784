import { useCallback, useRef, useState, useMemo, useLayoutEffect, MutableRefObject } from 'react'
import { Event } from '../libs/event'

function useRefState<T>(initialState: T): [T, MutableRefObject<T>, (val: T) => Promise<T>] {
  const [state, setState] = useState(initialState)
  const refState = useRef(state)

  const onSetState = useMemo(
    () => new Event<[T]>(),
    []
  )

  const changeState = useCallback(
    async (val: T) => {
      const ret = new Promise<T>(
        resolve => {
          onSetState.subscribeOnce(resolve)
        }
      )

      setState(val)
      refState.current = val

      return ret
    },
    [setState, onSetState]
  )

  useLayoutEffect(
    () => onSetState.invoke(state),
    [state, onSetState]
  )

  return [state, refState, changeState]
}

export default useRefState
