import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import Box from '../../tools/Components/atoms/Box';
import { getDateFormat1 } from '../../tools/utils/moment';
import { 
  tourDaysInfo, 
} from '../../utils/helpers';

export const TourDatesRenderer = ({ value: tour }) => {
  const { t } = useTranslation()

  const { startMoment, endMoment, diffDays, daysBefore } = tourDaysInfo(tour)

  return (
    <Box>
      <Typography variant='body1'>
        {getDateFormat1(startMoment)} - {getDateFormat1(endMoment)}
      </Typography>
      <Typography variant='body1' color='textSecondary'>
        {t('Tour.dates.diffDays', { count: diffDays })}
      </Typography>
      {daysBefore > 0 && (
        <Typography variant='body1' color='textSecondary'>
          {t('Tour.dates.daysBefore', { count: daysBefore })}
        </Typography>
      )}
    </Box>
  )
}
