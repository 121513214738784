import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITour, TourEndpoint } from "./TourEndpoint";
import { ToursEndpoint } from "./ToursEndpoint";

export const TourEditEndpoint = new DstSnRcHttp<
  [number, ITour],
  ITour
>({
  method: HTTP_METHODS.patch,
  url: ([id]) => `/tours/${id}`,
  body: ([id, tourist]: [number, ITour]) => tourist
})

TourEditEndpoint.transformUpdateFor(
  TourEndpoint,
  (oldData, newTourist) => oldData.id === newTourist.id ? newTourist : oldData
)

TourEditEndpoint.transformUpdateFor(
  ToursEndpoint,
  (oldData, newTourist) => oldData.map(t => t.id === newTourist.id ? newTourist : t)
)
