import { strToBool } from './string'
import { config } from './config'

class Logger {
  private isActive: boolean

  private defaultLog: (...data: any[]) => void
  private defaultWarn: (...data: any[]) => void
  private defaultError: (...data: any[]) => void

  private defaultMocker = () => {}

  constructor () {
    this.isActive = false

    this.defaultLog = console.log
    this.defaultWarn = console.warn
    this.defaultError = console.error

    let isActive: any = localStorage.getItem('logger')

    if (isActive !== 'true' && isActive !== 'false') {
      isActive = config.isDevelopment
    }

    this.setActive(strToBool(String(isActive)))

    //@ts-ignore
    window.logger = this
  }

  activate () {
    this.setActive(true)
  }

  deactivate () {
    this.setActive(false)
  }

  setActive (val: boolean) {
    localStorage.setItem('logger', val.toString())
    this.isActive = val

    if (this.isActive) {
      console.log = this.defaultLog
      console.warn = this.defaultWarn
      console.error = this.defaultError
    } else {
      console.log = console.warn = console.error = this.defaultMocker
    }
  }

  log (...args: any[]) {
    if (!this.isActive) return
    console.log(...args)
  }

  warn (...args: any[]) {
    if (!this.isActive) return
    console.warn(...args)
  }

  error (...args: any[]) {
    if (!this.isActive) return
    console.error(...args)
  }

  /* get msg () {
    return this.isActive ? console : {}
  } */
}

export const logger = new Logger()
