import useEnObserve from '../../tools/hooks/endpoints/useEnObserve';
import { Checkbox, List, ListItemButton, Popover, Typography } from '@mui/material'
import { useMemo, useCallback } from 'react'
import api from '../../endpoints';
import { 
  userToFullFio 
} from '../../utils/helpers';

export const TourEscortManagerFilter = ({
  onClose,
  anchorEl,
  typeUser,
  filterState,
  onChange,
  opened,
}) => {
  const [{ data }] = useEnObserve(api.tree.users.list, typeUser)

  const users = useMemo(
    () => data?.sort((a, b) => userToFullFio(a) > userToFullFio(b) ? 1 : -1),
    [data]
  )

  const clickItem = useCallback(
    id => () => onChange(
      filterState.indexOf(id) > -1 
        ? filterState.filter(fsId => fsId !== id)
        : [...filterState, id]
    ),
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        {users?.map(user => (
          <ListItemButton
            key={user.id}
            onClick={clickItem(user.id)}
          >
            <Checkbox
              color='primary'
              checked={filterState.indexOf(user.id) > -1}
            />
            <Typography>
              {userToFullFio(user)}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  )
}
