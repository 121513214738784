import { useMemo } from "react"

export const usePaginatedData = (data, rowsPerPage, currentPage) => {
  
  const paginatedData = useMemo(
    () => {
      const start = currentPage * rowsPerPage
      const end = (currentPage + 1) * rowsPerPage

      return data.slice(start, end)
    }, 
    [rowsPerPage, currentPage, data]
  )

  const totalPages = useMemo(
    () => Math.ceil(data.length / rowsPerPage),
    [data, rowsPerPage]
  )

  return [
    paginatedData,
    totalPages
  ]
}