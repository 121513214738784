import { Ref, useCallback } from 'react'

function useRefSync<T>(...refs: Ref<T>[]) {
  return useCallback(
    (value: T) => {
      for(const ref of refs) {
        if(typeof ref === 'function') {
          ref(value)
        }
        else if(!!ref) {
          //@ts-ignore
          // этот кек ридонли... какого...
          ref.current = value
        }
      }
    },
    // eslint-disable-next-line
    [...refs]
  )
}

export default useRefSync
