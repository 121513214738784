import paths from '../../paths'
import OneTourist from './OneTourist';
import PathMatch from '../../tools/Components/paths/PathMatch';
import TouristsList from './TouristsList';
import RightPanel from '../../ui/widgets/RightPanel';

const TouristsPage = () => {
  return (
    <>
      <TouristsList />
      <RightPanel>
        <PathMatch path={paths.root.c.tourists.c.one} Component={OneTourist} />
      </RightPanel>
    </>
  )
}

export default TouristsPage
