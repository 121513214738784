import { useLayoutEffect, useMemo, useRef, useCallback } from 'react'
import useRefState from '../../../tools/hooks/useRefState'

function useInOutState (outerState, defaultState, outerIsPrimary) {
  const initialized = useRef(false)
  const [innerState, refInnerState, setInnerState] = useRefState(
    useMemo(
      () => (typeof outerState === 'undefined' ? defaultState : outerState),
      // eslint-disable-next-line
      []
    )
  )

  const changeInnerState = useCallback(
    newVal => {
      // если outerState приоритетно и существует, внутреннее значение меняться не должно
      if (!outerIsPrimary || typeof outerState === 'undefined') {
        setInnerState(newVal)
      }
    },
    [setInnerState, outerState, outerIsPrimary]
  )

  useLayoutEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      return
    }

    setInnerState(outerState)
  }, [outerState, setInnerState])

  return [innerState, refInnerState, changeInnerState]
}

export default useInOutState
