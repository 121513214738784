import { autobind } from "core-decorators"
import { TConfigParamsableProperty, IEndpointBaseResponse, compilePropFunction } from ".."
import { DstSnRc, IDstSnRcConfig } from "../baseEndpoints/DstSnRc"
import { logger } from "../../../utils/logger"

export const DST_SN_RC_HTTP_DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
}

export interface IDstSnRcHttpResponse<TData, TError> extends IEndpointBaseResponse<TData, TError> {
    status: number
}

export enum HTTP_METHODS {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}

export interface IConfigDstSnRcHttp<
    TParams = any, 
    TData extends any = any,
    TError extends any = any, 
    TBody extends any = any,
    THeaders extends HeadersInit = HeadersInit,
    TResponse extends IDstSnRcHttpResponse<TData, TError> = IDstSnRcHttpResponse<TData, TError>,
> extends IDstSnRcConfig {
    baseUrl?: TConfigParamsableProperty<string, TParams>
    method?: TConfigParamsableProperty<HTTP_METHODS, TParams>
    headers?: TConfigParamsableProperty<THeaders, TParams>
    url: TConfigParamsableProperty<string, TParams>
    body?: TConfigParamsableProperty<TBody, TParams>
    bodyTransform?: (body?: TBody) => BodyInit
    fetchOptions?: TConfigParamsableProperty<RequestInit, TParams>
    responseTransform?: (
        originalResponse: Response,
        params: TParams,
        endpoint: DstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse, IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>>,
        localConfig?: IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>
    ) => Promise<TResponse>
    responsePostprocess?: (
        response: TResponse, 
        params: TParams,
        endpoint: DstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse, IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>>,
        localConfig?: IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>
    ) => Promise<TResponse>
}

@autobind
export class DstSnRcHttp<
    TParams = any, 
    TData extends any = any,
    TError extends any = any, 
    TBody extends any = any,
    THeaders extends HeadersInit = HeadersInit,
    TResponse extends IDstSnRcHttpResponse<TData, TError> = IDstSnRcHttpResponse<TData, TError>,
    TConfig extends IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>
        = IConfigDstSnRcHttp<TParams, TData, TError, TBody, THeaders, TResponse>
> extends DstSnRc<
    Response,
    TConfig,
    TParams, 
    TData, 
    TError,
    TResponse
> {
    protected get configName() {
        return 'DstSnRcHttp'
    }

    private makerequestParams(params: TParams, localConfig?: TConfig) {
        const baseUrl = compilePropFunction(this.getConfigProp('baseUrl', localConfig), params) || ''
        const url = compilePropFunction(this.getConfigProp('url', localConfig), params) || ''

        const realUrl = [baseUrl, url].join('')

        const body = compilePropFunction(this.getConfigProp('body', localConfig), params) as TBody | undefined
        const bodyTransform = this.getConfigProp('bodyTransform', localConfig)

        const bodyRequest = bodyTransform 
            ? bodyTransform(body) 
            : body 
                ? JSON.stringify(body) 
                : undefined

        const method = compilePropFunction(this.getConfigProp('method', localConfig), params) || HTTP_METHODS.get
        const headers = compilePropFunction(this.getConfigProp('headers', localConfig), params) || DST_SN_RC_HTTP_DEFAULT_HEADERS
        const fetchOptions = compilePropFunction(this.getConfigProp('fetchOptions', localConfig), params)

        const options: RequestInit = {
            // mode: 'cors',
            ...fetchOptions,
            headers,
            method,
            body: bodyRequest,
        }

        return {
            method,
            body,
            options,
            realUrl
        }
    }
    
    protected sendRequest(params: TParams, localConfig?: TConfig) {
        const { realUrl, options } = this.makerequestParams(params, localConfig)

        return fetch(realUrl, options)
    }
    
    protected async getResponse(fetchResponse: Response, params: TParams, localConfig?: TConfig) {
        let status = fetchResponse.status
        let data
        let error
        
        const responseTransform = this.getConfigProp('responseTransform', localConfig)
        const responsePostprocess = this.getConfigProp('responsePostprocess', localConfig)

        try {
            if(responseTransform) {
                //@ts-ignore
                const transformedResponse = await responseTransform(fetchResponse, params, this, localConfig)

                data = transformedResponse.data
                error = transformedResponse.error
            }
            else {
                const jsonResponse = await fetchResponse.json()
    
                const isError = status >= 400
    
                if (isError) {
                    error = jsonResponse
                }
                else {
                    data = jsonResponse
                }
            }

            if(responsePostprocess) {
                //@ts-ignore
                const postprocessedResponse = await responsePostprocess({ data, error, status } as TResponse, params, this, localConfig)

                status = postprocessedResponse.status
                data = postprocessedResponse.data
                error = postprocessedResponse.error
            }

        }
        catch(err) {
            error = err
        }

        return {
            status,
            data,
            error
        } as TResponse
    }

    protected log(
        isLoading: boolean,
        isMocker: boolean,
        isError: boolean,
        params: TParams,
        localConfig: TConfig | undefined,
        infoObject: any
    ) {
        const color = isLoading
            ? '#94e1ff'
            : isError
                ? '#e61700'
                : '#03b6fc'

        const { method, body, realUrl, options } = this.makerequestParams(params, localConfig)

        logger.log(
            `%c%s %s %s %o${body !== undefined ? '%s %o' : ''} ${!isLoading ? '%s %o ' : ''}%s %o`, 
            `color: ${color}; font-weight: 700;`, 

            `${this.configName} ${this.name}: ${isLoading ? 'loading' : isError ? 'error' : 'success'}${isMocker ? '-m' : ''}`,
            `\n${method} ${realUrl} ${infoObject.response?.status || ''}`,

            '\n- params:',
            params,
            ...(body !== undefined 
                ? [`\n- body:`, body]
                : []
            ),
            ...(!isLoading 
                ? [`\n- ${isError ? 'error' : 'data'}:`, isError ? infoObject.response!.error : infoObject.response!.data]
                : []
            ),
            '\n- info:',
            {
                fetchOptions: options,
                ...infoObject
            }
        )
    }
}
