import { autobind } from "core-decorators"
import { anyFirstLvlIsEqual } from "../../../utils/helpers"
import { DstSnRcDataUpdater } from "../utils"
import { EndpointBase, IEndpointBaseResponse, IEndpointBaseConfig } from "./EndpointBase"

export interface IDstRcConfig extends IEndpointBaseConfig {
}

@autobind
export abstract class DstRcReceiver<
    TConfig,
    TParams, 
    TData extends any,
    TError extends any,
    TResponse extends IEndpointBaseResponse<TData, TError>
> {
    private _params: TParams
    public get params() { return this._params }

    //@ts-ignore
    private _parent: DstRc<TConfig, TParams, TData, TError, TResponse>
    public get parent() { return this._parent }

    constructor(
        params: TParams, 
        //@ts-ignore
        parent: DstRc<TConfig, TParams, TData, TError, TResponse>,
    ) {
        this._params = params
        this._parent = parent
    }
    
    protected receive(response: TResponse) {
        this.parent.receive(this.params, response)
    }

    public mock(response: TResponse) {
        this.parent.mock(this.params, response)
    }
}

@autobind
export abstract class DstRc<
    TConfig extends IDstRcConfig,
    TParams extends any, 
    TData extends any,
    TError extends any,
    TResponse extends IEndpointBaseResponse<TData, TError>
> extends EndpointBase<TConfig, TParams, TData, TError> {
    //@ts-ignore
    private _dataUpdater: DstSnRcDataUpdater<TParams, TData>
    public get dataUpdater() { return this._dataUpdater }

    private _receivers: DstRcReceiver<TConfig, TParams, TData, TError, TResponse>[] = []
    public get receivers() { return this._receivers }
    
    constructor(
        config: TConfig = {} as TConfig
    ) {
        super(config)
    }

    protected abstract makeDstRcReceiver(
        params: TParams, 
        parent: DstRc<TConfig, TParams, TData, TError, TResponse>,
    ): DstRcReceiver<TConfig, TParams, TData, TError, TResponse>

    public subscribe(params: TParams) {
        const findedReceiver = this.receivers.find(
            r => anyFirstLvlIsEqual(params, r.params)
        ) as (DstRcReceiver<TConfig, TParams, TData, TError, TResponse> | undefined)

        if(findedReceiver) return findedReceiver

        const receiver = this.makeDstRcReceiver(params, this)

        this.receivers.push(receiver)

        return receiver
    }

    public receive(params: TParams, response: TResponse) {
        // const isError = !!response.error

        // если не ошибка - обновляем сопутствующие данные в updaters
        this.onReceiveInvoke(response, params)
    }

    public mock(params: TParams, response: TResponse) {
        this.receive(params, response)
    }
}
