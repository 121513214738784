import { useTranslation } from 'react-i18next';
import { MenuItem, Typography, Button } from '@mui/material'
import Linely from '../../../tools/Components/atoms/Linely';
import FilledSearch from '../../../ui/widgets/FilledSearch';
import api from '../../../endpoints';
import { useCallback, useMemo, useState } from 'react';
import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { parsePhone, userToFullFio } from '../../../utils/helpers';
import Box from '../../../tools/Components/atoms/Box';
import { touristFioSearcher } from '../../../ui/tableRenderers/TouristFioRenderer';
import { touristPhoneSearcher } from '../../../ui/tableRenderers/TouristPhoneRenderer';
import { TouristSexRenderer } from '../../../ui/tableRenderers/TouristSexRenderer';
import Centered from '../../../tools/Components/atoms/Centered';
import CreateTourist from '../../TouristsPage/Modals/CreateTourist';
import SelectField from '../../../ui/formik/SelectField';
import { useModal } from '../../../hooks/useModal';
import { getPersonYears, tsToMomentMoscow } from '../../../utils/moment';
import { getDateFormat1 } from '../../../tools/utils/moment';
import { DASH_LINE } from '../../../tools/utils/string';
import Loadable from '../../../ui/widgets/Loadable';

const TouristReserveFieldValue = ({ tourist, ...props }) => {
  const { t } = useTranslation()

  return (
    <Linely>
      <TouristSexRenderer
        value={tourist}
      />
      <Box>
        <Typography>
          {userToFullFio(tourist)}
        </Typography>
        <Typography color='textSecondary'>
          {parsePhone(tourist.phone)}{` ${DASH_LINE} `}
          {getDateFormat1(tsToMomentMoscow(tourist.birthday))}{' '}
          ({t('Common.personAge', { count: getPersonYears(tsToMomentMoscow(tourist.birthday)) })})
        </Typography>
      </Box>
    </Linely>
  )
}

const TouristReserveField = ({ field: { name, value }, form: { setFieldValue }, label, ...props }) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState()

  const [elementCreateTourist, openCreateTourist] = useModal(CreateTourist)

  const [{ data: tourists, isLoading }] = useEnObserve(api.tree.tourists.list)

  const searchedData = useMemo(
    () => (tourists || [])
      .filter(
        tourist => searchValue 
          ? (
            touristFioSearcher(searchValue, tourist) ||
            touristPhoneSearcher(searchValue, tourist)
          )
          : true
      )
      .slice(0, 10),
    [searchValue, tourists]
  )

  const handleCreateTourist = useCallback(
    tourist => {
      setFieldValue(
        name,
        tourist.id
      )
    },
    [setFieldValue, name]
  )

  const handleChange = useCallback(
    (e) => {
      setFieldValue(
        name,
        e.target.value
      )
    },
    [setFieldValue, name]
  )

  return (
    <>
      {elementCreateTourist}
      <Loadable
        noCentered
        loading={!tourists && isLoading}
        empty={!tourists}
      >
        {() => (
          <SelectField
            {...props}
            // без свойства key только что созданный турист не показывается
            key={value}
            value={value}
            label={label}
            onChange={handleChange}
            name='status_payment'
            labelId="demo-simple-select-filled-label2"
            renderValue={id => {
              const tourist = tourists.find(tourist => id === tourist.id)

              return tourist && (
                <TouristReserveFieldValue
                  tourist={tourist}
                />
              )
            }}
          >
            <Box
              onKeyDown={(e) => e.stopPropagation()}
              p='i2'
            >
              <FilledSearch
                value={searchValue}
                onChange={setSearchValue}
              />          
            </Box>
            {searchedData.map(tourist => (
              <MenuItem
                key={tourist.id}
                value={tourist.id}
              >
                <TouristReserveFieldValue
                  tourist={tourist}
                />
              </MenuItem>
            ))}
            <Centered
              p='i2'
            >
              <Button
                variant='contained'
                onClick={() => openCreateTourist({ onSubmit: handleCreateTourist })}
              >
                {t('Common.createNew')}
              </Button>
            </Centered>
          </SelectField>
        )}
      </Loadable>
    </>
  )
}

export default TouristReserveField
