import FixedYBackground from '../../ui/widgets/FixedYBackground';
import logoBackground from '../../static/images/logo-background.svg'
import useOnEvent from '../../tools/hooks/event/useOnEvent';
import { useEffect, useState, useRef, useCallback, useMemo, useContext } from 'react'
import { browserResizeEvent } from '../../tools/libs/event';
import { Paper, Typography, Button, TextField } from '@mui/material';
import Centered from '../../tools/Components/atoms/Centered';
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import SubmitButtonWrapper from '../../tools/Components/formik/SubmitButtonWrapper';
import { UserContext } from '../../app/UserProvider';
import * as Yup from 'yup'

const schema = Yup.object().shape({
  username: Yup.string().required('Validation.required'),
  password: Yup.string().required('Validation.required')
})

export const phonePreprocessToServer = phone => {
  // Пока что у нас только номера телефонов из рф и больше ничего
  // позже - переделать это хз как
  // Вообще по хорошему эту предобработку лучше бы на бэк перенести

  console.log('45er56rtgf', phone)
  let phoneProcessed = phone.replace(/[^0-9]/g, '')

  if(phoneProcessed[0] === '8') {
    return '7' + phoneProcessed.slice(1)
  }

  return phoneProcessed
}

export const phonePreprocessToFront = phone => {
  if(phone[0] === '8') {
    return '+7' + phone.slice(1)
  }
  else {
    return '+' + phone
  }
}

const LoginPage = () => {
  const { t } = useTranslation()

  const { login } = useContext(UserContext)

  const [contentWidth, setContentWidth] = useState(0)
  
  const refContent = useRef()

  const handleLogin = useCallback(
    async ({ username, password }, { setSubmitting }) => {
      await login(phonePreprocessToServer(username), password)

      setSubmitting(false)
    },
    [login]
  )
  
  useOnEvent(
    // eslint-disable-next-line
    useMemo(() => browserResizeEvent(refContent.current), [refContent.current]),
    useCallback(
      () => {
        refContent.current && setContentWidth(refContent.current.offsetWidth)
      },
      []
    )
  )

  useEffect(
    () => {
      refContent.current && setContentWidth(refContent.current.offsetWidth)
    },
    [setContentWidth]
  )
  
  return (
    <Centered
      ref={refContent}
      position='relative'
      width='100%'
      height='100vh'
    >
      <FixedYBackground
        position='absolute'
        top={0}
        right={0}
        bottom={0}
        left={0}
        img={logoBackground}
        opacity={0.015}
        width={700}
        height={700}
        scale={2}
        translateX={`${contentWidth/3 - 300}px`}
        translateY='90px'
        zIndex={1}
      />
      <Paper
        elevation={0}
        sx={{
          width: 500,
          zIndex: 2
        }}
      >
        <Formik
          initialValues={{}}
          onSubmit={handleLogin}
          validationSchema={schema}
        >
          <Linely
            p='i1'
            gap='i1'
            vertical
          >
            <Typography variant='h6'>
              {t('Login.title')}
            </Typography>
            <Field
              name='username'
              label={t('Common.phone')}
              variant="filled"
              as={TextField}
            />
            <Field
              name='password'
              type='password'
              label={t('Common.password')}
              variant="filled"
              as={TextField}
            />
            <Linely
              vertical
              centered
            >
              <SubmitButtonWrapper>
                <Button size='large' variant='contained'>
                  {t('Login.login')}
                </Button>
              </SubmitButtonWrapper>
            </Linely>
          </Linely>
        </Formik>
      </Paper>
    </Centered>
  )
}

export default LoginPage
