import paths from '../../paths'
import PathCase from '../../tools/Components/paths/PathCase';
import PathMatch from '../../tools/Components/paths/PathMatch';
import PathSwitch from '../../tools/Components/paths/PathSwitch';
import TourLeftPage from './TourLeftPage';
import TourPage from './TourPage';
import ToursList from './ToursList';
import CancelPage from './Cancels/CancelPage';
import WaitPage from './Waits/WaitPage';
import ReservePage from './Reserves/ReservePage';

const ToursPage = () => {
  return (
    <>
      <PathMatch path={paths.root.c.tours} exact Component={ToursList} />
      <PathMatch path={paths.root.c.tours.c.one} Component={TourLeftPage} />
      <PathSwitch>
        <PathMatch path={paths.root.c.tours.c.one.c.cancels.c.one} Component={CancelPage} />
        <PathMatch path={paths.root.c.tours.c.one.c.waits.c.one} Component={WaitPage} />
        <PathMatch path={paths.root.c.tours.c.one.c.reserves.c.one} Component={ReservePage} />
        <PathCase path={paths.root.c.tours.c.one} Component={TourPage} />
      </PathSwitch>
    </>
  )
}

export default ToursPage
