import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourist, TouristEndpoint } from "./TouristEndpoint";
import { TouristsEndpoint } from "./TouristsEndpoint";

export const TouristEditEndpoint = new DstSnRcHttp<
  [number, ITourist],
  ITourist
>({
  method: HTTP_METHODS.patch,
  url: ([id]) => `/tourists/${id}`,
  body: ([id, tourist]: [number, ITourist]) => tourist
})

TouristEditEndpoint.transformUpdateFor(
  TouristEndpoint,
  (oldData, newTourist) => oldData.id === newTourist.id ? newTourist : oldData
)

TouristEditEndpoint.transformUpdateFor(
  TouristsEndpoint,
  (oldData, newTourist) => oldData.map(t => t.id === newTourist.id ? newTourist : t)
)
