import { autobind } from "core-decorators";
import { PathRouter } from "../";

@autobind
export class PathUrlStore {
    protected _history: string[]
    protected _historyPosition: number

    protected _router: PathRouter<any> | undefined
    public get router() {
        return this._router
    }

    public get url() {
        return this._history[this._historyPosition]
    }

    public constructor(initUrl: string) {
        this._history = [initUrl]
        this._historyPosition = 0
    }

    public pushUrl(url: string) {
        this._history = [...this._history.slice(0, this._historyPosition + 1), url]
        this._historyPosition++
    }

    public replaceUrl(url: string) {
        this._history[this._historyPosition] = url
    }
    
    public goBack() {
        if(this._historyPosition <= 0) {
            return
        }

        this._historyPosition--
    }
    
    public goNext() {
        if(this._historyPosition >= this._history.length) {
            return
        }
        
        this._historyPosition++
    }

    public __applyRouter(router: PathRouter<any>) {
        this._router = router
    }
}
