import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITouristSmall } from "./TouristsEndpoint";

export interface ITourist extends ITouristSmall {
}

export const TouristEndpoint = new DstSnRcHttp<
  number,
  ITourist
>({
  method: HTTP_METHODS.get,
  url: (id) => `/tourists/${id}`
})
