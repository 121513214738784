import { Typography } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react'
import Box from '../../tools/Components/atoms/Box';
import { findStrInArray } from '../../tools/utils/array';
import { DASH_LINE } from '../../tools/utils/string';
import { getPersonYears, tsToMomentMoscow } from '../../utils/moment';
import { getDateFormat1 } from '../../tools/utils/moment';
import {
  touristIsBirthdayInTour,
} from '../../utils/helpers';
import Icon from '../widgets/Icon';
import CakeIcon from '@mui/icons-material/Cake';

export const touristBirthdaySearcher = (searchStr, tourist) => 
  tourist.birthdayMomentFormated && findStrInArray([tourist.birthdayMomentFormated], searchStr)

export const TouristBirthdayRenderer = ({ 
  value: tourist,
  column: { tourDates }
}) => {
  const { t } = useTranslation()

  const touristIsBirthday = useMemo(
    () => tourDates && tourist.birthday && touristIsBirthdayInTour(tourDates, tourist),
    [tourDates, tourist]
  )

  return tourist.birthday
    ? (
      <Linely vertical gap={0}>
        <Typography variant='body1'>
          {getDateFormat1(tsToMomentMoscow(tourist.birthday))}
        </Typography>
        <Linely
          centered
        >
          <Typography variant='body2' color='textSecondary'>
            ({t('Common.personAge', { count: getPersonYears(tsToMomentMoscow(tourist.birthday)) })})
          </Typography>
          {touristIsBirthday && (
            <Box
              mt='-3px'
            >
              <Icon
                small
                Component={CakeIcon}
                color='primary'
              />
            </Box>
          )}
        </Linely>
      </Linely>
    )
    : (
      <Typography variant='body1'>
        {DASH_LINE}
      </Typography>
    )
}
