import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import Box from '../../tools/Components/atoms/Box';

export const TouristSexRenderer = ({ value: tourist }) => (
  <Box>
    {tourist.sex === 'М'
      ? (
        <FaceIcon />
      )
      : (
        <Face2Icon />
      )
    }
  </Box>
)
