export class PathRouterHooksSomeIsNotDefinedError extends Error {
    constructor(errorPoint: string) {
        super(`${errorPoint} error. PathNode and PathRouter is not defined. Use this hook only through PathMatch or PathCase or PathRouterProvider`)
    }
}

export class PathRouterHooksPathCaseIsNotDefinedError extends Error {
    constructor(errorPoint: string) {
        super(`${errorPoint} error. PathNode is not defined. Use this hook only through PathMatch or PathCase`)
    }
}

export class PathRouterComponentPathTypeError extends Error {
    constructor(errorPoint: string, receivedType: string) {
        super(`${errorPoint} error. Path was expected "PathNode", but received was "${receivedType}"`)
    }
}