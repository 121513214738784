import { TableHead, TableRow, TableCell, Checkbox, Typography } from '@mui/material'
import clsx from 'clsx'

function TableViewHead ({
  multipleSelection,
  selectable,
  numSelected,
  maxSelectCount,
  onSelectAllClick,
  columns,
  columnCell,
  size,
  isSelectAllDisabled,
  isSelectAllHidden
}) {
  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding='none'>
            {multipleSelection && !isSelectAllHidden && maxSelectCount > 0 && (
              <Checkbox
                color='primary'
                indeterminate={numSelected > 0 && numSelected < maxSelectCount}
                checked={numSelected === maxSelectCount}
                onChange={onSelectAllClick}
                disabled={isSelectAllDisabled}
              />
            )}
          </TableCell>
        )}
        {columns.map((column, i) => (
          <TableCell
            {...column.headerCellProps}
            className={clsx(
              column.headerCellProps?.className,
            )}
            key={i}
            align={column.numeric ? 'right' : 'left'}
            padding={column.padding}
            size={column.size}
          >
            {column.columnView
              ? column.columnView(column)
              : columnCell
                ? columnCell(column)
                : typeof column.label === 'string'
                  ? (
                    <Typography variant='body2'>
                      {column.label}
                    </Typography>
                  )
                  : column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableViewHead
