/**
 * Функция для проверки жизненно важных условий,
 * выкидывает Error если что-то не так
 *
 * @example
 * assert(variable != null, 'variable must not be null')
 *
 * @export
 * @param {boolean} condition - условие, которое обязательно должно быть выполнено
 * @param {string | Error} messageOrError - сообщение об ошибке, описывающее, что не так. Либо сама ошибка
 */
export const assert = (condition: boolean, messageOrError: string | Error): condition is true => {
  if (!condition) {
    throw messageOrError instanceof Error ? messageOrError : new Error(messageOrError || 'Assertion failed.')
  }

  return condition
}
