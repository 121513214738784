import md5 from 'js-md5'

// заюзать для генерации пользовательских аватаров
export const stringAndThemeToHslColor = (id, theme) => {
  // TODO: лучше генериь Saturation и Lightness в зависимости от фонового цвета
  const { s, l } =
    theme.palette.mode === 'light' ? { s: 60, l: 60 } : { s: 60, l: 70 }

  const md5str = md5(id?.toString() ?? 'undefined')

  let hash = 0
  for (let i = 0; i < md5str.length; i++) {
    hash = ((hash << 3) | (md5str.charCodeAt(i) + hash)) + hash
  }

  const h = hash % 360
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

export const truncateIfBig = (str, max, ender = '...') => {
  if (str == null) return null
  const string = String(str)
  return string.length > max ? `${string.slice(0, max)}${ender}` : string
}

export const maxNumber = (number, max) => number > max ? `${max}+` : number.toString()

export const maxNumber99 = (number) => maxNumber(number, 99)

// строка вида: http://some.thing.com/.../.../.../.../another.thing => another.thing
// идея в том чтобы оставить 2 последних слова [\w]+\.[\w]+&
export const getFileNameFromFullPath = url => url.match(/[\w]+\.[\w]+$/)

export const strToBool = str => (str || '').trim().toLowerCase() === 'true'

export const stringContainsSubstring = (str, substr) => String(str)
  .toLowerCase()
  .indexOf(String(substr).toLowerCase()) > -1
