import api from '../../../../endpoints';
import router from '../../../../paths';
import { remove } from '../../../../tools/libs/paths/helpers';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import { Formik } from 'formik';
import ReserveModalForm from './ReserveModalForm';
import usePathParams from '../../../../tools/hooks/paths/usePathParams';
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import moment from 'moment';
import { reserveFormSchema } from './reserveFormSchema';

const CreateReserve = ({ ...props }) => {
  const [{ createReserve }] = usePathParams()
  const { tourId } = createReserve || {}

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const onClose = useCallback(
    ({ id_tour, tourist }) => router.pushUrl(router.getUrl([
        [router.root.c.createReserve, remove()],
        ...(id_tour ? [[router.root.c.tours.c.one.c.reserves.c.one, { tourId: id_tour, touristId: tourist.id }]] : [])
      ]
    )),
    []
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { data, error } = await api.tree.reserves.create.request([
        tourId,
        values.touristId,
        {
          ...values,
          booking_date: moment().unix()
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      onClose(data)
    },
    [tourId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Reserve.createModal.title'
      lSubmitLable='Common.create'
      withoutBtns
    >
      <Formik
        initialValues={{}}
        validationSchema={reserveFormSchema}
        onSubmit={onSubmit}
      >
        {tour && (
          <ReserveModalForm
            price={tour.price}
            lSubmitLable='Common.create'
            onClose={onClose}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateReserve
