import Icon from "./Icon"
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import ForwardIcon from '@mui/icons-material/Forward';
import { forwardRef } from "react";

const CancelStatus = forwardRef(({ status, ...props }, ref) => {
  
  return (
    <Icon
      {...props}
      ref={ref}
      color='error'
      Component={status === 'cancellation' ? DoDisturbOnIcon : ForwardIcon}
    />
  )
})

export default CancelStatus
