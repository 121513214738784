import { autobind } from 'core-decorators'
import { BrowserEvent } from './BrowserEvent'

@autobind
export class CustomOptionsEvent extends BrowserEvent {

  public constructor(
    element: HTMLElement
  ) {
    super(element, 'contextmenu')
  }

  protected override onEvent(e: Event) {
    super.onEvent(e)

    e?.preventDefault()
    e?.stopPropagation()
  }
}

export const customOptionsEvent = (
  element: HTMLElement,
) => new CustomOptionsEvent(element)
