import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITour } from "./TourEndpoint";
import { ToursEndpoint } from "./ToursEndpoint";

export const TourArchiveEndpoint = new DstSnRcHttp<
  number,
  ITour
>({
  method: HTTP_METHODS.patch,
  url: id => `/tours/${id}/archive`,
})

TourArchiveEndpoint.transformUpdateFor(
  ToursEndpoint,
  (oldData, newTourist) => oldData.map(t => t.id === newTourist.id ? newTourist : t)
)
