import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const getBrowserLanguage = () => (
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage
).toLowerCase().split(/[_-]+/)[0]

export const createLanguages = (locales, defaultLanguage = 'en', force = false) =>
  i18n
    .use(initReactI18next)
    .init({
      // debug: true,
      resources: locales,
      lng: force ? defaultLanguage : (localStorage.getItem('language') || getBrowserLanguage() || defaultLanguage),
      fallbackLng: defaultLanguage,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      }
    })

export const changeLanguage = (language) => {
  localStorage.setItem('language', language) // eslint-disable-line
  i18n.changeLanguage(language)
}
