import { useLayoutEffect, useMemo, useState } from 'react'
//@ts-ignore
import { DstSnRc, IDstSnRcConfig, IDstSnRcResponse } from '../../libs/endpoints/baseEndpoints/DstSnRc'
import { IEndpointObserverState } from '../../libs/endpoints/utils/EndpointObserver'
//@ts-ignore
import { TEndpointParams } from '../../libs/endpoints'

    //@ts-ignore
function useEnObserve<
    TParams extends TEndpointParams, 
    TData extends any,
    TError extends any,
    TResponse extends IDstSnRcResponse<TData, TError>,
    //@ts-ignore
    TConfig extends IDstSnRcConfig<TResponse>
>(
    //@ts-ignore
    endpoint: DstSnRc<TParams, TData, TError, TResponse, TConfig>,
    params: TParams,
    config?: TConfig,
    requestValid: boolean = true,
    ignoreCache: boolean = false
) {
    const [state, setState] = useState<IEndpointObserverState<TData, TError>>(
        { 
            isLoading: true,
            data: undefined, 
            error: undefined 
        } as IEndpointObserverState<TData, TError>
    )

  const observer = useMemo(
      () => requestValid ? endpoint.observe(params, config) : null,
      [endpoint, params, config, requestValid]
  )

  useLayoutEffect(
      () => {
        if(observer) {
            setState(observer.state)
        }

        observer?.onUpdate.subscribe(setState)

        if(observer && !observer.isLoading && ignoreCache) {
            observer.reload()
        }

        return () => {
            observer?.onUpdate.unsubscribe(setState)
        }
      },
      [endpoint, observer, setState, ignoreCache]
  )

  return [state, observer?.reload] as const
}

export default useEnObserve
