import { Typography, Tooltip } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import {
  getReserveStatusIsValid, 
  getReserveStatusLocaleKey, 
} from '../../utils/helpers';
import { DASH_LINE } from '../../tools/utils/string';
import ReserveStatus from '../widgets/ReserveStatus';
import { usePercentWidth } from '../../hooks/usePercentWidth';
import { getScreenWidthValue } from '../widgets/ThemeProvider';

export const ReserveStatusRenderer = ({ value: reserve }) => {
  const { t } = useTranslation()

  const isXl = getScreenWidthValue(usePercentWidth())


  return !getReserveStatusIsValid(reserve.status_payment)
    ? (
      <Typography>
        {DASH_LINE}
      </Typography>
    )
    : (
      <Linely>
        <Tooltip
          title={t(getReserveStatusLocaleKey(reserve.status_payment))}
        >
          <div>
            <ReserveStatus
              status={reserve.status_payment}
            />
          </div>
        </Tooltip>
        {isXl && (
          <Typography>
            {t(getReserveStatusLocaleKey(reserve.status_payment))}
          </Typography>
        )}
      </Linely>
    )
}
