import { Typography } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { findStrInArray } from '../../tools/utils/array';
import { DASH_LINE } from '../../tools/utils/string';

export const touristSocialNetworksSearcher = (searchStr, { telegram, instagram, email, vk }) => 
  findStrInArray([telegram, instagram, email, vk], searchStr)

export const TouristSocialNetworksRenderer = ({ value: tourist }) => (
  <Linely
    vertical
    gap={0}
    maxWidth={150}
  >
    {tourist.telegram && (
      <Typography
        noWrap
      >
        {`tg: ${tourist.telegram}`}
      </Typography>
    )}
    {tourist.instagram && (
      <Typography
        noWrap
      >
        {`inst: ${tourist.instagram}`}
      </Typography>
    )}
    {tourist.email && (
      <Typography
        noWrap
      >
        {`wa: ${tourist.email}`}
      </Typography>
    )}
    {tourist.vk && (
      <Typography
        noWrap
      >
        {`vk: ${tourist.vk}`}
      </Typography>
    )}
    {(!tourist.telegram && !tourist.instagram && !tourist.vk && !tourist.email) && (
      <Typography>
        {DASH_LINE}
      </Typography>
    )}
  </Linely>
)
