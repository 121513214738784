import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FormRequiredFieldsHelper = () => {
  const { t } = useTranslation()

  return (
    <Typography>
      <Typography display='inline' color='error'>*</Typography> - {t('Validation.required.fields')}
    </Typography>
  )
}

export default FormRequiredFieldsHelper
