import Box from '../../tools/Components/atoms/Box';
import useBoolean from '../../tools/hooks/useBoolean';
import LeftDrawer from './LeftDrawer';
import TopToolbar from './TopToolbar';

function MainPage({ children }) {
  const [drawerAnchor, , , openDrawer, closeDrawer] = useBoolean(false)

  return (
    <>
      <LeftDrawer
        drawerAnchor={drawerAnchor}
        closeDrawer={closeDrawer}
      />
      <TopToolbar
        openDrawer={openDrawer}
      />
      <Box
        flex
        mt={64 / 8}
        minWidth={1200}
      >
        {children}
      </Box>
    </>
  );
}

export default MainPage;
