import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Icon from '../widgets/Icon';
import Clickly from '../../tools/Components/atoms/Clickly';

export const TourLinkRenderer = ({ value: tour }) => tour.site_link
  ? (
    <Clickly href={tour.site_link} target='_blank'>
      <Icon
        Component={LinkIcon}
        color='primary'
      />
    </Clickly>
  )
  : (
    <Icon
      Component={LinkOffIcon}
    />
  )
