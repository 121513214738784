import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourSmall } from "./ToursEndpoint";

export interface ITour extends ITourSmall {
}

export const TourEndpoint = new DstSnRcHttp<
  number,
  ITour
>({
  method: HTTP_METHODS.get,
  url: (id) => `/tours/${id}`
})
