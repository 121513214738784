import api from '../../../../endpoints';
import router from '../../../../paths';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback, useMemo } from 'react'
import { Field, Formik, useFormikContext } from 'formik';
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import TextField from '../../../../ui/formik/TextField';
import SelectField from '../../../../ui/formik/SelectField';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Box from '../../../../tools/Components/atoms/Box';
import Linely from '../../../../tools/Components/atoms/Linely';
import ReserveStatus from '../../../../ui/widgets/ReserveStatus';
import { getRepayment, userToFullFio } from '../../../../utils/helpers';
import SubmitButtonWrapper from '../../../../tools/Components/formik/SubmitButtonWrapper';
import FormRequiredFieldsHelper from '../../../../ui/widgets/FormRequiredFieldsHelper';
import { reserveFormSchema } from '../../Reserves/Modals/reserveFormSchema';

const WaitToReserveModalForm = ({ tourist, tour, price, onClose }) => {
  const { t } = useTranslation()

  const { values: { paid } } = useFormikContext()

  const prepayment = getRepayment(price)

  const remainder = typeof paid === 'number' ? price - paid : '?'

  return (
    <>
      <Box
        px='i1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <Trans
                i18nKey='Wait.moveToReserve.message'
                values={{ tourist: userToFullFio(tourist), tour: tour.title }}
                components={[
                  <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />,
                  <Typography key={1} display='inline' variant='subtitle1' color='textPrimary' />,
                ]}
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              required
              name='status_payment'
              label={t('Reserve.status')}
              as={SelectField}
            >
              <MenuItem value='full'>
                <Linely>
                  <ReserveStatus status='full' />
                  <Typography>
                    {t('Reserve.status.full')}
                  </Typography>
                </Linely>
              </MenuItem>
              <MenuItem value='prepayment'>
                <Linely>
                  <ReserveStatus status='prepayment' />
                  <Typography>
                    {t('Reserve.status.prepayment')}
                  </Typography>
                </Linely>
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              required
              name='paid'
              type='number'
              label={t('Common.payed')}
              variant="filled"
              as={TextField}
              InputProps={{
                endAdornment: (
                  <Typography
                    align='right'
                    display='inline'
                    color='textSecondary'
                    minWidth='fit-content'
                  >
                    <Trans
                      i18nKey='Reserve.paid'
                      values={{ price, prepayment, remainder }}
                      components={[
                        <br />
                      ]}
                    />
                  </Typography>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='tickets'
              label={t('Reserve.tickets.title')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='comment'
              label={t('Common.comment')}
              variant="filled"
              as={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        px='i1'
      >
        <FormRequiredFieldsHelper />
      </Box>
      <Linely
        end
        gap='i2'
        px='i1'
      >
        <Button
          variant="contained"
          size='large'
          color='inherit'
          onClick={onClose}
        >
          {t('Common.close')}
        </Button>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size='large'
          >
            {t('Wait.moveToReserve')}
          </Button>
        </SubmitButtonWrapper>
      </Linely>
    </>
  )
}

const WaitToReserveModal = ({ tourId, touristId, onClose, ...props }) => {
  const [{ data }] = useEnObserve(api.tree.reserves.one, [tourId, touristId], null, !!touristId)

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const initialValues = useMemo(
    () => data && ({
      ...data,
      touristId,
      status_payment: null,
    }),
    [touristId, data]
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { error } = await api.tree.reserves.edit.request([
        tourId,
        touristId,
        {
          ...values,
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one.c.reserves.c.one, { tourId, touristId }]
      ]))

      onClose()
    },
    [tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Wait.moveToReserve'
      lSubmitLable='Common.save'
      withoutBtns={!!initialValues}
    >
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={reserveFormSchema}
          onSubmit={onSubmit}
        >
          {tour && data && (
            <WaitToReserveModalForm
              tourist={data.tourist}
              tour={tour}
              price={tour.price}
              onClose={onClose}
            />
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default WaitToReserveModal
