import { useCallback, useMemo, useState } from "react"

export const useModal = (Component) => {
  const [modalData, setModalData] = useState()

  const handleOpen = useCallback(
    (props = {}) => setModalData(props),
    []
  )

  const handleClose = useCallback(
    () => setModalData(null),
    []
  )

  const element = useMemo(
    () => !!modalData && (
      <Component
        {...modalData}
        opened={!!modalData}
        onClose={handleClose}
      />
    ),
    [modalData, handleClose]
  )

  return [
    element,
    handleOpen,
    handleClose
  ]
}