import { Typography } from '@mui/material'
import 'moment'
import moment from 'moment-timezone'
import Bowser from 'bowser'
import Linely from '../../tools/Components/atoms/Linely'

export const ErrorData = ({ error }) => {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent)

  return (
    <Linely vertical>
      <Typography
        variant='caption'
        color='textSecondary'
      >
        {moment().tz('Europe/Moscow').format('DD.MM.YYYY HH:mm:ss:SSSS (Z)')}
      </Typography>
      <Typography
        variant='caption'
        color='textSecondary'
      >
        browser: {browser.name} - {browser.version} / os: {os.name} - {platform.type} - {os.version}
      </Typography>
      <Typography
        variant='caption'
        color='textSecondary'
      >
        {error.message}
      </Typography>
      <Typography
        component='pre'
        style={{ fontSize: 9 }}
        variant='caption'
        color='textSecondary'
      >
        {error.stack}
      </Typography>
    </Linely>
  )
}

export default ErrorData
