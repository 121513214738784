import { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import usePrevious from '../../hooks/usePrevious'
import { sleep } from '../../utils/helpers'
import { mbComponentFunctionalElement } from '../../utils/react'

function OpenDelayable (
  {
    opened,
    Component,
    children,
    showAllways,
    showOnlyOpened,
    noFreezePropsOnRemove,
    removeOnCloseDelay = 1000,
    ...props
  },
  ref
) {
  const refFreezedProps = useRef(null)

  const [mustShowed, setMustShowed] = useState(false)

  const prevProps = usePrevious({ ...props, ref })

  useLayoutEffect(
    () => {
      if (showAllways || showOnlyOpened) {
        return
      }

      if (opened) {
        setMustShowed(true)
        return
      }

      const cb = async () => {
        refFreezedProps.current = null
        await sleep(removeOnCloseDelay)

        setMustShowed(false)
      }

      if (!noFreezePropsOnRemove) {
        refFreezedProps.current = prevProps
      }
      cb()
    },
    [
      prevProps,
      showOnlyOpened,
      showAllways,
      noFreezePropsOnRemove,
      opened,
      setMustShowed,
      removeOnCloseDelay
    ]
  )

  return (showOnlyOpened ? opened : (showAllways || opened || mustShowed)) && mbComponentFunctionalElement(
    Component,
    children,
    refFreezedProps.current ?? { ...props, ref, opened }
  )
}

export default forwardRef(OpenDelayable)
