import { useCallback, useMemo, useState } from "react"
import { browserResizeEvent } from "../tools/libs/event"
import useOnEvent from "../tools/hooks/event/useOnEvent"

export const usePercentWidth = (percentValue = 1) => {
  const [clientWidth, setClientWidth] = useState(0)

  useOnEvent(
    useMemo(() => browserResizeEvent(document.documentElement), []),
    useCallback(
      () => {
        setClientWidth(Math.max(1200, document.documentElement.clientWidth))
      },
      []
    )
  )

  return percentValue * clientWidth
}