import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { IReserve } from "./ReserveEndpoint";
import { ReservesEndpoint } from "./ReservesEndpoint";
import { TourEndpoint } from "./TourEndpoint";
import { TouristToursEndpoint } from "./TouristToursEndpoint";
import { ToursEndpoint } from "./ToursEndpoint";

export const ReserveCreateEndpoint = new DstSnRcHttp<
  [number, number, IReserve],
  IReserve
>({
  method: HTTP_METHODS.post,
  url: ([id, touristId]) => `/tours/${id}/tourists/${touristId}`,
  body: ([_, __, reserve]: [number, number, IReserve]) => reserve
})

ReserveCreateEndpoint.reloadUpdateFor(
  ReservesEndpoint,
  () => true
)

ReserveCreateEndpoint.reloadUpdateFor(
  TouristToursEndpoint,
  (__, _, [, touristId], olDataTouristId) => touristId === olDataTouristId
)

ReserveCreateEndpoint.reloadUpdateFor(
  TourEndpoint,
  (oldData, _, [tourId]) => tourId === oldData.id
)

ReserveCreateEndpoint.reloadUpdateFor(
  ToursEndpoint,
  () => true
)
