import * as Yup from 'yup'

export const reserveFormSchema = Yup.object().shape({
  touristId: Yup.number()
    .typeError('Validation.required')
    .required('Validation.required'),
  paid: Yup.number()
    .typeError('Validation.required')
    .required('Validation.required'),
  status_payment: Yup.string()
    .required('Validation.required'),
})