import { autobind } from 'core-decorators'
import { Event as BaseEvent } from './Event'

@autobind
export abstract class BrowserLongPressEventBase extends BaseEvent<[Event]> {
  private invokeTimeout?: NodeJS.Timeout
  private cullDown: number

  protected abstract get someDownEvent(): string
  protected abstract get someUpEvent(): string

  public constructor(element: HTMLElement, cullDown: number = 600) {
    super()

    this.cullDown = cullDown

    if (element) { 
      //@ts-ignore
      element.addEventListener(this.someDownEvent, this.onSomeDown) 
      //@ts-ignore
      element.addEventListener(this.someUpEvent, this.onSomeUp) 
    }
  }

  private onSomeDown(e: Event) {
    this.invokeTimeout = setTimeout(
      () => this.finish(e),
      this.cullDown
    )
  }

  private onSomeUp() {
    if(this.invokeTimeout) {
      clearTimeout(this.invokeTimeout)
      this.invokeTimeout = undefined
    }
  }

  private finish(e: Event) {
    this.invoke(e)
    this.invokeTimeout = undefined
  }
}

export class BrowserLongMousePressEvent extends BrowserLongPressEventBase {
  protected get someDownEvent() { return 'mousedown' }
  protected get someUpEvent() { return 'mouseup' }
}

export class BrowserLongTouchEvent extends BrowserLongPressEventBase {
  protected get someDownEvent() { return 'touchstart' }
  protected get someUpEvent() { return 'touchend' }
}

export const browserLongMousePressEvent = (element: HTMLElement, cullDown: number = 600) => new BrowserLongMousePressEvent(element, cullDown)
export const browserLongTouchEvent = (element: HTMLElement, cullDown: number = 600) => new BrowserLongTouchEvent(element, cullDown)

