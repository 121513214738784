import Box from '../../tools/Components/atoms/Box';
import Button from '@mui/material/Button';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RightPanel from '../../ui/widgets/RightPanel';
import { ThemeContext } from '../../ui/widgets/ThemeProvider';
import { useContext } from 'react'
import CenterPanel from '../../ui/widgets/CenterPanel';
import { Typography } from '@mui/material';
import Linely from '../../tools/Components/atoms/Linely';
import Icon from '../../ui/widgets/Icon';
import ModalWrapper from '../../tools/Components/ModalWrapper';
import Dialog from '../../ui/widgets/Dialog';
import AddIcon from '@mui/icons-material/Add'
import CircleButton from '../../ui/widgets/CircleButton';

const ServicePage = () => {
  const { clientWidth } = useContext(ThemeContext)

  return (
    <>
      <CenterPanel>
        <Linely vertical gap='i1'>
          <Box>
            <ModalWrapper
              Component={Dialog}
              title='test modal'
              message={[...Array(10).keys()].map((i) => (
                <div key={i}>
                  sdfsdf {i}
                  <br />
                </div>
              ))}
            >
              <Button variant="contained">Открыть модалку</Button>
            </ModalWrapper>
          </Box>
          <Linely vertical gap='i3'>
            <Linely gap='i3'>
              <Button variant="contained" size='small'>Contained small</Button>
              <Button variant="contained" size='medium'>Contained medium</Button>
              <Button variant="contained" size='medium' color='inherit'>Contained medium gray</Button>
              <Button variant="contained" size='large'>Contained large</Button>
            </Linely>
            <Linely gap='i3'>
              <Button variant="outlined" size='small'>Outlined_ small</Button>
              <Button variant="outlined" size='medium'>Outlined_ medium</Button>
              <Button variant="outlined" size='medium' color='inherit'>Outlined_ medium gray</Button>
              <Button variant="outlined" size='large'>Outlined_ large</Button>
            </Linely>
            <Linely gap='i3'>
              <Button variant="text" size='small'>__Text___ small</Button>
              <Button variant="text" size='medium'>__Text___ medium</Button>
              <Button variant="text" size='medium' color='inherit'>__Text___ medium gray</Button>
              <Button variant="text" size='large'>__Text___ large</Button>
            </Linely>
          </Linely>
          <Linely>
            <CircleButton
              small
              IconComponent={AddIcon}
            >
              999
            </CircleButton>
            <CircleButton
              IconComponent={AddIcon}
            >
              999
            </CircleButton>
            <CircleButton
              big
              IconComponent={AddIcon}
            >
              999
            </CircleButton>
            <CircleButton
              small
              variant='contained'
            >
              <Icon
                small
                Component={AddIcon}
              />
            </CircleButton>
            <CircleButton
              variant='contained'
            >
              <Icon
                Component={AddIcon}
              />
            </CircleButton>
            <CircleButton
              big
              variant='contained'
            >
              <Icon
                big
                Component={AddIcon}
              />
            </CircleButton>
          </Linely>
          <Box sx={{ width: '100%', maxWidth: 500 }}>
            <Linely vertical>
              <Typography variant="h6">
                h6. Разрешение страницы {clientWidth} x {document.documentElement.clientHeight}
              </Typography>
              <Typography variant="subtitle1">
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="body1">
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="subtitle2">
                body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="body2">
                body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                quasi quidem quibusdam.
              </Typography>
              <Typography variant="button" display="block">
                button text
              </Typography>
            </Linely>
          </Box>
          <Linely>
            <Icon
              Component={ApartmentIcon}
              big
            />
            <Icon
              Component={ApartmentIcon}
            />
            <Icon
              Component={ApartmentIcon}
              small
            />
          </Linely>
          <Box>
            {[...Array(100).keys()].map((i) => (
              <div key={i}>
                sdfsdf {i}
                <br />
              </div>
            ))}
          </Box>
        </Linely>
      </CenterPanel>
      <RightPanel>
        {[...Array(200).keys()].map((i) => (
          <div key={i}>
          sdfsdf {i}
            <br />
          </div>
        ))}
      </RightPanel>
    </>
  )
}

export default ServicePage
