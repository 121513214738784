import { createContext, useCallback, useMemo } from 'react';
import useMemoObject from '../tools/hooks/useMemoObject';
import useEnObserve from '../tools/hooks/endpoints/useEnObserve';
import api, { getApiConfigAccessToken, tokenIsExpired } from '../endpoints';
import router from '../paths';
import useLocalStorrage from '../tools/hooks/useLocalStorrage';

export const UserContext = createContext({})

export const logout = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')

  if(window.location.pathname !== '/login' && window.location.pathname !== '/') {
    window.location.href = '/'
  }
}

function UserProvider({ children }) {
  const [accessToken] = useLocalStorrage('accessToken')
  const [state, reloadUser] = useEnObserve(api.tree.auth.user, null, null, !!accessToken)
  const { data: user, isLoading } = state

  const isAuth = useMemo(
    () => !!user,
    [user]
  )

  const isNoAuth = useMemo(
    () => !accessToken || (!user && !isLoading && !tokenIsExpired(state)),
    [accessToken, user, isLoading, state]
  )

  const login = useCallback(
    async (username, password) => {
      const { data, error } = await api.tree.auth.login.request({ username, password })

      if(error) {
        return
      }

      localStorage.setItem('accessToken', data.access)
      localStorage.setItem('refreshToken', data.refresh)
      api.setConfig('DstSnRcHttp.headers.Authorization', getApiConfigAccessToken())

      router.pushUrl(
        router.root.getUrl()
      )

      reloadUser?.()
    },
    [reloadUser]
  )

  const contextValue = useMemoObject({
    login,
    user,
    isAuth,
    isNoAuth
  })

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;
