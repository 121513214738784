import { useTheme } from '@mui/material/styles';
import { forwardRef } from "react";

const Icon = forwardRef(({ Component, size, small, big, ...props }, ref) => {
  const theme = useTheme()
  
  return (
    <Component
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        fontSize: theme.iconSizes[size ?? (small ? 'small' : (big ? 'big' : "medium"))]
      }}
    />
  )
})

export default Icon
