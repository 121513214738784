import { cloneElement } from "react"
import { IHashMap } from "./types"

export const mbFunctionalElement = (children: any, args: IHashMap<any>) => 
    typeof children === 'function' 
        ? children(args) 
        : children

export const mbComponentFunctionalElement = (Component: any, element: any, args: IHashMap<any>) => 
    Component 
        ? <Component {...args} />
        : mbFunctionalElement(element, args)

export const mbFunctionalCloneElement = (children: any, args: IHashMap<any>) => 
    typeof children === 'function' 
        ? children(args) 
        : cloneElement(children, args)

export const mbComponentFunctionalCloneElement = (Component: any, element: any, args: IHashMap<any>) => 
    Component 
        ? <Component {...args} />
        : mbFunctionalCloneElement(element, args)
    