import countries from 'world_countries_lists/data/countries/ru/countries.json'
// import flags from 'world_countries_lists/data/flags/24x24/flags-24x24.json'

export const countries_list = countries.map(country => ({
    code: country.alpha2,
    name: country.name,
    icon: `https://flagcdn.com/w20/${country.alpha2.toLowerCase()}.png`,
    // icon: flags[country.alpha2]
}))

export const countries_map = Object.fromEntries(countries_list.map(country => [country.code, country]))
