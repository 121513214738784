import api from '../../../../endpoints';
import router from '../../../../paths';
import usePathParams from '../../../../tools/hooks/paths/usePathParams';
import { remove } from '../../../../tools/libs/paths/helpers';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback, useMemo } from 'react'
import { Formik } from 'formik';
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import ReserveModalForm from './ReserveModalForm';
import { reserveFormSchema } from './reserveFormSchema';

const EditReserve = ({ ...props }) => {
  const [{ editReserve }] = usePathParams()
  const { tourId, touristId } = editReserve || {}

  const [{ data }] = useEnObserve(api.tree.reserves.one, [tourId, touristId], null, !!touristId)

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const initialValues = useMemo(
    () => data && ({
      ...data,
      touristId: data.tourist.id
    }),
    [data]
  )

  const onClose = useCallback(
    () => router.pushUrl(
      router.getUrl([
        [router.root.c.editReserve, remove()]
      ])
    ),
    []
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { error } = await api.tree.reserves.edit.request([
        tourId,
        touristId,
        {
          ...values
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      onClose()
    },
    [tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Reserve.editModal.title'
      lSubmitLable='Common.save'
      withoutBtns={!!initialValues}
    >
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={reserveFormSchema}
          onSubmit={onSubmit}
        >
          {tour && (
            <ReserveModalForm
              price={tour.price}
              lSubmitLable='Common.save'
              onClose={onClose}
            />
          )}
        </Formik>
      )}
    </Dialog>
  )
}

export default EditReserve
