import { useCallback } from "react"
import useRefState from "./useRefState"

const useBoolean = (defaultValue: boolean = false): [
    boolean,
    React.MutableRefObject<boolean>,
    () => Promise<boolean>,
    () => Promise<boolean>,
    () => Promise<boolean>,
    (val: boolean) => Promise<boolean>,
] => {
    const [opened, refOpened, setOpened] = useRefState<boolean>(defaultValue)

    const handleOpen = useCallback(
        () => setOpened(true),
        [setOpened]
    )

    const handleClose = useCallback(
        () => setOpened(false),
        [setOpened]
    )

    const handleToggle = useCallback(
        () => setOpened(!refOpened.current),
        [setOpened, refOpened]
    )

    return [
        opened,
        refOpened,
        handleToggle,
        handleOpen,
        handleClose,
        setOpened
    ]
}

export default useBoolean
