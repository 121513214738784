import { getTouristStatusColor } from '../../utils/helpers';
import StatusSmallIcon from './StatusSmallIcon';

const TouristStatus = ({ status, ...props }) => {
  
  return (
    <StatusSmallIcon
      {...props}
      color={getTouristStatusColor(status)}
    />
  )
}

export default TouristStatus
