import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material'
import { Field } from 'formik';
import Box from '../../../../tools/Components/atoms/Box';
import SubmitButtonWrapper from '../../../../tools/Components/formik/SubmitButtonWrapper';
import Linely from '../../../../tools/Components/atoms/Linely';
import TouristReserveField from '../../ui/TouristReserveField';
import TextField from '../../../../ui/formik/TextField';
import FormRequiredFieldsHelper from '../../../../ui/widgets/FormRequiredFieldsHelper';

const WaitModalForm = ({ lSubmitLable, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box
        px='i1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              required
              name='touristId'
              label={t('Common.tourist')}
              variant="filled"
              component={TouristReserveField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name='booking_source'
              label={t('Reserve.source')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='comment'
              label={t('Common.comment')}
              variant="filled"
              as={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        px='i1'
      >
        <FormRequiredFieldsHelper />
      </Box>
      <Linely
        end
        gap='i2'
        px='i1'
      >
        <Button
          variant="contained"
          size='large'
          color='inherit'
          onClick={onClose}
        >
          {t('Common.close')}
        </Button>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size='large'
          >
            {t(lSubmitLable)}
          </Button>
        </SubmitButtonWrapper>
      </Linely>
    </>
  )
}

export default WaitModalForm
