import { Avatar, useTheme } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { DASH_LINE } from '../../tools/utils/string'
import { stringAndThemeToHslColor } from '../../utils/string'

const UserAvatar = ({ user }) => {
  const theme = useTheme()

  const initials = user && (
    user.surname || user.first_name 
      ? [user.surname, user.first_name].filter(str => !!str).map(str => str[0]).join('') 
      : (user?.login || '?').substring(0, 2)
  ).toUpperCase()

  const avataredLabel = user && (initials.length
    ? initials
    : (user.email || '?').substring(0, 2)
  ).toUpperCase()

  return user 
    ? (
      <div>
        <Avatar
          src={user.avatar}
          sx={{
            bgColor: stringAndThemeToHslColor(`${avataredLabel}${user.id}`, theme)
          }}
        >
          {!user.avatar && avataredLabel}
        </Avatar>
        {user.banned && <LockOutlinedIcon />}
      </div>
    )
    : DASH_LINE
}

export default UserAvatar
