import * as Yup from 'yup'

export const tourFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Validation.required'),
  start_date: Yup.mixed()
    .required('Validation.required'),
  end_date: Yup.mixed()
    .required('Validation.required'),
  total_places: Yup.number()
    .typeError('Validation.required')
    .required('Validation.required'),
  price: Yup.number()
    .typeError('Validation.required')
    .required('Validation.required'),
  responsible_manager: Yup.number()
    .typeError('Validation.required')
    .required('Validation.required'),
})