import api from '../../../endpoints';
import Dialog from '../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { Trans } from 'react-i18next';
import Box from '../../../tools/Components/atoms/Box';
import { Typography } from '@mui/material';
import router from '../../../paths';
import { remove } from '../../../tools/libs/paths/helpers';

const DeleteTour = ({ id, onClose, ...props }) => {
  const [{ data: tour }] = useEnObserve(api.tree.tours.one, id, null, !!id)

  const handleSubmit = useCallback(
    async () => {
      const { error } = await api.tree.tours.delete.request(id)

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one, remove()]
      ]))

      onClose()
    },
    [id, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Tour.deleteModal.title'
      message={(
        <Trans
          i18nKey='Tour.deleteModal.message'
          values={{ title: tour?.title || '' }}
          components={[
            <Typography key={0} display='inline' variant='subtitle1' />,
            <Box key={1} mt='i1' />,
            <Typography key={2} display='inline' variant='subtitle1' color='error' />,
          ]}
        />
      )}
      lSubmitLable='Common.delete'
      onSubmit={handleSubmit}
    />
  )
}

export default DeleteTour
