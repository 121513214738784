import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourSmall } from "./ToursEndpoint";

export const TouristToursEndpoint = new DstSnRcHttp<
  number,
  ITourSmall[]
>({
  method: HTTP_METHODS.get,
  url: (id) => `/tourists/${id}/bookings`
})
