import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear'
import { useState, useCallback } from 'react'
import Icon from './Icon';

const FilledSearch = ({ value: outerValue, onChange, ...props }) => {
  const { t } = useTranslation()

  const [value, setValue] = useState()

  const handleChange = useCallback(
    e => {
      const value = e.target.value
      setValue(value)
      onChange(value)
    },
    [onChange]
  )

  const handleClear = useCallback(
    () => {
      setValue('')
      onChange('')
    },
    [onChange]
  )

  return (
    <TextField
      {...props}
      fullWidth
      value={outerValue || value}
      onChange={handleChange}
      size="small"
      placeholder={t('FilledSearch.placeholder')}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (outerValue || value) && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Icon
                Component={ClearIcon}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default FilledSearch
