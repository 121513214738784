import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import { ThemeProvider as MuiThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import useOnEvent from '../../tools/hooks/event/useOnEvent';
import { useCallback, useMemo, useState } from 'react'
import { browserResizeEvent } from '../../tools/libs/event';
import { createContext } from 'react';
import useMemoObject from '../../tools/hooks/useMemoObject';

export const getScreenWidthValue = (screenWidth: number, valueDown1536: any = false, valueUp1536: any = true) =>
    screenWidth > 1536    // в mui в теме есть такое значение 
      ? valueUp1536
      : valueDown1536

export const ThemeContext = createContext({})

function ThemeProvider({ children }: any) {
  const [clientWidth, setClientWidth] = useState(0)

  useOnEvent(
    useMemo(() => browserResizeEvent(document.documentElement), []),
    useCallback(
      () => {
        setClientWidth(document.documentElement.clientWidth)
      },
      []
    )
  )
  
  const theme = useMemo(
    () => createTheme({
      modalWidth: getScreenWidthValue(clientWidth, 650, 800),
      indents: {
        i1: getScreenWidthValue(clientWidth, 16, 24) / 8,
        i2: getScreenWidthValue(clientWidth, 10, 16) / 8,
        i3: getScreenWidthValue(clientWidth, 8, 12) / 8,
        i4: getScreenWidthValue(clientWidth, 4, 6) / 8,
      },
      iconSizes: {
        big: getScreenWidthValue(clientWidth, 24, 32),
        medium: getScreenWidthValue(clientWidth, 18, 24),
        small: getScreenWidthValue(clientWidth, 14, 18),
      },
      typography: {
        fontFamily: '"Montserrat", sans-serif',
        h6: {
          fontSize: getScreenWidthValue(clientWidth, 17, 20),
          fontWeight: 700
        },
        subtitle1: {
          fontSize: getScreenWidthValue(clientWidth, 12, 14),
          fontWeight: 600
        },
        body1: {
          fontSize: getScreenWidthValue(clientWidth, 12, 14),
          fontWeight: 500
        },
        subtitle2: {
          fontSize: getScreenWidthValue(clientWidth, 10, 12),
          fontWeight: 600
        },
        body2: {
          fontSize: getScreenWidthValue(clientWidth, 11, 12),
          fontWeight: 500
        },
        button: {
          fontSize: getScreenWidthValue(clientWidth, 12, 14)
        }
      },
      palette: {
        divider: '#EEEEEE',
        background: {
          default: '#F4F4F4'
        },
        primary: {
          main: '#FFA048',
        },
      },
      components: {
        MuiButton: {
          defaultProps: {
            disableElevation: true,
            sx: {
              fontSize: getScreenWidthValue(clientWidth, 12, 14),
              textTransform: 'initial',
            }
          },
          styleOverrides: {
            sizeLarge: {
              padding: getScreenWidthValue(clientWidth, '10px 30px', '10px 40px')
            }
          }
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              backgroundColor: 'rgba(230, 230, 230, 0.76)',
              '&:hover': {
                backgroundColor: 'rgba(210, 210, 210, 0.5)',
              },
              '&.Mui-focused': {
                backgroundColor: 'rgba(240, 240, 240, 0.76)',
              },
            }
          }
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '& > .MuiTableCell-root:first-child, & *:first-child .MuiTableCell-root': {
                paddingLeft: getScreenWidthValue(clientWidth, 16, 24)
              },
              '& > .MuiTableCell-root:last-child, & *:last-child .MuiTableCell-root': {
                paddingRight: getScreenWidthValue(clientWidth, 16, 24)
              }
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              paddingTop: getScreenWidthValue(clientWidth, 6, 8),
              paddingBottom: getScreenWidthValue(clientWidth, 6, 8),
              paddingLeft: getScreenWidthValue(clientWidth, 10, 16),
              paddingRight: getScreenWidthValue(clientWidth, 10, 16),
              verticalAlign: 'middle'
            }
          }
        },
      }
    } as ThemeOptions),
    [clientWidth]
  )

  const contextValue = useMemoObject({
    theme,
    clientWidth
  })

  console.log('theme context', contextValue)

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

declare module '@mui/material/styles' {
  interface Theme {
    indents: {
      i1: number
      i2: number
      i3: number
      i4: number
    }
    iconSizes: {
      small: number
      medium: number
      big: number
    }
  }
  // allow configuration using `createTheme`
  type ThemeOptions = Partial<Theme>
}

export default ThemeProvider;
