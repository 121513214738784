import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourist } from "./TouristEndpoint";

export interface IReserveSmall {
  id_tour: number,
  booking_date: number,
  booking_source: string,
  paid: number,
  status_payment: string,
  tickets: string,
  comment: string,
  tourist: ITourist
}

export const ReservesEndpoint = new DstSnRcHttp<
  number,
  IReserveSmall[]
>({
  method: HTTP_METHODS.get,
  url: (id) => `/tours/${id}/tourists`
})
