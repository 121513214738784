import RightPanel from '../../ui/widgets/RightPanel';
import Linely from '../../tools/Components/atoms/Linely';
import LeftRight from '../../tools/Components/atoms/LeftRight';
import { IconButton, Typography } from '@mui/material';
import { DASH_LINE } from '../../tools/utils/string';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getReserveStatusIsValid, getReserveStatusLocaleKey, userToFullFio } from '../../utils/helpers';
import { tsToMomentMoscow } from '../../utils/moment';
import { getDateFormat1 } from '../../tools/utils/moment';
import ReserveStatus from '../../ui/widgets/ReserveStatus';
import TouristStatus from '../../ui/widgets/TouristStatus';
import Box from '../../tools/Components/atoms/Box';
import PathLink from '../../tools/Components/paths/PathLink';
import Icon from '../../ui/widgets/Icon';
import CloseIcon from '@mui/icons-material/Close';
import ShowOnEvent from '../../tools/Components/event/ShowOnEvent';
import Options from '../../ui/widgets/Options';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loadable from '../../ui/widgets/Loadable';
import { TouristInfo } from '../../ui/widgets/TouristInfo';
import { TouristToursInfo } from '../../ui/entities/TouristToursInfo';
import { ReservePaidRenderer } from '../../ui/tableRenderers/ReservePaidRenderer';

const TourTouristView = ({
  type,
  tour,
  tourTourist,
  loading,
  empty,
  options,
  goBackHref,
}) => {
  const { t } = useTranslation()

  const bookingDateMoment = useMemo(
    () => tourTourist && tsToMomentMoscow(tourTourist.booking_date),
    [tourTourist]
  )

  return (
    <RightPanel>
      <Loadable
        loading={loading}
        empty={empty}
      >
        {() => (
          <Linely
            fulLine
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely>
                <TouristStatus
                  status={tourTourist.tourist.status}
                />
                <Typography
                  variant='h6'
                >
                  {userToFullFio(tourTourist.tourist)}
                </Typography>
              </Linely>
              <Box mx={-1}>
                <PathLink
                  href={goBackHref}
                >
                  <IconButton>
                    <Icon
                      big
                      Component={CloseIcon}
                    />
                  </IconButton>
                </PathLink>
              </Box>
            </LeftRight>
            <LeftRight>
              <Typography
                variant='subtitle1'
              >
                {t(type === 'reserve'
                  ? 'Tour.tourists.reserve.title'
                  : type === 'wait'
                    ? 'Tour.tourists.wait.title'
                    : 'Tour.tourists.cancel.title'
                )}
              </Typography>
              <Box mx={-1}>
                <ShowOnEvent
                  event='click'
                  Component={Options}
                  options={options}
                >
                  <IconButton>
                    <Icon
                      Component={MoreVertIcon}
                    />
                  </IconButton>
                </ShowOnEvent>
              </Box>
            </LeftRight>
            <Linely
              vertical
              gap='i4'
            >
              <LeftRight>
                <Typography color='textSecondary'>{t('Reserve.date')}</Typography>
                <Typography>
                  {getDateFormat1(bookingDateMoment)}{' '}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Reserve.source')}</Typography>
                <Typography
                  align='right'
                  style={{
                    maxWidth: '70%',
                  }}
                >
                  {tourTourist.booking_source || DASH_LINE}
                </Typography>
              </LeftRight>
              {type !== 'wait' && (
                <LeftRight>
                  <Typography color='textSecondary'>{t('Common.payed')}</Typography>
                  <ReservePaidRenderer
                    value={tourTourist}
                    column={{ tourPrice: tour.price }}
                  />
                </LeftRight>
              )}
              {type === 'reserve' && (
                <LeftRight>
                  <Typography color='textSecondary'>{t('Reserve.status')}</Typography>
                  {!getReserveStatusIsValid(tourTourist.status_payment)
                    ? DASH_LINE
                    : (
                      <Linely>
                        <ReserveStatus status={tourTourist.status_payment} />
                        <Typography>{t(getReserveStatusLocaleKey(tourTourist.status_payment))}</Typography>
                      </Linely>
                    )
                  }
                </LeftRight>
              )}
              <LeftRight>
                <Typography color='textSecondary'>{t('Reserve.tickets.title')}</Typography>
                <Typography
                  align='right'
                  style={{
                    maxWidth: '70%',
                  }}
                >
                  {tourTourist.tickets || DASH_LINE}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Common.comment')}</Typography>
                <Typography
                  align='right'
                  style={{
                    maxWidth: '70%',
                  }}
                >
                  {tourTourist.comment || DASH_LINE}
                </Typography>
              </LeftRight>
            </Linely>
            <Typography
              variant='subtitle1'
            >
              {t('Tourist.mainInfo')}
            </Typography>
            <TouristInfo
              tourist={tourTourist.tourist}
            />
            <TouristToursInfo
              tourist={tourTourist.tourist}
            />
          </Linely>
        )}
      </Loadable>
    </RightPanel>
  )
}

export default TourTouristView
