export class PathRouterPathNodeIsNotDefinedError extends Error {
    constructor(errorPoint: string) {
        super(`${errorPoint} error. PathRouter is not defined. Use PathNode only through PathRouter`)
    }
}

export class PathRouterOptionalAndParametrNodeError extends Error {
    constructor(pathName: string) {
        super(`Optional path ${pathName} cannot be a parameter at the same time`)
    }
}

export class PathRouterParamIsNotDefinedError extends Error {
    constructor(paramName: string) {
        super(`Param "${paramName}" is not defined`)
    }
}

export class PathRouterPathMultipleNodeNotInsideError extends Error {
    constructor(nodeName: string) {
        super(`Node "${nodeName}" is not inside multiple node`)
    }
}

export class PathRouterPathMultipleNodeNotSameInsideError extends Error {
    constructor(nodeName1: string, nodeName2: string) {
        super(`Nodes "${nodeName1}" and "${nodeName2}" are inside different multiple nodes`)
    }
}

export class PathRouterNamesConflictError extends Error {
    constructor(repeatedPaths: string[]) {
        super(`Path names ${repeatedPaths.map(s => `"${s}"`).join(', ')} should not be repeated in the PathRouter`)
    }
}

export class PathRouterSystemError extends Error {
    constructor() {
        super('PathRouter system error. Report this bug please')
    }
}

export class PathRouterRemoveNodeRootError extends Error {
    constructor() {
        super(`You cannot remove the root node`)
    }
}
