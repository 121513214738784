import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography
} from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableViewHead from './TableView/TableViewHead'
import TableViewRow from './TableView/TableViewRow'
import clsx from 'clsx'
import TablePagination from '../widgets/TablePagination'

/* const useStyles = createUseStyles(theme => ({
  root: {
    '& tr > td:first-child, & tr > th:first-child': {
      paddingLeft: ({ padding, leftPadding }) =>
        theme.spacing(padding != null ? padding : leftPadding != null ? leftPadding : 2)
    },
    '& tr > td:last-child, & tr > th:last-child': {
      paddingRight: ({ padding }) => theme.spacing(padding != null ? padding : 2)
    },
    '& td, & th': {
      border: 'none'
    }
  }
})) */

function ClearTable ({
  columns,
  padding,
  leftPadding,
  data: outerData,
  selectedIds,
  rowProps,
  tableRowProps,
  rendererProps,
  highlighted,
  paginationProps,
  hidePaginationControls,
  className,
  tableClassName,
  tableBodyClassName,
  selectable,
  onSelect,
  onSelectAll,
  multipleSelection,
  onClick,
  href,
  noHeader,
  headerColumnCell,
  children,
  emptyMessage,
  noIsEmpty,
  selectLvl,
  noOverflowX,
  size,
  onHover,
  onUnhover,
  isSelectAllDisabled,
  isSelectAllHidden,
  numSelected,
  hasSelectedIds,
  isSelected,
  ...tableProps
}) {
  // const classes = useStyles({ padding, leftPadding })
  const { t } = useTranslation()

  const data = useMemo(
    () => outerData || [],
    [outerData]
  )

  const clickable = useMemo(() => onClick || href, [onClick, href])

  const onSelectAllClick = useCallback(e => onSelectAll(e.target.checked), [
    onSelectAll
  ])

  const tableElement = (
    <Table
      className={tableClassName}
      size={size}
      {...tableProps}
      style={{
        overflowX: !noOverflowX && 'auto',
      }}
    >
      {!noHeader && (
        <TableViewHead
          size={size}
          multipleSelection={multipleSelection}
          selectable={selectable}
          numSelected={numSelected}
          onSelectAllClick={onSelectAllClick}
          maxSelectCount={paginationProps?.count || data.length}
          columns={columns}
          columnCell={headerColumnCell}
          isSelectAllDisabled={isSelectAllDisabled}
          isSelectAllHidden={isSelectAllHidden}
        />
      )}
      <TableBody className={tableBodyClassName}>
        {children}
        {!noIsEmpty && !data?.length
          ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Typography>
                  {t('Table.noData')}
                </Typography>
              </TableCell>
            </TableRow>
          )
        : data?.map((row, index) => (
          <TableViewRow
            key={row.id}
            selectLvl={selectLvl}
            hasSelectedIds={hasSelectedIds}
            isSelected={isSelected}
            row={row}
            size={size}
            highlighted={highlighted}
            selectable={selectable}
            onSelect={onSelect}
            clickable={clickable}
            onClick={onClick}
            onHover={onHover}
            onUnhover={onUnhover}
            href={href}
            rowIndex={index}
            rowProps={rowProps}
            tableRowProps={tableRowProps}
            multipleSelection={multipleSelection}
            columns={columns}
            rendererProps={rendererProps}
          />
        ))}
      </TableBody>
      {paginationProps && !hidePaginationControls && (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={100000}>
              <TablePagination {...paginationProps} />
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>
  )

  return (
    <div
      className={clsx(className, /* classes.root */)}
    >
      {tableElement}
    </div>
  )
}

export default memo(ClearTable)
