import { useContext, useMemo } from 'react'
import { UserContext, logout } from '../UserProvider'
import ShowOnEvent from '../../tools/Components/event/ShowOnEvent'
import Options from '../../ui/widgets/Options'
import LogoutIcon from '@mui/icons-material/Logout';
import Icon from '../../ui/widgets/Icon';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import UserAvatar from '../../ui/widgets/UserAvatar';

function UserMenu () {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  const userOptions = useMemo(
    () => [
      {
        icon: (<Icon Component={LogoutIcon} color='error' />),
        text: t('Common.logout'),
        color: 'error',
        onClick: logout
      }
    ],
    [t]
  )
  
  return (
    <ShowOnEvent
      event='click'
      Component={Options}
      options={userOptions}
    >
      <IconButton>
        <UserAvatar
          user={user}
        />
      </IconButton>
    </ShowOnEvent>
  )
}

export default UserMenu
