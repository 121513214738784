import moment from 'moment'
import { useCallback, useMemo } from 'react'
import useActionAt from './useActionAt'
import useRefState from './useRefState'

/*
    toRecalc: bool | number
    если bool - нужно ли пересчитывать
    если number - timestamp до которого нужно обновлять время, после него апдейтов не будет
*/
function useCurrentTime (toRecalc = true) {
  const [currentTime, refCurrentTime, setCurrentTime] = useRefState(
    useMemo(() => moment(), [])
  )

  const nextTime = useMemo(
    // именно через .unix() * 1000 можно получить четкую границу начала следующей секунды
    () => moment(currentTime).startOf('second').add(1, 'second'),
    [currentTime]
  )

  const nextTimeMilliseconds = useMemo(
    // именно через .unix() * 1000 можно получить четкую границу начала следующей секунды
    () => nextTime.unix() * 1000,
    [nextTime]
  )

  const needRecalc = useMemo(
    () =>
      typeof toRecalc === 'number' ? currentTime.unix() <= toRecalc : toRecalc,
    [toRecalc, currentTime]
  )

  // обновляем текущее время
  useActionAt(
    needRecalc ? nextTimeMilliseconds : null,
    useCallback(
      () => {
        setCurrentTime(moment())
      },
      [setCurrentTime]
    )
  )

  return [currentTime, refCurrentTime]
}

export default useCurrentTime
