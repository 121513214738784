import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export interface ITourSmall {
  id: number,
  title: string,
  start_date: number,
  end_date: number,
  total_places: number,
  price: number,
  busy_places: number,
  site_link: string,
  info_link: string,
  calc_link: string,
  manager: string,
  escort: string
}

export const ToursEndpoint = new DstSnRcHttp<
  null,
  ITourSmall[]
>({
  method: HTTP_METHODS.get,
  url: '/tours/all'
})
