import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourist } from "./TouristEndpoint";
import { TouristsEndpoint } from "./TouristsEndpoint";

export const TouristCreateEndpoint = new DstSnRcHttp<
  ITourist,
  ITourist
>({
  method: HTTP_METHODS.post,
  url: '/tourists',
  body: (tourist: ITourist) => tourist
})

TouristCreateEndpoint.transformUpdateFor(
  TouristsEndpoint,
  (oldData, newTourist) => [newTourist, ...oldData]
)
