import { IHashMap } from "../../utils/types";
import { 
    getArrayPath,
    pathParamsToUrlParts,
    checkMatchParam,
    parseParam,
    closed,
} from "./helpers";
import { 
    PathRouter,
    IUrlParts,
    TPathParams, 
    TQueryParams,
    TPathLocalParams,
    TParametrType,
    PathMultipleNode
} from "./";
import { PathRouterPathNodeIsNotDefinedError } from "./errors";
import { autobind } from "core-decorators";
import { assert } from '../../utils/assert';
import deepmerge from 'deepmerge';
import { setIn, getIn } from "../../utils/object";

export interface IPathNodeOptions {
    _multipleRoot?: PathMultipleNode<any>,        // нода есть у нескольких родителей
    optional?: boolean,        // нода опциональная
    isParameter?: boolean      // нода есть у нескольких родителей,
    parametrType?: TParametrType,
    name?: string | null       // если юзер хочет задать другое имя для пути - это можно сделать тут
    // extend?: Path.node      // нода от которой наследуется текущая нода. Наследуются все её дочерние пути. Нода автоматически становится multiple
}

@autobind
export class PathNode<TChilds extends IHashMap<PathNode<any>>> {
    public get options() {
        return {
            _multipleRoot: this._multipleRoot,
            optional: this.optional,
            isParameter: this.isParameter,
            parametrType: this.parametrType,
            name: this.name,
        } as IPathNodeOptions
    }

    private _multipleRoot?: PathMultipleNode<any>
    public get multipleRoot() {
        return this._multipleRoot
    }
    public get multiple() {
        return !!this._multipleRoot
    }

    private _optional: boolean = false
    public get optional() {
        return this._optional
    }

    private _isParameter: boolean = false
    public get isParameter() {
        return this._isParameter
    }

    private _parametrType: TParametrType = 'string'
    public get parametrType() {
        return this._parametrType
    }

    private _name?: string
    public get name() {
        return this._name
    }

    protected _childs: TChilds = {} as TChilds
    public get c() {
        return this._childs
    }
    public get childs() {
        return this._childs
    }

    private _parent?: PathNode<any> = undefined
    public get parent() {
        return this._parent
    }

    private _router?: PathRouter<any> = undefined
    public get router() {
        return this._router
    }

    private _isMatchExact: boolean = false
    public get isMatchExact() {
        return this._isMatchExact
    }

    private _isMatch: boolean = false
    public get isMatch() {
        return this._isMatch
    }

    private _localParams: TPathLocalParams = {}
    public get localParams() {
        return this._localParams
    }

    private _params: TPathParams = {}
    public get params() {
        return this._params
    }

    public get pageUrl() {
        assert(!!this._router, new PathRouterPathNodeIsNotDefinedError('PathNode pageUrl'))
        return (this._router as PathRouter<any>).url
    }

    public get queryParams() {
        assert(!!this._router, new PathRouterPathNodeIsNotDefinedError('PathNode queryParams'))
        return (this._router as PathRouter<any>).queryParams
    }

    public get hash() {
        assert(!!this._router, new PathRouterPathNodeIsNotDefinedError('PathNode hash'))
        return (this._router as PathRouter<any>).hash
    }
    

    constructor(options?: IPathNodeOptions, childNodes?: TChilds) {      
        if(options) {
            this._multipleRoot = options._multipleRoot ?? this._multipleRoot
            this._optional = options.optional ?? this._optional
            this._isParameter = options.isParameter ?? this._isParameter
            this._parametrType = options.parametrType ?? this._parametrType
            this._name = options.name ?? this._name
        }

        if(childNodes) {
            this._childs = childNodes
        }
    }

    public getUrl(params: TPathParams = {}, queryParams?: TQueryParams, hash?: string): string {
        assert(!!this._router?.__urlParts, new PathRouterPathNodeIsNotDefinedError('PathNode getUrl()'))

        return (this._router as PathRouter<any>).getUrl([[this, closed(params)]], queryParams, hash)
    }

    public __compileNode(
        outerName?: string,
        parent?: PathNode<any>, 
        router?: PathRouter<any>
    ) {
        this._name = this._name ?? outerName
        this._parent = parent
        this._router = router

        for (const [outerName, child] of Object.entries(this.childs)) {
            child.__compileNode(outerName, this, router)
        }
    }

    public __getUrlParts(params: TPathParams) {
        if(!this._router) {
            throw new PathRouterPathNodeIsNotDefinedError('PathNode __getUrlParts()')
        }

        const arrayPath = getArrayPath(this)
        
        return pathParamsToUrlParts(
            arrayPath, 
            deepmerge(
                this.params,
                params
            )
        )
    }

    public __updateUrl(urlParts: IUrlParts, mainPathStartIndex: number = 0, localParamsPath: string = '') {
        const currentUrlPath = urlParts.mainPath[mainPathStartIndex]

        this._isMatchExact = false
        this._isMatch = true
        this._localParams = mainPathStartIndex > 0 ? { ...this.parent?._localParams } : {}

        if(
            this._isParameter && 
            checkMatchParam(currentUrlPath, this.parametrType) && 
            mainPathStartIndex < urlParts.mainPath.length
        ) {
            this._localParams[this.name as string] = parseParam(currentUrlPath, this.parametrType)
        }
        else if(currentUrlPath !== this.name) {
            this._isMatch = false
        }

        this._params = { ...this.parent?.params }

        if(localParamsPath !== '') {
            this._params = setIn(
                this._params, 
                localParamsPath, 
                { ...getIn(this._params, localParamsPath), ...this._localParams }
            )
        }
        else {
            this._params = { ...this._params, ...this._localParams }
        }

        if(this._isMatch && mainPathStartIndex === urlParts.mainPath.length - 1) {
            this._router?.__addFullMatchPath(this)
            this._isMatchExact = true
        }

        for(const childNode of Object.values(this.childs)) {
            if(!this.isMatch) {
                childNode.__updateUrlNoMatch()
            }
            else {
                if(childNode.optional) {
                    const optionalUrlParts = urlParts.optionalPaths.find(path => path.mainPath[0] === childNode.name)

                    if(optionalUrlParts) {
                        childNode.__updateUrl(
                            optionalUrlParts, 
                            0, 
                            `${localParamsPath}${localParamsPath.length > 0 ? '.' : ''}${childNode.name}`
                        )
                    }
                    else {
                        childNode.__updateUrlNoMatch()
                    }
                }
                else {
                    childNode.__updateUrl(urlParts, mainPathStartIndex + 1, localParamsPath)
                }
            }
        }
    }

    private __updateUrlNoMatch() {
        this._isMatchExact = false
        this._isMatch = false

        this._params = { ...this.parent?._params }
        this._localParams = this.optional ? {} : { ...this.parent?._localParams }
        
        for(const childNode of Object.values(this.childs)) {
            childNode.__updateUrlNoMatch()
        }
    }

}
