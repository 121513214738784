import { Typography } from '@mui/material'
import { findStrInArray } from '../../tools/utils/array';
import {
  parsePhone,
} from '../../utils/helpers';
import { phonePreprocessToServer } from '../../pages/LoginPage';

export const touristPhoneSearcher = (searchStr, { phone }) => 
  phone && (phone.replace(/[^\w]/g, '') && phonePreprocessToServer(searchStr) && findStrInArray([phone], phonePreprocessToServer(searchStr)))

export const TouristPhoneRenderer = ({ value: tourist }) => (
  <Typography
    sx={{
      whiteSpace: 'nowrap'
    }}
  >
    {parsePhone(tourist.phone)}
  </Typography>
)
