import { autobind } from 'core-decorators'
import { Event as BaseEvent, TEventCb } from './Event'

@autobind
export class OnceEvent<Args extends Array<any>> extends BaseEvent<Args> {
  private _invoked: boolean = false
  public get invoked() { return this._invoked }

  //@ts-ignore
  private _invokedArgs: Args = ([] as Args)
  public get invokedArgs() { return this._invokedArgs }

  public constructor() {
    super()
  }

  public override subscribe (cb: TEventCb<Args>) {
    if(this._invoked) {
      cb(...this._invokedArgs)
      return
    }

    this.subscribers.add(cb)
  }

  public override invoke (...args: Args) {
    if(this._invoked) {
      return
    }

    for(const cb of this.subscribers.values()) {
      cb(...args)
    }

    this._invoked = true
  }
}

export const onceEvent = <Args extends Array<any>>() => new OnceEvent<Args>()
