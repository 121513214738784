import { forwardRef, useRef } from 'react'
import useBoolean from '../../hooks/useBoolean'
import useRefSync from '../../hooks/useRefSync'
import { mbComponentFunctionalElement } from '../../utils/react'
import OnEvent from './OnEvent'

function ShowOnEvent (
  {
    event,
    eventArgs,
    Component,
    element,
    children,
    openable,
    ...props
  },
  outRef
) {
  const ref = useRef()
  const syncedRef = useRefSync(ref, outRef)

  const [opened, , , open, close] = useBoolean(false)

  return (
    <>
      <OnEvent
        ref={syncedRef}
        onEvent={open}
        event={event}
        eventArgs={eventArgs}
      >
        {children}
      </OnEvent>
      {(openable || opened) && mbComponentFunctionalElement(
        Component,
        element,
        {
          ...props,
          opened,
          onClose: close,
          anchorEl: ref.current
        }
      )}
    </>
  )
}

export default forwardRef(ShowOnEvent)
