import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next'
import Linely from '../../tools/Components/atoms/Linely'
import ErrorData from './ErrorData';
import Centered from '../../tools/Components/atoms/Centered';
import router from '../../paths';

export const LocalError = ({ error }) => {
  const { t } = useTranslation()

  return (
    <Box
      border='#f008 3px solid'
      backgroundColor='#f001'
      p={2}
    >
      <Centered fullHeight>
        <Linely
          itemsGap={8}
          vertical
          centered
        >
          <Typography variant='h6'>
            {t('ErrorPage.main')}
          </Typography>
          <ErrorData
            error={error}
          />
          <Linely>
            <Button
              color='inherit'
              onClick={router.goBack}
            >
              {t('ErrorPage.goBack')}
            </Button>
            <Button
              color='inherit'
              onClick={() => window.location.reload()}
            >
              {t('ErrorPage.reloadPage')}
            </Button>
          </Linely>
        </Linely>
      </Centered>
    </Box>
  )
}

export default LocalError
