import { Typography, Tooltip } from '@mui/material'
import { DASH_LINE } from '../../tools/utils/string';

export const ReserveTicketsRenderer = ({ value: reserve }) => (
  <Tooltip
    title={reserve.tickets}
  >
    <Typography
      variant='body1'
      style={{
        '-webkit-line-clamp': '4',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      }}
    >
      {reserve.tickets || DASH_LINE}
    </Typography>
  </Tooltip>
)
