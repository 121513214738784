import { Typography, Button } from '@mui/material'
import Centered from '../../tools/Components/atoms/Centered'
import Linely from '../../tools/Components/atoms/Linely'
import { useTranslation } from 'react-i18next'
import ErrorData from './ErrorData'

export const GlobalError = ({ error }) => {
  const { t } = useTranslation()

  return (
    <Centered
      height='100vh'
    >
      <Linely
        vertical
        centered
        gap='i1'
      >
        <Typography
          variant='h6'
          color='textSecondary'
        >
          {t('ErrorPage.main')}
        </Typography>
        <ErrorData
          error={error}
        />
        <Button variant='contained' color='primary' href={process.env.PUBLIC_URL || '/'}>
          {t('ErrorPage.backToMain')}
        </Button>
        <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
          {t('ErrorPage.reloadPage')}
        </Button>
      </Linely>
    </Centered>
  )
}

export default GlobalError
