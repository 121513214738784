import { Box as MuiBox, useTheme } from '@mui/material'
import { forwardRef } from 'react'

/* const transformPyl = (pyl) => ({ py: pyl, pl: pyl })
const transformPyr = (pyr) => ({ py: pyr, pr: pyr })
const transformPxt = (pxt) => ({ px: pxt, pt: pxt })
const transformPxb = (pxb) => ({ px: pxb, pb: pxb })
const transformPtl = (ptl) => ({ pt: ptl, pl: ptl })
const transformPtr = (ptr) => ({ pt: ptr, pr: ptr })
const transformPbl = (pbl) => ({ pb: pbl, pl: pbl })
const transformPbr = (pbr) => ({ pb: pbr, pr: pbr })

const transformMyl = (myl) => ({ my: myl, ml: myl })
const transformMyr = (myr) => ({ my: myr, mr: myr })
const transformMxt = (mxt) => ({ mx: mxt, mt: mxt })
const transformMxb = (mxb) => ({ mx: mxb, mb: mxb })
const transformMtl = (mtl) => ({ mt: mtl, ml: mtl })
const transformMtr = (mtr) => ({ mt: mtr, mr: mtr })
const transformMbl = (mbl) => ({ mb: mbl, ml: mbl })
const transformMbr = (mbr) => ({ mb: mbr, mr: mbr }) */

const transformInline = (inline, display) => {
  // если блок не инлайн
  // или если он инлайн, но у него в свойстве дисплей уже есть инлайн (inline, inline-flex, ...)
  if (!inline || display.indexOf('inline') === 0) {
    return display
  }

  return display ? `inline-${display}` : 'inline'
}

export const transformIndent = (theme, indent) => {
  // передано шаблонное значение отступа
  if(typeof indent === 'string') {
    const isNegative = indent[0] === '-'

    indent = isNegative ? indent.substring(1) : indent

    indent = theme.indents[indent]

    return isNegative ? -indent : indent
  }

  return indent
}

export const getWidth = (width, fullWidth, indents) => {
  let value = width

  if(fullWidth) {
    const offsetLeft = indents.ml || indents.mx || indents.m || 0
    const offsetRight = indents.mr || indents.mx || indents.m || 0

    // Только отрицательные отступы не меняют ширину элемента, а сдвигают его, не меняя ширину
    // Отрицательные отступы должны увеличивать ширину
    const resNegativeOffset = Math.min(0, offsetLeft) + Math.min(0, offsetRight)

    if(resNegativeOffset === 0) {
      value = '100%'
    }
    else {
      value = `calc(100% + ${-resNegativeOffset*8}px)`
    }
  }

  return value
}

function Box (props, ref) {
  const theme = useTheme()
  
  const display = props.hide ? 'none' : transformInline(props.inline, props.display || (props.flex ? 'flex' : null))
  const component = props.component || (display?.indexOf('inline') === 0 ? 'span' : 'div')

  const indents = Object.fromEntries([
    'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl',
    'm', 'mx', 'my', 'mt', 'mr', 'mb', 'ml',
  ].map(key => [key, transformIndent(theme, props[key])]))

  props.width === 'inherit' && console.log('df43erdf', props.width, getWidth(props.width || props.w, props.fullWidth, indents))
  return (
    <MuiBox
      {...props}
      {...indents}
      ref={ref}
      component={component}
      display={display}
      width={getWidth(props.width || props.w, props.fullWidth, indents)}
      maxWidth={getWidth(props.maxWidth, props.fullWidth, indents)}
      height={props.fullHeight ? '100%' : (props.height || props.h)}
      maxHeight={props.fullHeight ? '100%' : props.maxHeight}
    />
  )
}

/*
    <MuiBox
      {...props}
      ref={ref}
      component={component}
      display={transformInline(props.inline, props.display)}
      width={props.fullWidth ? '100%' : (props.width || props.w)}
      height={props.fullHeight ? '100%' : (props.height || props.h)}
      {...((props.pyl || props.paddingYLeft) ? transformPyl(props.pyl || props.paddingYLeft) : {})}
      {...((props.pyr || props.paddingYRight) ? transformPyr(props.pyr || props.paddingYRight) : {})}
      {...((props.pxt || props.paddingXTop) ? transformPxt(props.pxt || props.paddingXTop) : {})}
      {...((props.pxb || props.paddingXBottom) ? transformPxb(props.pxb || props.paddingXBottom) : {})}
      {...((props.ptl || props.paddingTopLeft) ? transformPtl(props.ptl || props.paddingTopLeft) : {})}
      {...((props.ptr || props.paddingTopRight) ? transformPtr(props.ptr || props.paddingTopRight) : {})}
      {...((props.pbl || props.paddingBottomLeft) ? transformPbl(props.pbl || props.paddingBottomLeft) : {})}
      {...((props.pbr || props.paddingBottomRight) ? transformPbr(props.pbr || props.paddingBottomRight) : {})}
      {...((props.myl || props.marginYLeft) ? transformMyl(props.myl || props.marginYLeft) : {})}
      {...((props.myr || props.marginYRight) ? transformMyr(props.myr || props.marginYRight) : {})}
      {...((props.mxt || props.marginXTop) ? transformMxt(props.mxt || props.marginXTop) : {})}
      {...((props.mxb || props.marginXBottom) ? transformMxb(props.mxb || props.marginXBottom) : {})}
      {...((props.mtl || props.marginTopLeft) ? transformMtl(props.mtl || props.marginTopLeft) : {})}
      {...((props.mtr || props.marginTopRight) ? transformMtr(props.mtr || props.marginTopRight) : {})}
      {...((props.mbl || props.marginBottomLeft) ? transformMbl(props.mbl || props.marginBottomLeft) : {})}
      {...((props.mbr || props.marginBottomRight) ? transformMbr(props.mbr || props.marginBottomRight) : {})}
    />
*/

export default forwardRef(Box)
