import api from '../../../../endpoints';
import router from '../../../../paths';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { userToFullFio } from '../../../../utils/helpers';
import SelectField from '../../../../ui/formik/SelectField';
import Linely from '../../../../tools/Components/atoms/Linely';
import CancelStatus from '../../../../ui/widgets/CancelStatus';
import { Field, Formik } from 'formik';
import { useMemo } from 'react'
import TextField from '../../../../ui/formik/TextField';
import SubmitButtonWrapper from '../../../../tools/Components/formik/SubmitButtonWrapper';
import FormRequiredFieldsHelper from '../../../../ui/widgets/FormRequiredFieldsHelper';
import { cancelFormSchema } from '../../Cancels/Modals/cancelFormSchema';
import Box from '../../../../tools/Components/atoms/Box';

const ReserveToCancelModal = ({ tourId, touristId, onClose, ...props }) => {
  const { t } = useTranslation()

  const [{ data }] = useEnObserve(api.tree.reserves.one, [tourId, touristId], null, !!touristId)

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const initialValues = useMemo(
    () => data && ({
      ...data,
      touristId: data.id_tourist,
      tourist: undefined,
    }),
    [data]
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { error } = await api.tree.reserves.edit.request([
        tourId,
        touristId,
        {
          ...values,
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one.c.cancels.c.one, { tourId, touristId }]
      ]))

      onClose()
    },
    [tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Reserve.moveToCancel'
      withoutBtns
    >
      {initialValues && data && (
        <Formik
          initialValues={initialValues}
          validationSchema={cancelFormSchema}
          onSubmit={onSubmit}
        >
          <>
            <Box
              px='i1'
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    <Trans
                      i18nKey='Reserve.moveToCancel.message'
                      values={{ tourist: userToFullFio(data.tourist), tour: tour.title }}
                      components={[
                        <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />,
                        <Typography key={1} display='inline' variant='subtitle1' color='textPrimary' />,
                      ]}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    name='status_payment'
                    label={t('Cancel.status.label')}
                    as={SelectField}
                  >
                    <MenuItem value='cancellation'>
                      <Linely>
                        <CancelStatus status='cancellation' />
                        <Typography>
                          {t('Cancel.status.cancellation')}
                        </Typography>
                      </Linely>
                    </MenuItem>
                    <MenuItem value='transfer'>
                      <Linely>
                        <CancelStatus status='transfer' />
                        <Typography>
                          {t('Cancel.status.transfer')}
                        </Typography>
                      </Linely>
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    multiline
                    name='comment'
                    label={t('Common.comment')}
                    variant="filled"
                    as={TextField}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              px='i1'
            >
              <FormRequiredFieldsHelper />
            </Box>
            <Linely
              end
              gap='i2'
              px='i1'
            >
              <Button
                variant="contained"
                size='large'
                color='inherit'
                onClick={onClose}
              >
                {t('Common.close')}
              </Button>
              <SubmitButtonWrapper>
                <Button
                  variant="contained"
                  size='large'
                >
                  {t('Reserve.moveToCancel')}
                </Button>
              </SubmitButtonWrapper>
            </Linely>
          </>
        </Formik>
      )}
    </Dialog>
  )
}

export default ReserveToCancelModal
