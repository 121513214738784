import { autobind } from "core-decorators"
import { 
    TEndpointModalSubmodelsAndPaths, 
    TEndpointModelEndpointAvailable, 
    TEndpointModelIdGeter, 
    TEndpointModelInheritTheirToBase, 
    TEndpointModelInheritToBaseTheir,
    checkCiclycLinks,
    defaultGetId,
    describeEndpointFromModels,
    subscribeEndpointToModels
} from "./helpers"

@autobind
export class EndpointModel {
    private _getId?: TEndpointModelIdGeter
    public get getId(): TEndpointModelIdGeter { 
        return this.parent?.getId ?? this._getId ?? defaultGetId as TEndpointModelIdGeter 
    }
    
    private _name: string
    public get name() { return this._name }

    private _parent?: EndpointModel
    private get parent() { return this._parent }

    private _isArray: boolean = false
    public get isArray() { return this._isArray }

    private _getIdForHashMapHash?: TEndpointModelIdGeter
    public get getIdForHashMapHash() { return this._getIdForHashMapHash ?? this.getId as TEndpointModelIdGeter }

    private _isHashMap: boolean = false
    public get isHashMap() { return this._isHashMap }

    private _heirToBase?: TEndpointModelInheritTheirToBase<any, any>
    public get heirToBase() { return this._heirToBase }

    private _baseToHeir?: TEndpointModelInheritToBaseTheir<any, any>
    public get baseToHeir() { return this._baseToHeir }

    private _endpoints: Set<TEndpointModelEndpointAvailable> = new Set()
    public get endpoints() { return this._endpoints }

    private _submodelsAndPaths: TEndpointModalSubmodelsAndPaths
    public get submodelsAndPaths(): [string, EndpointModel][] {
        const res = [
            ...(this.parent?.submodelsAndPaths || []),
            ...this._submodelsAndPaths
        ]

        return this._isHashMap
            ? res.map(([path, model]) => [`{}.${path}`, model])
            : this.isArray
                ? res.map(([path, model]) => [`[].${path}`, model])
                : res
    }

    constructor(
        submodelsAndPaths: TEndpointModalSubmodelsAndPaths = [],
        getId?: TEndpointModelIdGeter,
    ) {
        this._name = Math.random().toString().slice(2)
        this._getId = getId

        checkCiclycLinks(submodelsAndPaths, this)
        this._submodelsAndPaths = submodelsAndPaths
    }

    public subscribeEndpoint(endpoint: TEndpointModelEndpointAvailable) {
        this._endpoints.add(endpoint)

        subscribeEndpointToModels(
            endpoint,
            this
        )
    }

    public describeEndpoint(endpoint: TEndpointModelEndpointAvailable) {
        this._endpoints.delete(endpoint)

        describeEndpointFromModels(
            endpoint
        )
    }

    // возвращает модель список содержащий текущие модели
    public listModel() {
        const newModel = new EndpointModel([])

        newModel._isArray = true

        return newModel
    }

    // возвращает модель хешмапу содержащую текущие модели
    public hashMapModel(
        getIdForHashMapHash?: TEndpointModelIdGeter
    ) {
        const newModel = new EndpointModel([])

        newModel._isHashMap = true
        newModel._getIdForHashMapHash = getIdForHashMapHash

        return newModel
    }

    // возвращает модель список содержащий текущие модели
    // отнаследованная модель расширяет базовую
    // id модели строго должен задаваться в базовой модели
    public inheritModel <Their, TBase>(
        submodelsAndPaths: TEndpointModalSubmodelsAndPaths,
        // преобразование расширенной в базу
        // дабы наследование работало хотябы в одну сторону - обязательный аргумент
        heirToBase: TEndpointModelInheritTheirToBase<Their, TBase>,
        // преобразование базы в расширенную, не всегда возможно, поэтому не обязательна
        baseToHeir?: TEndpointModelInheritToBaseTheir<Their, TBase>,
    ) {
        const newModel = new EndpointModel(submodelsAndPaths)

        newModel._parent = this
        newModel._heirToBase = heirToBase
        newModel._baseToHeir = baseToHeir

        return newModel
    }
}


/*
  DstSnRcDataUpdater: если эндп.1 подписан на эндп.2, а эндп.2 подписан на эндп.3, 
  то эндп.1 должен обновить как эндп.2, так и эндп.3

  EndpointModel:
  разделить класс модели на 3: EndpointModel EndpointArrayModel EndpointHashMapModel
  в массивы и хешмапы добавить методы:


  const m1 = new EndpointModel()

  const m2 = new EndpointModel()

  const m3 = new EndpointModel(
    ['path1', m1]
  )

  const m4 = m3.inheritModel([
    [
      ['path2', m2]
    ],
    ({ customProp1, customProp2, ...props }) => props,
    (props, customProps) => ({ ...customProps, ...props })
  ])

  const m5 = m4.inheritModel([
    [],
    ({ customProp1, customProp2, ...props }) => props,
    (props, customProps) => ({ ...customProps, ...props })
  ])

  const m6 = new EndpointModel([
    ['path1.path2.path3', m3.listModel()]
  ])

  const m7 = new EndpointModel([
    ['path1.path2.path3', m4.hashMapModel()]
  ])

  const m8 = new EndpointModel([
    ['path1.[].path2.{}.path3', m6.hashMapModel()]
  ])


  

    m1:

    m2:

    m3:
        '!.path2': m2
        '!': m4
        'path1': m1

    m4:
        '!.path1': m1
        '!': m3
        'path2': m2

    m5:
        '!': m4
        'path1': m1
        'path2': m2

    m6:
        'path1.path2.path3.[].path1': m1
        'path1.path2.path3.[].path2': m2
        'path1.path2.path3.[]: m3
        'path1.path2.path3.[].!: m4

    m7:
        'path1.path2.path3.[].path1': m1
        'path1.path2.path3.[].path2': m2
        'path1.path2.path3.{!}.!: m3
        'path1.path2.path3.{!}: m4

    m8:
        'path1.[].path2.{}.path3.{!}.path1.path2.path3.[].path1.path2.path3.[].path1': m1
        'path1.[].path2.{}.path3.{!}.path1.path2.path3.[].path1.path2.path3.[].path2': m2
        'path1.[].path2.{}.path3.{!}.path1.path2.path3.[]: m3
        'path1.[].path2.{}.path3.{!}.path1.path2.path3.[].!: m4
        'path1.[].path2.{}.path3.{!}': m6
*/
