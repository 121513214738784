import PathSwitch from "../tools/Components/paths/PathSwitch";
import paths from '../paths'
import LoginPage from "../pages/LoginPage";
import PathMatch from "../tools/Components/paths/PathMatch";
import TouristsPage from "../pages/TouristsPage";
import PathCase from "../tools/Components/paths/PathCase";
import ServicePage from "../pages/ServicePage";
import MainPage from "./MainPage";
import { UserContext } from "./UserProvider";
import { useContext, useLayoutEffect } from 'react'
import usePathRouter from "../tools/hooks/paths/usePathRouter";
import EditTourist from "../pages/TouristsPage/Modals/EditTourist";
import CreateTourist from "../pages/TouristsPage/Modals/CreateTourist";
import ToursPage from "../pages/ToursPage";
import CreateTour from "../pages/ToursPage/Modals/CreateTour";
import EditTour from "../pages/ToursPage/Modals/EditTour";
import CreateReserve from "../pages/ToursPage/Reserves/Modals/CreateReserve";
import EditReserve from "../pages/ToursPage/Reserves/Modals/EditReserve";
import CreateWait from "../pages/ToursPage/Waits/Modals/CreateWait";
import EditWait from "../pages/ToursPage/Waits/Modals/EditWait";
import EditCancel from "../pages/ToursPage/Cancels/Modals/EditCancel";

const onlyNoAuth = [
  paths.root.c.login,
]

const needAuth = [
  paths.root.c.tourists,
  paths.root.c.service,
]

function AppRoutes() {
  const { isAuth, isNoAuth } = useContext(UserContext)

  const [router] = usePathRouter()

  useLayoutEffect(
    () => {
      if((isAuth || isNoAuth) && router.root.isMatchExact) {
        router.pushUrl(
          isAuth 
            ? router.root.c.tours.getUrl()
            : router.root.c.login.getUrl()
        )

        return
      }

      if(isAuth) {
        for(const p of onlyNoAuth) {
          if(p.isMatch) {
            router.pushUrl(
              router.root.c.tours.getUrl()
            )
          }
        }

        return
      }

      if(isNoAuth) {
        for(const p of needAuth) {
          if(p.isMatch) {
            router.pushUrl(
              router.root.c.login.getUrl()
            )
          }
        }

        return
      }
    },
    [isAuth, isNoAuth, router]
  )

  return (isAuth || isNoAuth) && (
    <>
      <PathSwitch>
        <PathMatch exact path={paths.root.c.login} Component={LoginPage} />
        <PathCase>
          <MainPage>
            <PathSwitch>
              <PathMatch path={paths.root.c.tourists} Component={TouristsPage} />
              <PathMatch path={paths.root.c.tours} Component={ToursPage} />
              <PathMatch path={paths.root.c.service} Component={ServicePage} />
            </PathSwitch>
          </MainPage>
        </PathCase>
      </PathSwitch>
      <PathMatch openable path={paths.root.c.createTourist} Component={CreateTourist} />
      <PathMatch openable path={paths.root.c.editTourist.c.one} Component={EditTourist} />
      <PathMatch openable path={paths.root.c.createTour} Component={CreateTour} />
      <PathMatch openable path={paths.root.c.editTour.c.one} Component={EditTour} />
      <PathMatch openable path={paths.root.c.createReserve.c.tour} Component={CreateReserve} />
      <PathMatch openable path={paths.root.c.editReserve.c.tour.c.tourist} Component={EditReserve} />
      <PathMatch openable path={paths.root.c.createWait.c.tour} Component={CreateWait} />
      <PathMatch openable path={paths.root.c.editWait.c.tour.c.tourist} Component={EditWait} />
      <PathMatch openable path={paths.root.c.editCancel.c.tour.c.tourist} Component={EditCancel} />
    </>
  );
}

export default AppRoutes;
