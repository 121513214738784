import { Typography } from '@mui/material';
import Dialog from './Dialog';
import Linely from '../../tools/Components/atoms/Linely';
import { useCallback, useState } from 'react';
import { Event } from '../../tools/libs/event';
import useOnEvent from '../../tools/hooks/event/useOnEvent';

export const ApiErrorsListnerEvent = new Event()

const ApiErrorsListner = () => {
  const [errorApiResponse, setErrorApiResponse] = useState()

  const handleClose = useCallback(
    () => setErrorApiResponse(null),
    []
  )

  useOnEvent(
    ApiErrorsListnerEvent,
    (response) => setErrorApiResponse(response)
  )

  return (
    <Dialog
      noClose
      opened={!!errorApiResponse}
      lTitle={['ApiError.title', { status: errorApiResponse?.status }]}
      lSubmitLable='Common.close'
      onClose={handleClose}
      onSubmit={handleClose}
    >
      <Linely
        vertical
        px='i1'
        gap='i3'
      >
        <Typography>
          {errorApiResponse?.error?.detail || ':('}
        </Typography>
      </Linely>
    </Dialog>
  )
}

export default ApiErrorsListner
