import api from '../../../endpoints';
import router from '../../../paths';
import { remove } from '../../../tools/libs/paths/helpers';
import Dialog from '../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import { Formik } from 'formik';
import TouristModalForm from './TouristModalForm';
import { phonePreprocessToServer } from '../../LoginPage';
import { touristFormSchema } from './touristFormSchema';

const CreateTourist = ({ onSubmit, onClose, ...props }) => {
  const handleClose = useCallback(
    ({ id }) => {
      if(onSubmit) {
        onClose?.()
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.createTourist, remove()],
        ...(id ? [[router.root.c.tourists.c.one, { touristId: id }]] : [])
      ]))

      onClose?.()
    },
    [onSubmit, onClose]
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { data, error } = await api.tree.tourists.create.request({
        ...values,
        phone: phonePreprocessToServer(values.phone),
        birthday: values.birthday?.unix()
      })

      setSubmitting(false)

      if(error) {
        return
      }

      onSubmit?.(data)
      handleClose(data)
    },
    [handleClose, onSubmit]
  )

  return (
    <Dialog
      {...props}
      onClose={handleClose}
      lTitle='Tourist.createModal.title'
      lSubmitLable='Common.create'
      withoutBtns
    >
      <Formik
        initialValues={{ citizenship: null }}
        validationSchema={touristFormSchema}
        onSubmit={handleSubmit}
      >
        <TouristModalForm
          lSubmitLable='Common.create'
          onClose={handleClose}
        />
      </Formik>
    </Dialog>
  )
}

export default CreateTourist
