import api from '../../../endpoints';
import Dialog from '../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { Trans } from 'react-i18next';
import Box from '../../../tools/Components/atoms/Box';
import { Typography } from '@mui/material';

const ArchiveTour = ({ id, onClose, ...props }) => {
  const [{ data: tour }] = useEnObserve(api.tree.tours.one, id, null, !!id)

  const handleSubmit = useCallback(
    async () => {
      const { error } = await api.tree.tours.archive.request(id)

      if(error) {
        return
      }

      onClose()
    },
    [id, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Tour.archiveModal.title'
      message={(
        <Trans
          i18nKey='Tour.archiveModal.message'
          values={{ title: tour?.title || '' }}
          components={[
            <Typography key={0} display='inline' variant='subtitle1' />,
            <Box key={1} mt='i1' />,
          ]}
        />
      )}
      lSubmitLable='Common.archive'
      onSubmit={handleSubmit}
    />
  )
}

export default ArchiveTour
