import { Typography, ButtonBase } from '@mui/material'
import Box from '../../../tools/Components/atoms/Box'
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Icon from '../../widgets/Icon';
import Linely from '../../../tools/Components/atoms/Linely';
import { useCallback, useRef } from 'react';
import useBoolean from '../../../tools/hooks/useBoolean';
import ShowOnEvent from '../../../tools/Components/event/ShowOnEvent';

function SortableTableHeadCell ({ onSetSorter, sorter, column }) {
  const rootRef = useRef()

  const [hovered, , , activateHovered, deactivateHovered] = useBoolean(false)

  const currentSorterSelected = column.sorter && sorter.name === column.sorter

  const currentFilterSelected = column.filterIsActive

  const onClickSorter = useCallback(
    () => {
      onSetSorter?.({
        name: column.sorter,
        asc: currentSorterSelected && sorter?.asc ? !sorter?.asc : true
      })
    },
    [column.sorter, onSetSorter, sorter, currentSorterSelected]
  )

  return (
    <Box
      ref={rootRef}
      position='relative'
      onMouseOver={activateHovered}
      onMouseLeave={deactivateHovered}
    >
      <Linely
        position='absolute'
        top={0}
        right={0}
        bottom={0}
        backgroundColor='white'
        pl='i4'
        hide={!(hovered || currentSorterSelected || currentFilterSelected)}
      >
        <Box
          hide={!((column.filter && hovered) || currentFilterSelected)}
        >
          <ShowOnEvent
            {...column.filterComponentProps}
            event='click'
            Component={column.filterComponent}
          >
            <ButtonBase>
              <Icon
                small
                color={currentFilterSelected ? 'primary' : 'inherit'}
                Component={FilterListIcon}
              />
            </ButtonBase>
          </ShowOnEvent>
        </Box>
        {((column.sorter && hovered) || currentSorterSelected) && (
          <ButtonBase
            onClick={onClickSorter}
          >
            <Icon
              small
              Component={ArrowDownwardIcon}
              color={currentSorterSelected ? 'primary' : 'inherit'}
              style={{
                transform: currentSorterSelected && !sorter?.asc ? 'rotate(180deg)' : ''
              }}
            />
          </ButtonBase>
        )}
      </Linely>
      <Typography variant='body2'>
        {column.label}
      </Typography>
    </Box>
  )
}

export default SortableTableHeadCell
