import { forwardRef /* useRef, useCallback, useMemo */ } from 'react'
import Box from './atoms/Box'
/* import useMergeRefs from '../hooks/useMergeRefs'
import useOnEvent from '../hooks/event/useOnEvent'
import { browserEvent, browserResizeEvent } from '../libs/event'
import useRefVar from '../hooks/useRefVar'
import { config } from '../utils/config' */

function Stickly (
  { 
    children, 
    minTop = 0, 
    minBottom = 0,
    StickProps = {},
    ...props
  }, 
  ref
) {
  return (
    <Box
      {...props}
      {...StickProps}
    >
      {children}
    </Box>
  )

  /* const refMinTop = useRefVar(minTop)
  const refMinBottom = useRefVar(minBottom)

  const stickRef = useRef()

  const prevScrollTopPosition = useRef()
  const prevScrollLeftPosition = useRef()

  const refStickInnerProps = useRef({})

  const setStickInnerProps = useCallback(
    newProps => {
      if(!stickRef.current) {
        return
      }

      for(const [prop] of Object.entries(refStickInnerProps.current)) {
        if(newProps[prop]) {
          continue
        }

        stickRef.current.style[prop] = null
      }

      for(const [prop, value] of Object.entries(newProps)) {
        stickRef.current.style[prop] = value
      }

      refStickInnerProps.current = newProps
    },
    []
  )

  const recalc = useCallback(
    e => {
      // попытка пофиксить багу перелыгина на макбуке в хроме
      try {
        // Начало координат: самая верхняя левая точка страницы. Вне всяких скролов
        // Все переменные ниже указывающие на какую либо точку в своём названии
        // подразумевают что точка находится в выше описанной системе координат

        const windowHeight = document.documentElement.clientHeight
        const availableHeight = windowHeight - refMinTop.current - refMinBottom.current

        const scrollTopPosition = document.documentElement.scrollTop
        const scrollBottomPosition = scrollTopPosition + windowHeight
        const scrollLeftPosition = document.documentElement.scrollLeft

        // Если нет хотябы одного из них - значит нет и второго
        if(prevScrollTopPosition.current == null) {
          prevScrollTopPosition.current = scrollTopPosition
          prevScrollLeftPosition.current = scrollLeftPosition
        }

        const deltaScrollTopPosition = scrollTopPosition - prevScrollTopPosition.current

        const parentBoundingRect = stickRef.current.parentNode.getBoundingClientRect()
        const parentTopPoint = scrollTopPosition + parentBoundingRect.top
        // const parentBottomPoint = parentTopPoint + parentBoundingRect.height

        const stickBoundingRect = stickRef.current.getBoundingClientRect()
        const stickTopPoint = scrollTopPosition + stickBoundingRect.top
        const stickBottomPoint = stickTopPoint + stickBoundingRect.height
        const stickHeight = stickRef.current.offsetHeight

        const marginTop = stickTopPoint - parentTopPoint

        // у обеих переменных ниже вопросы к названиям
        const stickLeftScreenOffset = parentBoundingRect.left
        const stickLeftParentOffset = stickLeftScreenOffset - parentBoundingRect.left

        // Если элемент один единственный - мы это не отследим
        // и нужно задать заранее высоту для родителя.
        // без SetTimeout крашится ResizeObserver
        // тут же задаём ребенку ширину родителя, а то она пляшет
        setTimeout(
          () => {
            stickRef.current.parentNode.style.minHeight = `${stickHeight}px`
            stickRef.current.style.width = `${stickRef.current.parentNode.offsetWidth}px`
          },
          0
        )

        if(refStickInnerProps.current.position) {
          // задаём отступ слева на тот случай если будет горизонтальный скролл
          setStickInnerProps({
            ...refStickInnerProps.current,
            position: refStickInnerProps.current.position === 'fixed' ? 'fixed' : 'relative',
            left: `${refStickInnerProps.current.position === 'fixed' ? stickLeftScreenOffset : stickLeftParentOffset}px`
          })
        }

        // Если элемент меньше доступной ему зоны - можно смело его зафиксировать вверху и забыть
        if(availableHeight >= stickHeight) {
          setStickInnerProps({
            position: 'fixed',
            // width: 'inherit',
            top: `${refMinTop.current}px`,
            left: `${stickLeftScreenOffset}px`
          })
        }
        
        // Самый первый рендер элементов
        else if(!(e instanceof Event) && (scrollBottomPosition > (stickBottomPoint + refMinBottom.current))) {
          setStickInnerProps({
            position: 'fixed',
            // width: 'inherit',
            bottom: `${refMinBottom.current}px`,
            left: `${stickLeftScreenOffset}px`
          })
        }

        // Движение вниз
        else if(deltaScrollTopPosition > 0) {

          // если мы скролили вниз и уперлись в нижнюю границу
          if(scrollBottomPosition > (stickBottomPoint + refMinBottom.current)) {
            setStickInnerProps({
              position: 'fixed',
              // width: 'inherit',
              bottom: `${refMinBottom.current}px`,
              left: `${stickLeftScreenOffset}px`
            })
          }

          // если мы скролили вверх уперевшись в вверхнюю границу
          // и начали скролить вниз
          if(scrollTopPosition === (stickTopPoint - refMinTop.current)) {
            setStickInnerProps({
              position: 'relative',
              // fucking margins
              // marginTop: `${marginTop}px`    is not work
              // parentNode начинает съезжать вниз и из-за этого скачет скроллбар
              // + иногда блочится прокрутка наверх
              transform: `translateY(${marginTop}px)`,
              left: `${stickLeftParentOffset}px`
            })
          }
        }

        // Движение вверх
        else if (deltaScrollTopPosition < 0) {

          // если мы скролили вверх и уперлись в верхнюю границу
          if(scrollTopPosition < (stickTopPoint - refMinTop.current)) {
            setStickInnerProps({
              position: 'fixed',
              // width: 'inherit',
              top: `${refMinTop.current}px`,
              left: `${stickLeftScreenOffset}px`
            })
          }

          // если мы скролили вниз уперевшись в нижнюю границу
          // и начали скролить вверх
          if(scrollBottomPosition === (stickBottomPoint + refMinBottom.current)) {
            setStickInnerProps({
              position: 'relative',
              // fucking margins
              // marginTop: `${marginTop}px`    is not work
              // parentNode начинает съезжать вниз и из-за этого скачет скроллбар
              // + иногда блочится прокрутка наверх
              transform: `translateY(${marginTop}px)`,
              left: `${stickLeftParentOffset}px`
            })
          }
        }

        prevScrollTopPosition.current = scrollTopPosition
      }
      catch(e) {
        // чтоб самому видеть баги
        if(config.isDevelopment) {
          throw e
        }
      }
    },
    [refStickInnerProps, refMinTop, refMinBottom, setStickInnerProps]
  )

  useOnEvent(
    useMemo(() => browserEvent(document, 'scroll'), []),
    recalc
  )

  useOnEvent(
    // eslint-disable-next-line
    useMemo(() => browserResizeEvent(stickRef.current), [stickRef.current]),
    recalc
  )

  useOnEvent(
    // eslint-disable-next-line
    useMemo(() => browserResizeEvent(stickRef.current?.parentNode), [stickRef.current]),
    recalc
  )

  return (
    <Box
      {...props}
    >
      <Box
        {...StickProps}
        ref={useMergeRefs(ref, stickRef)}
      >
        {children}
      </Box>
    </Box>
  ) */
}

export default forwardRef(Stickly)
