import api from '../../../../endpoints';
import router from '../../../../paths';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback, useState } from 'react'
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, MenuItem, Typography } from '@mui/material';
import { userToFullFio } from '../../../../utils/helpers';
import SelectField from '../../../../ui/formik/SelectField';
import Linely from '../../../../tools/Components/atoms/Linely';
import ReserveStatus from '../../../../ui/widgets/ReserveStatus';

const CancelToReserveModal = ({ tourId, touristId, onClose, ...props }) => {
  const { t } = useTranslation()

  const [{ data }] = useEnObserve(api.tree.reserves.one, [tourId, touristId], null, !!touristId)

  const [statusPayment, setStatusPayment] = useState('prepayment')

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const onSubmit = useCallback(
    async () => {
      const { error } = await api.tree.reserves.edit.request([
        tourId,
        touristId,
        {
          ...data,
          tourist: undefined,
          status_payment: statusPayment,
        }
      ])

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one.c.reserves.c.one, { tourId, touristId }]
      ]))

      onClose()
    },
    [data, statusPayment, tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Cancel.moveToReserve'
      onSubmit={onSubmit}
      lSubmitLable='Cancel.moveToReserve'
    >
      <DialogContent>
        <Linely
          vertical
          gap='i2'
        >
          {data && (
            <Typography>
              <Trans
                i18nKey='Cancel.moveToReserve.message'
                values={{ tourist: userToFullFio(data.tourist), tour: tour.title }}
                components={[
                  <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />,
                  <Typography key={1} display='inline' variant='subtitle1' color='textPrimary' />,
                ]}
              />
            </Typography>
          )}
          <SelectField
            value={statusPayment}
            label={t('Reserve.status')}
            onChange={e => setStatusPayment(e.target.value)}
          >
            <MenuItem value='full'>
              <Linely>
                <ReserveStatus status='full' />
                <Typography>
                  {t('Reserve.status.full')}
                </Typography>
              </Linely>
            </MenuItem>
            <MenuItem value='prepayment'>
              <Linely>
                <ReserveStatus status='prepayment' />
                <Typography>
                  {t('Reserve.status.prepayment')}
                </Typography>
              </Linely>
            </MenuItem>
          </SelectField>
        </Linely>
      </DialogContent>
    </Dialog>
  )
}

export default CancelToReserveModal
