import { autobind } from "core-decorators"
import { Event } from "../../event"
import { IEndpointBaseResponse, DstSnRc, IDstSnRcConfig } from "../baseEndpoints"
import { TDstSnRcDataUpdaterReloadUpdate, TDstSnRcDataUpdaterTransformUpdate } from "./DstSnRcDataUpdater"
import { EndpointModel } from "."

export interface IEndpointObserverState<TData, TError> extends IEndpointBaseResponse<TData, TError> {
    isLoading: boolean
}

@autobind
export class EndpointObserver<
    TOriginalResponse,
    TConfig extends IDstSnRcConfig = IDstSnRcConfig,
    TParams extends any = any, 
    TData extends any = any,
    TError extends any = any,
    TResponse extends IEndpointBaseResponse<TData, TError> = IEndpointBaseResponse<TData, TError>
> {
    private _config?: TConfig
    public get config() { return this._config }

    private _onUpdate: Event<[IEndpointObserverState<TData, TError>]> = new Event()
    public get onUpdate() { return this._onUpdate }

    private _endpoint: DstSnRc<TOriginalResponse, TConfig, TParams, TData, TError, TResponse>
    public get endpoint() { return this._endpoint }

    private _params: TParams
    public get params() { return this._params }

    private _isLoading: boolean = false
    public get isLoading() { return this._isLoading }

    private _response: TResponse = { data: undefined, error: undefined } as TResponse
    public get response() { return this._response }
    
    public get state(): IEndpointObserverState<TData, TError> { return { 
        ...this._response, 
        isLoading: this.isLoading 
    } }
    
    constructor(
        params: TParams,
        endpoint: DstSnRc<TOriginalResponse, TConfig, TParams, TData, TError, TResponse>,
        config?: TConfig
    ) {
        this._params = params
        this._endpoint = endpoint
        this._config = config

        this.subscribeToDataUpdater()

        this.reload()
    }

    public async reload() {
        this._isLoading = true
        this.onUpdate.invoke(this.state)

        const response = await this.endpoint.request(this.params, this.config)

        this._isLoading = false
        this._response = { 
            ...this._response,
            ...response,
            error: response.error,
            data: response.error === undefined ? response.data : this._response.data
        }
        this.onUpdate.invoke(this.state)

        return response
    }

    private subscribeToDataUpdater() {
        this.endpoint.dataUpdater.model?.onUpdate.subscribe(
            (path, id, data, endpoint, model) => {
                if(
                    this.state.data === data ||
                    !this.response.data
                ) {
                    return
                }

                this.updateDataFromSubscribe(
                    (this.endpoint.dataUpdater.model as EndpointModel<any, TData>).getUpdatedData(
                        this.response.data, 
                        path, 
                        id, 
                        data, 
                        model
                    )
                )
            }
        )

        this.endpoint.dataUpdater.onReloadUpdate.subscribe(
            <TParamsOrigin, TDataOrigin>(
                paramsOrigin: TParamsOrigin, 
                dataOrigin: TDataOrigin, 
                filter: TDstSnRcDataUpdaterReloadUpdate<TParamsOrigin, TDataOrigin, TParams, TData>
            ) => {
                if(
                    this.state.data && 
                    filter(
                        this.state.data,
                        dataOrigin,
                        paramsOrigin,
                        this.params
                    )
                ) {
                    this.reload()
                }
            }
        )

        this.endpoint.dataUpdater.onTransformUpdate.subscribe(
            <TParamsOrigin, TDataOrigin>(
                paramsOrigin: TParamsOrigin, 
                dataOrigin: TDataOrigin, 
                transform: TDstSnRcDataUpdaterTransformUpdate<TParamsOrigin, TDataOrigin, TParams, TData>
            ) => {
                if(this.state.data === undefined) {
                    return
                }

                this.updateDataFromSubscribe(transform(
                    this.state.data,
                    dataOrigin,
                    paramsOrigin,
                    this.params
                ))
            }
        )
    }

    private updateDataFromSubscribe(data: TData) {
        this._response = { ...this._response, data }
        this.onUpdate.invoke(this.state)
    }
}
