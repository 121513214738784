import { autobind } from "core-decorators"
import { IHashMap } from "../../../utils/types"
import { DstRc, DstRcReceiver, IDstRcConfig } from "../baseEndpoints/DstRc"
import { IEndpointBaseResponse, TConfigParamsableProperty, compilePropFunction } from "../baseEndpoints/"
import { transformArryableVar } from "../../../utils/array"
import { Event as TrueEvent } from "../../event"

export type TWsProtocols = string | string[] | undefined
export type TWsMsg = string | ArrayBufferLike | Blob | ArrayBufferView

interface IConfigDstRcWs<
    TParams, 
    TResponse,
> extends IDstRcConfig {
    url: TConfigParamsableProperty<string, TParams>
    protocols?: TConfigParamsableProperty<TWsProtocols, TParams>
    responsePreprocess: (originalResponse: MessageEvent<any>) => TResponse
}

@autobind
export class WsClient {
    private _ws: WebSocket
    public get ws() { return this._ws }

    public _url: string
    public get url() { return this._url }

    public _protocols: TWsProtocols
    public get protocols() { return this._protocols }

    public _online: boolean
    public get online() { return this._online }

    public _onMessage: TrueEvent<[MessageEvent<any>]>
    public get onMessage() { return this._onMessage }

    public _onOpen: TrueEvent<[Event]>
    public get onOpen() { return this._onOpen }

    public _onClose: TrueEvent<[CloseEvent | Event, boolean]>
    public get onClose() { return this._onClose }

    constructor(url: string, protocols?: TWsProtocols) {
        // @ts-ignore
        this._ws = null

        this._url = url
        this._protocols = protocols
        this._online = false

        this._onMessage = new TrueEvent()
        this._onOpen = new TrueEvent()
        this._onClose = new TrueEvent()
        
        this.reconnect()
    }

    public reconnect() {
        this._ws = new WebSocket(this.url, this.protocols)

        this.ws.onclose = this.onCloseHandler
        this.ws.onerror = this.onErrorHandler
        this.ws.onmessage = this.onMessageHandler
        this.ws.onopen = this.onOpenHandler
    }

    public send(message: TWsMsg) {
        if(!this.online) {
            return false
        }

        this.ws.send(message)
        return true
    }

    private onCloseHandler(event: CloseEvent) {
        this._online = false
        this.onClose.invoke(event, false)
        this.reconnect()
    }

    private onErrorHandler(event: Event) {
        this._online = false
        this.onClose.invoke(event, true)
        this.reconnect()
    }

    private onMessageHandler(event: MessageEvent<any>) {
        this.onMessage.invoke(event)
    }

    private onOpenHandler(event: Event) {
        this._online = true
        this.onOpen.invoke(event)
    }
}

@autobind
export class WsClientsStore {
    private _store: IHashMap<WsClient> = {}
    public get store() { return this._store }

    public static getHash(url: string, protocols: TWsProtocols = []) {
        return [url, ...transformArryableVar(protocols)].join('..')
    }

    public addOrGet(url: string, protocols?: TWsProtocols) {
        const hash = WsClientsStore.getHash(url, protocols)
        
        if(!this.store[hash]) {
            this.store[hash] = new WsClient(url, protocols)
        }

        return this.store[hash]
    }
}

@autobind
export class DstRcWsReceiver<
    TParams = any, 
    TData extends any = any,
    TError extends any = any,
    TResponse extends IEndpointBaseResponse<TData, TError> = IEndpointBaseResponse<TData, TError>,
    TConfig extends IConfigDstRcWs<TParams, TResponse> = IConfigDstRcWs<TParams, TResponse>
> extends DstRcReceiver<
    TConfig,
    TParams, 
    TData, 
    TError,
    TResponse
> {
    private _wsClient: WsClient

    constructor(
        params: TParams, 
        parent: DstRcWs<TParams, TData, TError, TResponse, TConfig>
    ) {
        super(params, parent)

        const url = compilePropFunction(parent.getConfigProp('url'), params) as string
        const protocols = compilePropFunction(parent.getConfigProp('protocols'), params) as string | string[]

        this._wsClient = new WsClient(url, protocols)

        this._wsClient.onMessage.subscribe(this.onReceive)
    }

    private onReceive(message: MessageEvent<any>) {
        const responsePreprocess = this.parent.getConfigProp('responsePreprocess') as (originalResponse: MessageEvent<any>) => TResponse

        if(responsePreprocess) return responsePreprocess(message)

        try {
            return { error: undefined, data: JSON.parse(message.data) } as TResponse
        }
        catch(error) {
            return { data: undefined, error } as TResponse
        }
    }
}

export const clientsStore = new WsClientsStore()

@autobind
export class DstRcWs<
    TParams = any, 
    TData extends any = any,
    TError extends any = any,
    TResponse extends IEndpointBaseResponse<TData, TError> = IEndpointBaseResponse<TData, TError>,
    TConfig extends IConfigDstRcWs<TParams, TResponse> = IConfigDstRcWs<TParams, TResponse>
> extends DstRc<
    TConfig,
    TParams, 
    TData, 
    TError,
    TResponse
> {
    protected get configName() {
        return 'DstRcWs'
    }
    
    protected makeDstRcReceiver(
        params: TParams, 
        parent: DstRc<TConfig, TParams, TData, TError, TResponse>
    ): DstRcReceiver<TConfig, TParams, TData, TError, TResponse> {
        return new DstRcWsReceiver(
            params, 
            parent as DstRcWs<TParams, TData, TError, TResponse, TConfig>
        )
    }
}
