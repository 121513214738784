import { useState, useMemo } from 'react';
import { MenuItem, Typography } from '@mui/material'
import SelectField from './SelectField'
import Box from '../../tools/Components/atoms/Box';
import FilledSearch from '../widgets/FilledSearch';
import { useTranslation } from 'react-i18next';

const SearchableSelectField = ({ items, itemStrSearch, renderItem, ...props }) => {
  const { t } = useTranslation()
  
  const [searchValue, setSearchValue] = useState()

  const searchedItems = useMemo(
    () => (items || [])
      .filter(item => !searchValue || itemStrSearch(item).toLowerCase().indexOf(searchValue.toLowerCase()) > -1),
    [searchValue, itemStrSearch, items]
  )

  return (
    <SelectField
      {...props}
    >
      <Box
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        p='i2'
        position='sticky'
        top={0}
        backgroundColor='#fff'
        zIndex={9}
      >
        <FilledSearch
          value={searchValue}
          onChange={setSearchValue}
        />          
      </Box>
      {!searchValue && (
        <MenuItem value={null}>
          <Typography sx={{ fontStyle: 'italic' }}>
            {t('Common.notSelected')}
          </Typography>
        </MenuItem>
      )}
      {searchedItems.map(renderItem)}
    </SelectField>
  )
}

export default SearchableSelectField
