import { Button, useTheme } from '@mui/material';
import { forwardRef } from 'react';

const CircleButton = forwardRef((
  { size, small, big, ...props },
  ref
) => {
  const theme = useTheme()
  
  const realSize = size ?? (small ? 'small' : (big ? 'big' : "medium"))

  const pxSize = `${theme.iconSizes[realSize] * 1.6}px`

  return (
    <Button
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        borderRadius: '100%',
        minWidth: 0,
        p: 0,
        width: pxSize,
        height: pxSize,
      }}
    />
  )
})

export default CircleButton
