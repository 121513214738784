import { PathNode } from "."
import { IHashMap } from "../../utils/types"

export enum EURL_SET_TYPE {
    push = 'push',
    replace = 'replace'
}

export enum EURL_SET_TYPE_EVENT_ONLY {
    browserBackNext = 'browserBackNext'
}

export type TURL_SET_TYPE_EVENT = EURL_SET_TYPE | EURL_SET_TYPE_EVENT_ONLY

export enum EURL_STYLE {
    main = 'main',
    queryParams = 'queryParams',
}

export interface IUrlParts { 
    mainPath: string[], 
    optionalPaths: IUrlParts[],
    // позиция в mainPath в которой в родительской ноде лежит текущий UrlParts
    // Значение может быть не определено, если например из урла тянется
    parentMainPathPosition?: number,
    isClosedPath?: boolean
}

export type TParametrType = 'number' | 'string' | RegExp
export type TPathParamValue = string | number | (string | number)[] | null | boolean
export type TQueryParams = IHashMap<TPathParamValue>
export type TPathParams = IHashMap<TPathParamValue | TPathParams>
export type TPathLocalParams = IHashMap<TPathParamValue>



export interface IPathParamsLocal extends TPathParams { __isPathParamsLocal: 'true', params: TPathParams }
export interface IPathParamsClosed extends TPathParams { __isPathParamsClosed: 'true', params: TPathParams | IPathParamsLocal }
export interface IPathParamsRemove extends TPathParams { __isPathParamsRemove: 'true' }



export type TNodeParam = [PathNode<any>, TPathParams | IPathParamsLocal]
