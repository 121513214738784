import { useMemo } from 'react'
import { IHashMap } from '../utils/types'

function useMemoObject<T extends IHashMap>(data: T) {
  return useMemo(
    () => data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.entries(data)
      .sort(([key1], [key2]) => key1 > key2 ? 1 : key1 < key2 ? -1 : 0 )
      .flat()
  )
}

export default useMemoObject
