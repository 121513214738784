import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ITourist } from "./TouristEndpoint";
import { TouristsEndpoint } from "./TouristsEndpoint";

export const TouristArchiveEndpoint = new DstSnRcHttp<
  number,
  ITourist
>({
  method: HTTP_METHODS.patch,
  url: id => `/tourists/${id}/archive`,
})

TouristArchiveEndpoint.transformUpdateFor(
  TouristsEndpoint,
  (oldData, newTourist) => oldData.map(t => t.id === newTourist.id ? newTourist : t)
)
