import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export interface IUser {
  surname: string       // фамилия
  first_name: string    // имя
  second_name: string   // отчество
  phone: string
  birthday: number
  permissions: string[]
}

export const CurentUserEndpoint = new DstSnRcHttp<
  null,
  IUser
>({
  method: HTTP_METHODS.get,
  url: '/auth/me'
})
