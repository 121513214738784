import { Trans, useTranslation } from 'react-i18next';
import { Grid, Button, MenuItem, Typography } from '@mui/material'
import { Field, useFormikContext } from 'formik';
import Box from '../../../../tools/Components/atoms/Box';
import SubmitButtonWrapper from '../../../../tools/Components/formik/SubmitButtonWrapper';
import Linely from '../../../../tools/Components/atoms/Linely';
import TouristReserveField from '../../ui/TouristReserveField';
import ReserveStatus from '../../../../ui/widgets/ReserveStatus';
import { getRepayment } from '../../../../utils/helpers';
import SelectField from '../../../../ui/formik/SelectField';
import TextField from '../../../../ui/formik/TextField';
import FormRequiredFieldsHelper from '../../../../ui/widgets/FormRequiredFieldsHelper';

const ReserveModalForm = ({ price, lSubmitLable, onClose }) => {
  const { t } = useTranslation()

  const { values: { paid } } = useFormikContext()

  const prepayment = getRepayment(price)

  const remainder = typeof paid === 'number' ? price - paid : '?'

  return (
    <>
      <Box
        px='i1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              required
              name='touristId'
              label={t('Common.tourist')}
              variant="filled"
              component={TouristReserveField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              required
              name='status_payment'
              label={t('Reserve.status')}
              as={SelectField}
            >
              <MenuItem value='full'>
                <Linely>
                  <ReserveStatus status='full' />
                  <Typography>
                    {t('Reserve.status.full')}
                  </Typography>
                </Linely>
              </MenuItem>
              <MenuItem value='prepayment'>
                <Linely>
                  <ReserveStatus status='prepayment' />
                  <Typography>
                    {t('Reserve.status.prepayment')}
                  </Typography>
                </Linely>
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              required
              name='paid'
              type='number'
              label={t('Common.payed')}
              variant="filled"
              as={TextField}
              InputProps={{
                endAdornment: (
                  <Typography
                    align='right'
                    display='inline'
                    color='textSecondary'
                    minWidth='fit-content'
                  >
                    <Trans
                      i18nKey='Reserve.paid'
                      values={{ price, prepayment, remainder }}
                      components={[
                        <br />
                      ]}
                    />
                  </Typography>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name='booking_source'
              label={t('Reserve.source')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='tickets'
              label={t('Reserve.tickets.title')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='comment'
              label={t('Common.comment')}
              variant="filled"
              as={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        px='i1'
      >
        <FormRequiredFieldsHelper />
      </Box>
      <Linely
        end
        gap='i2'
        px='i1'
      >
        <Button
          variant="contained"
          size='large'
          color='inherit'
          onClick={onClose}
        >
          {t('Common.close')}
        </Button>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size='large'
          >
            {t(lSubmitLable)}
          </Button>
        </SubmitButtonWrapper>
      </Linely>
    </>
  )
}

export default ReserveModalForm
