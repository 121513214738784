import { useLayoutEffect, useRef } from 'react'
import moment from 'moment'

// jstimestamp - милисекунды или null, если null - ничего не делать
// если jstimestamp - не число, action не будет выполнено никогда
function useActionAt (jstimestamp, action) {
  const refLastTimeOut = useRef(null)

  useLayoutEffect(() => {
    if (refLastTimeOut.current) clearTimeout(refLastTimeOut.current)

    if (jstimestamp == null) {
      return
    }

    if (jstimestamp - moment().valueOf() > 0) {
      refLastTimeOut.current = setTimeout(
        action,
        jstimestamp - moment().valueOf()
      )
    } else if (typeof jstimestamp === 'number') action()
  }, [action, jstimestamp, refLastTimeOut])
}

export default useActionAt
