export interface TConfig {
  isDevelopment: boolean
}

export class Config {
  public config: TConfig

  private static instance: Config

  constructor() {
    this.config = {
      isDevelopment: true
    }

    Config.instance = this
  }

  public set(config: TConfig) {
    // если просто переприсвоить конфиг - экспортированный конфиг (this.config) будет прежним
    // поэтому сначала затрём старые параметры
    Object.keys(this.config).forEach(
      //@ts-ignore
      key => { delete this.config[key as keyof TConfig] }
    )
    // затем добавим новые
    Object.assign(this.config, config)
  }

  public static set(config: TConfig) {
    Config.instance.set(config)
  }
}

const instance = new Config()

export const config = instance.config
