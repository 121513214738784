import { EndpointModel } from "../utils/"
import { anyFirstLvlIsEqual, sleep } from "../../../utils/helpers"
import { EndpointObserver } from "../utils/EndpointObserver"
import { EndpointBase, IEndpointBaseConfig, IEndpointBaseResponse } from "./EndpointBase"
import { autobind } from "core-decorators"
import { 
    DstSnRcDataUpdater, 
    TDstSnRcDataUpdaterReloadUpdate, 
    TDstSnRcDataUpdaterTransformUpdate, 
    TEndpointDstUpdate 
} from '../utils/DstSnRcDataUpdater'



    
export interface IDstSnRcConfig extends IEndpointBaseConfig {
    noLog?: boolean
}

export type TDstSnRcMockFilter<TParams> = TParams | boolean | ((params: TParams) => boolean)
export type TDstSnRcMockResponseGetter<
    TConfig extends IDstSnRcConfig,
    TParams extends any, 
    TData extends any,
    TError extends any,
    TResponse extends IEndpointBaseResponse<TData, TError>
> = TResponse | ((params: TParams, localConfig?: TConfig) => TResponse)

export class DstSnRcMock<
    TConfig extends IDstSnRcConfig,
    TParams extends any, 
    TData extends any,
    TError extends any,
    TResponse extends IEndpointBaseResponse<TData, TError>
> {
    private _filter: TDstSnRcMockFilter<TParams>
    public get filter() { return this._filter }
    
    private _responseGetter: TDstSnRcMockResponseGetter<TConfig, TParams, TData, TError, TResponse>
    public get responseGetter() { return this._responseGetter }
    
    private _delay: number
    public get delay() { return this._delay }

    public constructor(
        filter: TDstSnRcMockFilter<TParams>, 
        responseGetter: TDstSnRcMockResponseGetter<TConfig, TParams, TData, TError, TResponse>, 
        delay: number = 600
    ) {
        this._filter = filter
        this._responseGetter = responseGetter
        this._delay = delay
    }

    public check(params: TParams) {
        return typeof this.filter === 'boolean' 
            ? this.filter 
            : typeof this.filter === 'function'
                //@ts-ignore
                ? this.filter(params) as boolean
                : anyFirstLvlIsEqual(this.filter, params)
    }

    public async getResponse(params: TParams, localConfig?: TConfig) {
        await sleep(this.delay)
        return typeof this.responseGetter === 'function' ? this.responseGetter(params, localConfig) : this.responseGetter
    }
}

@autobind
export abstract class DstSnRc<
    TOriginalResponse,
    TConfig extends IDstSnRcConfig,
    TParams extends any, 
    TData extends any,
    TError extends any,
    TResponse extends IEndpointBaseResponse<TData, TError>
> extends EndpointBase<TConfig, TParams, TData, TError> {
    
    private _dataUpdater: DstSnRcDataUpdater<TParams, TData>
    public get dataUpdater() { return this._dataUpdater }

    private _mocks: DstSnRcMock<TConfig, TParams, TData, TError, TResponse>[] = []
    public get mocks() { return this._mocks }

    private _observes: Set<EndpointObserver<TOriginalResponse, TConfig, TParams, TData, TError, TResponse>> = new Set()
    public get observes() { return this._observes }
    
    constructor(
        config: TConfig = {} as TConfig,
        model?: EndpointModel<any, TData>
    ) {
        super(config)

        this._dataUpdater = new DstSnRcDataUpdater(this, model)
    }

    protected abstract log(
        isLoading: boolean,
        isMocker: boolean,
        isError: boolean,
        params: TParams,
        localConfig: TConfig | undefined,
        infoObject: any
    ): void
    
    protected abstract getResponse(originalResponse: TOriginalResponse, params: TParams, localConfig?: TConfig): Promise<TResponse>
    
    protected abstract sendRequest(params: TParams, localConfig?: TConfig): Promise<TOriginalResponse>

    // TODO: private. public only tests
    public getMock(params: TParams) {
        return this.mocks.find(mock => mock.check(params))
    }

    public async request(params: TParams, localConfig?: TConfig) {
        const mock = this.getMock(params)
        let response: TResponse
        let requestAwaited: TOriginalResponse | undefined

        if(mock) {
            this.onSendInvoke(params)

            this.log(true, true, false, params, localConfig, { mock, endpoint: this })

            response = await mock.getResponse(params, localConfig)
        }
        else {
            const request = this.sendRequest(params, localConfig)

            this.log(true, false, false, params, localConfig, { request, endpoint: this })

            this.onSendInvoke(params)

            requestAwaited = await request

            response = await this.getResponse(requestAwaited, params, localConfig)
        }

        const isError = response.error !== undefined

        this.log(false, !!mock, isError, params, localConfig, { originalResponse: requestAwaited, response, endpoint: this })
        
        if(!isError) {
            this.dataUpdater.update(params, response.data as TData)
        }

        this.onReceiveInvoke(response, params)

        return response
    }

    public mock(
        filter: TDstSnRcMockFilter<TParams>, 
        responseGetter: TDstSnRcMockResponseGetter<TConfig, TParams, TData, TError, TResponse>, 
        delay: number = 600
    ) {
        const mock = new DstSnRcMock(filter, responseGetter, delay)
        return [mock, this.mocks.push(mock) - 1] as [typeof mock, number]
    }

    public observe(params: TParams, config?: TConfig) {
        let observer = Array.from(this._observes).find(
            observer => anyFirstLvlIsEqual(observer.params, params)
        ) as EndpointObserver<TOriginalResponse, TConfig, TParams, TData, TError, TResponse>

        if(!!observer) {
            return observer
        }

        // TODO: реализовать механизм подписок и отписок
        observer = new EndpointObserver(params, this, config)
        this._observes.add(observer)

        return observer
    }

    public reloadUpdateFor<TParamsDst, TDataDst>(
        endpoint: TEndpointDstUpdate<TParamsDst, TDataDst>,
        reload: TDstSnRcDataUpdaterReloadUpdate<TParams, TData, TParamsDst, TDataDst>
    ) {
        endpoint._dataUpdater.addReloadUpdateOrigin(this, reload)
    }

    public transformUpdateFor<TParamsDst, TDataDst>(
        endpoint: TEndpointDstUpdate<TParamsDst, TDataDst>, 
        transform: TDstSnRcDataUpdaterTransformUpdate<TParams, TData, TParamsDst, TDataDst>
    ) {
        endpoint._dataUpdater.addTransformUpdateOrigin(this, transform)
    }

    public reloadUpdateForDescibe<TParamsDst, TDataDst>(
        endpoint: TEndpointDstUpdate<TParamsDst, TDataDst>,
    ) {
        endpoint._dataUpdater.removeReloadUpdatOrigin(this)
    }

    public transformUpdateForDescibe<TParamsDst, TDataDst>(
        endpoint: TEndpointDstUpdate<TParamsDst, TDataDst>, 
    ) {
        endpoint._dataUpdater.removeTransformUpdateOrigin(this)
    }
}
