import { autobind } from "core-decorators"
import deepmerge from "deepmerge"
import { Event } from "../../event"
import { EndpointsTree } from "../utils/EndpointsTree"

export type TConfigParamsableProperty<T, TParams> = T | ((params: TParams) => T)

export const compilePropFunction = <T, TParams>(prop: TConfigParamsableProperty<T, TParams>, params: TParams) => 
    typeof prop === 'function' 
        ? (prop as ((params: TParams) => T))(params)    // prop(params)
        : prop

export interface IEndpointBaseResponse<TData, TError> {
    data?: TData
    error?: TError
}

export interface IEndpointBaseConfig {
}

export interface IEndpointConfigReplace<T> {
    ___isEndpointConfigReplace: true,
    value: T
}

export const replace = <T>(value: T): IEndpointConfigReplace<T> => ({
    ___isEndpointConfigReplace: true,
    value
})

export const isEndpointConfigReplace = <T>(value: any): value is IEndpointConfigReplace<T> => 
    value?.___isEndpointConfigReplace === true

export const mergeEndpointConfig = <TConfig>(a: TConfig, b: TConfig): TConfig => 
    isEndpointConfigReplace(b) ? b : deepmerge(a, b)

@autobind
export abstract class EndpointBase<
    TConfig extends IEndpointBaseConfig,
    TParams extends any, 
    TData extends any,
    TError extends any,
> {
    protected abstract get configName(): string

    private _config: TConfig
    public get config() { return this._config }
    
    private _name: string = ''
    public get name() { return this._name }

    private _tree?: EndpointsTree<any, any>
    public get tree() { return this._tree }

    private _onSend: Event<[TParams]>
    public get onSend() {
      return this._onSend
    }

    private _onReceive: Event<[IEndpointBaseResponse<TData, TError>, TParams]>
    public get onReceive() {
      return this._onReceive
    }

    constructor(
        config: TConfig = {} as TConfig
    ) {
        this._config = config
        
        this._onSend = new Event()
        this._onReceive = new Event()
    }

    public ___applyTree(tree: EndpointsTree<any, any>, name: string) {
        this._tree = tree
        this._name = name
    }

    public getConfigProp <TKey extends keyof TConfig>(name: TKey, localConfig?: TConfig): TConfig[TKey] | undefined {
        return localConfig?.[name] !== undefined 
            ? localConfig[name]
            : this.config[name] !== undefined 
                ? this.config[name]
                : this.tree?.getConfigProp(this.configName, name as string)
    }

    protected onSendInvoke(params: TParams) {
        this.onSend.invoke(params)
        this.tree?.onSendInvoke(this, params)
    }

    protected onReceiveInvoke(response: IEndpointBaseResponse<TData, TError>, params: TParams) {
        this.onReceive.invoke(response, params)
        this.tree?.onReceiveInvoke(this, response, params)
    }
}
