import { Typography, List, Popover, ListItemButton, Radio } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react'
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import Icon from '../widgets/Icon';

export const TouristSexFilter = ({
  onClose,
  anchorEl,
  filterState,
  onChange,
  opened,
}) => {
  const { t } = useTranslation()

  const clickMans = useCallback(
    () => onChange(filterState !== 'm' ? 'm' : null),
    [filterState, onChange]
  )

  const clickWomans = useCallback(
    () => onChange(filterState !== 'w' ? 'w' : null),
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItemButton
          onClick={clickMans}
        >
          <Radio
            color='primary'
            checked={filterState === 'm'}
            onChange={clickMans}
          />
          <Linely>
            <Icon
              Component={FaceIcon}
            />
            <Typography>
              {t('Common.sex.mans')}
            </Typography>
          </Linely>
        </ListItemButton>
        <ListItemButton
          onClick={clickWomans}
        >
          <Radio
            color='primary'
            checked={filterState === 'w'}
            onChange={clickWomans}
          />
          <Linely>
            <Icon
              Component={Face2Icon}
            />
            <Typography>
              {t('Common.sex.womens')}
            </Typography>
          </Linely>
        </ListItemButton>
      </List>
    </Popover>
  )
}
