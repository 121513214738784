import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { Typography } from '@mui/material'
import Linely from '../../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { SortableTable } from '../../../ui/tables';
import { useMemo, useState, useCallback } from 'react'
import Box from '../../../tools/Components/atoms/Box';
import FilledSearch from '../../../ui/widgets/FilledSearch';
import api from '../../../endpoints';
import usePathRouter from '../../../tools/hooks/paths/usePathRouter';
import AddIcon from '@mui/icons-material/Add'
import LeftRight from '../../../tools/Components/atoms/LeftRight';
import CircleButton from '../../../ui/widgets/CircleButton';
import PathLink from '../../../tools/Components/paths/PathLink';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { TouristPhoneRenderer, touristPhoneSearcher } from '../../../ui/tableRenderers/TouristPhoneRenderer';
import { TouristSexRenderer } from '../../../ui/tableRenderers/TouristSexRenderer';
import { TouristSexFilter } from '../../../ui/TableFilters/TouristSexFilter';
import { TouristBirthdayRenderer } from '../../../ui/tableRenderers/TouristBirthdayRenderer';
import { TouristBirthdayFilter } from '../../../ui/TableFilters/TouristBirthdayFilter';
import { TouristFioRenderer, touristFioSearcher } from '../../../ui/tableRenderers/TouristFioRenderer';
import { TouristStatusFilter } from '../../../ui/TableFilters/TouristStatusFilter';
import { local, remove } from '../../../tools/libs/paths/helpers';
import Icon from '../../../ui/widgets/Icon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Options from '../../../ui/widgets/Options';
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import PersonIcon from '@mui/icons-material/Person';
import ActionRenderer, { optionsRendererColumn } from '../../../ui/tableRenderers/ActionRenderer';
import { useModal } from '../../../hooks/useModal';
import { usePercentWidth } from '../../../hooks/usePercentWidth';
import Loadable from '../../../ui/widgets/Loadable';
import SortableTableCleareAll from '../../../ui/tables/SortableTable/SortableTableCleareAll';
import { TourCommentRenderer } from '../../../ui/tableRenderers/TourCommentRenderer';
import {
  sortedIsAsc, 
  touristIsBirthdayInTour, 
  userToFullFio 
} from '../../../utils/helpers';
import { getPersonYears, tsToMomentMoscow } from '../../../utils/moment';
import moment from 'moment';
import { merge } from 'lodash';
import DeleteWait from './Modals/DeleteWait';
import WaitToReserveModal from './Modals/WaitToReserveModal';
import ChairIcon from '@mui/icons-material/Chair';

const WaitsTablePage = () => {
  const { t } = useTranslation()
  
  const [optionsElement, setOptionsElement] = useState()

  const [router] = usePathRouter()

  const [{ 
    tourId 
  }] = usePathParams(router.root.c.tours.c.one)
  const [{ touristId }] = usePathParams(router.root.c.tours.c.one.c.waits.c.one)

  const [{ wSearch: { value: wSearch } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wSearch.c.value), [{ wSearch: {} }])
  const [{ wSorted: { value: wSorted } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wSorted.c.value), [{ wSorted: {} }])
  const [{ wSex: { value: filtewSex } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wSex.c.value), [{ wSex: {} }])
  const [{ wBirthdayBy: { value: wBirthdayBy } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wBirthdayBy.c.value), [{ wBirthdayBy: {} }])
  const [{ wAgeStart: { value: wAgeStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wAgeStart.c.value), [{ wAgeStart: {} }])
  const [{ wAgeEnd: { value: wAgeEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wAgeEnd.c.value), [{ wAgeEnd: {} }])
  const [{ wBirthdayStart: { value: wBirthdayStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wBirthdayStart.c.value), [{ wBirthdayStart: {} }])
  const [{ wBirthdayEnd: { value: wBirthdayEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wBirthdayEnd.c.value), [{ wBirthdayEnd: {} }])
  const [{ wBirthdayMonthStart: { value: wBirthdayMonthStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wBirthdayMonthStart.c.value), [{ wBirthdayMonthStart: {} }])
  const [{ wBirthdayMonthEnd: { value: wBirthdayMonthEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wBirthdayMonthEnd.c.value), [{ wBirthdayMonthEnd: {} }])
  const [{ wTouristStatus: { value: wTouristStatus } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wTouristStatus.c.value), [{ wTouristStatus: {} }])
  const [{ wPage: { value: wPage } }] = merge(usePathParams(router.root.c.tours.c.one.c.waits.c.wPage.c.value), [{ wPage: {} }])

  const searchValue = (wSearch ?? '').toString()

  const pageWidth = usePercentWidth()
  
  const [deletedWaitModal, deletedWaitOpen] = useModal(DeleteWait)
  const [waitToReserveModalModal, waitToReserveModalOpen] = useModal(WaitToReserveModal)

  const [{ data: tour, isLoading }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: tourTourists }] = useEnObserve(api.tree.reserves.list, tourId)

  const tourWaits = useMemo(
    () => (tourTourists || [])
      .filter(({ status_payment }) => ['wait_list'].indexOf(status_payment) > -1)
      .sort((a, b) => a.booking_date < b.booking_date ? 1 : -1)
      .map((item, index) => ({ ...item, tourist: { ...item.tourist, index: index + 1 } })),
    [tourTourists]
  )

  const sorter = useMemo(
    () => {
      const [name, ascDesc] = wSorted?.split(',') || []

      return {
        name,
        asc: ascDesc === 'asc'
      }
    },
    [wSorted]
  )

  const filterBirthday = useMemo(
    () => ({
      by: wBirthdayBy,
      ageStart: wAgeStart,
      ageEnd: wAgeEnd,
      birthdayStart: wBirthdayStart ? moment.unix(wBirthdayStart) : null,
      birthdayEnd: wBirthdayEnd ? moment.unix(wBirthdayEnd) : null,
      birthdayMonthStart: wBirthdayMonthStart ? moment.unix(wBirthdayMonthStart) : null,
      birthdayMonthEnd: wBirthdayMonthEnd ? moment.unix(wBirthdayMonthEnd) : null,
    }),
    [
      wBirthdayBy,
      wAgeStart,
      wAgeEnd,
      wBirthdayStart,
      wBirthdayEnd,
      wBirthdayMonthStart,
      wBirthdayMonthEnd,
    ]
  )

  const filtewTouristStatus = useMemo(
    () => (wTouristStatus && typeof wTouristStatus !== 'boolean') ? wTouristStatus.toString().split(',') : [],
    [wTouristStatus]
  )
  
  const filteredWaits = useMemo(
    () => (tourWaits || []).filter(
      (wait) => 
        (
          (!filtewTouristStatus || filtewTouristStatus.length === 0) ||
          (filtewTouristStatus.indexOf('normal') > -1 && wait.tourist.status === 'normal') || 
          (filtewTouristStatus.indexOf('dangerZone') > -1 && wait.tourist.status === 'danger_zone') || 
          (filtewTouristStatus.indexOf('blocked') > -1 && wait.tourist.status === 'blocked')
        ) &&
        (
          // Фильтр должен быть выбран
          !filterBirthday.by || 
          (
            filterBirthday.by === 'age' && 
            (!filterBirthday.ageStart || (wait.tourist.birthday && filterBirthday.ageStart <= getPersonYears(tsToMomentMoscow(wait.tourist.birthday)))) &&
            (!filterBirthday.ageEnd || (wait.tourist.birthday && filterBirthday.ageEnd >= getPersonYears(tsToMomentMoscow(wait.tourist.birthday))))
          ) ||
          (
            filterBirthday.by === 'birthday' && 
            (!filterBirthday.birthdayStart || (wait.tourist.birthday && filterBirthday.birthdayStart.unix() <= wait.tourist.birthday)) &&
            (!filterBirthday.birthdayEnd || (wait.tourist.birthday && filterBirthday.birthdayEnd.unix() >= wait.tourist.birthday))
          ) ||
          (
            filterBirthday.by === 'birthdayMonth' && 
            (!filterBirthday.birthdayMonthStart || (wait.tourist.birthday && filterBirthday.birthdayMonthStart.month() <= tsToMomentMoscow(wait.tourist.birthday).month())) &&
            (!filterBirthday.birthdayMonthEnd || (wait.tourist.birthday && filterBirthday.birthdayMonthEnd.month() >= tsToMomentMoscow(wait.tourist.birthday).month()))
          )
        ) &&
        (
          filtewSex == null ||
          (filtewSex === 'm' && wait.tourist.sex === 'М') ||
          (filtewSex !== 'm' && wait.tourist.sex !== 'М')
        )
    ),
    [
      tourWaits, 
      filtewSex, 
      filtewTouristStatus, 
      filterBirthday
    ]
  )
  
  const sortedWaits = useMemo(
    () => [
      ...filteredWaits.sort((a, b) => {
        if(sorter.name === 'fio') {
          return sortedIsAsc(sorter.asc, userToFullFio(a.tourist) > userToFullFio(b.tourist))
        }
        else if(sorter.name === 'birthday') {
          const nowTs = moment().unix()
  
          return sortedIsAsc(sorter.asc, (a.tourist.birthday || nowTs) < (b.tourist.birthday || nowTs))
        }

        return tsToMomentMoscow(a.booking_date).isBefore(tsToMomentMoscow(b.booking_date)) ? 1 : -1
      })
    ],
    [filteredWaits, sorter]
  )

  const mansTourists = useMemo(
    () => (tourWaits || [])
      .filter(({ status_payment, tourist: { sex } }) => status_payment !== 'cancellation' && sex === 'М')
      .length,
    [tourWaits]
  )

  const womansTourists = useMemo(
    () => (tourWaits || [])
    .filter(({ status_payment, tourist: { sex } }) => status_payment !== 'cancellation' && sex !== 'М')
      .length,
    [tourWaits]
  )

  const birthdayTourists = useMemo(
    () => tour && tourWaits
      ? tourWaits
        .filter(({ tourist }) => touristIsBirthdayInTour(tour, tourist))
        .length
      : 0,
    [tour, tourWaits]
  )

  const onSetSearchValue = useCallback(
    (search) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wSearch.c.value, search ? local({ value: search }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetSorter = useCallback(
    ({ name, asc }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wSorted.c.value, local({ value: `${name},${asc ? 'asc' : 'desc'}` })],
      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFiltewSex = useCallback(
    (sex) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wSex.c.value, sex ? local({ value: sex }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterBirthday = useCallback(
    ({
      by,
      ageStart,
      ageEnd,
      birthdayStart,
      birthdayEnd,
      birthdayMonthStart,
      birthdayMonthEnd,
    }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wBirthdayBy.c.value, by ? local({ value: by }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wAgeStart.c.value, ageStart ? local({ value: ageStart }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wAgeEnd.c.value, ageEnd ? local({ value: ageEnd }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayStart.c.value, birthdayStart?.isValid() ? local({ value: birthdayStart?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayEnd.c.value, birthdayEnd?.isValid() ? local({ value: birthdayEnd?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayMonthStart.c.value, birthdayMonthStart?.isValid() ? local({ value: birthdayMonthStart?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayMonthEnd.c.value, birthdayMonthEnd?.isValid() ? local({ value: birthdayMonthEnd?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFiltewTouristStatus = useCallback(
    (touristStatus) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wTouristStatus.c.value, touristStatus?.length > 0 ? local({ value: touristStatus.join(',') }) : remove()],
      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )

  const waitOptions = useCallback(
    ({ id_tour, tourist: { id } }) => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Wait.editModal.title'),
        href: router.getUrl([
          [router.root.c.editWait.c.tour.c.tourist, local({ touristId: id, tourId: id_tour })]
        ])
      },
      {
        icon: (<Icon Component={PersonIcon} />),
        text: t('Tourist.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: id })]
        ])
      },
      {
        icon: (<Icon Component={ChairIcon} />),
        text: t('Wait.moveToReserve'),
        onClick: () => waitToReserveModalOpen({ tourId: id_tour, touristId: id })
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Wait.delete'),
        color: 'error',
        onClick: () => deletedWaitOpen({ tourId: id_tour, touristId: id })
      },
    ],
    [t, router, deletedWaitOpen, waitToReserveModalOpen]
  )
  
  const onClearAllFilters = useCallback(
    () => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.waits.c.wSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.waits.c.wSex.c.value, remove()],

      [router.root.c.tours.c.one.c.waits.c.wSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.waits.c.wBirthdayBy.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayBy.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wAgeEnd.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayStart.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayEnd.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayMonthStart.c.value, remove()],
      [router.root.c.tours.c.one.c.waits.c.wBirthdayMonthEnd.c.value, remove()],

      [router.root.c.tours.c.one.c.waits.c.wTouristStatus.c.value, remove()],

      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, remove()],
    ])),
    [router]
  )
  
  const columns = useMemo(
    () => [
      {
        id: 'tourist',
        sorter: 'fio',
        label: t('Common.fio'),
        filter: 'status',
        index: true,
        filterComponent: TouristStatusFilter,
        filterIsActive: filtewTouristStatus?.length > 0,
        filterComponentProps: {
          filterState: filtewTouristStatus,
          onChange: onSetFiltewTouristStatus,
        },
        search: touristFioSearcher,
        renderer: TouristFioRenderer,
        maxWidth: pageWidth * 0.166,
      },
      {
        id: 'tourist',
        sorter: 'birthday',
        filter: 'birthday',
        filterComponent: TouristBirthdayFilter,
        filterIsActive: !!filterBirthday.by,
        filterComponentProps: {
          filterState: filterBirthday,
          onChange: onSetFilterBirthday,
        },
        label: t('Common.birthday'),
        tourDates: tour,
        renderer: TouristBirthdayRenderer
      },
      {
        id: 'tourist',
        filter: 'sex',
        filterComponent: TouristSexFilter,
        filterIsActive: filtewSex != null,
        filterComponentProps: {
          filterState: filtewSex,
          onChange: onSetFiltewSex,
        },
        label: t('Common.sex'),
        renderer: TouristSexRenderer
      },
      {
        id: 'tourist',
        label: t('Common.phone'),
        search: touristPhoneSearcher,
        renderer: TouristPhoneRenderer
      },
      {
        id: '',
        label: t('Tour.tourists.wait.comment'),
        tourPrice: tour?.price,
        renderer: TourCommentRenderer
      },
      {
        id: '',
        noClick: true,
        columnView: () => (
          <SortableTableCleareAll
            canClear={
              filtewTouristStatus?.length > 0 ||
              !!filterBirthday.by ||
              filtewSex != null ||
              !!wSorted
            }
            onClear={onClearAllFilters}
          />
        ),
        action: { 
          ...optionsRendererColumn, 
          onClick: (tourist, e) => {
            setOptionsElement(
              <Options
                opened
                onClose={() => setOptionsElement(null)}
                options={waitOptions(tourist)}
                anchorEl={e.target}
              />
            )
          }
        },
        renderer: ActionRenderer
      },
    ],
    [
      t, 
      onSetFiltewSex,
      onSetFilterBirthday,
      onSetFiltewTouristStatus,
      onClearAllFilters,
      pageWidth, 
      tour, 
      waitOptions,
      filtewSex,
      filtewTouristStatus,
      filterBirthday,
      wSorted
    ]
  )

  const href = useCallback(
    ({ tourist: { id } }) => router.root.c.tours.c.one.c.waits.c.one.getUrl(
      {
        touristId: id
      }
    ),
    [
      router,
    ]
  )

  const checkIsHighlighted = useCallback(
    ({ tourist: { id } }) => id === touristId,
    [touristId]
  )

  return (
    <>
      {deletedWaitModal}
      {waitToReserveModalModal}
      {optionsElement}
      <Loadable
        loading={!tourTourists && isLoading}
        empty={!tour || !tourTourists}
      >
        {() => (
          <Linely
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely
                gap={0}
                vertical
              >
                <Typography variant='h6'>
                  {t('Tour.tourists.waits.title')}
                </Typography>
                <Linely gap='i2'>
                  <Typography color='textSecondary'>
                    <Trans
                      i18nKey='Tour.tourists.waits'
                      values={{ count: tourWaits.length }}
                      components={[
                        <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                      ]}
                    />
                  </Typography>
                  <Linely gap='i4'>
                    <Icon
                      Component={FaceIcon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {mansTourists}
                    </Typography>
                  </Linely>
                  <Linely gap='i4'>
                    <Icon
                      Component={Face2Icon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {womansTourists}
                    </Typography>
                  </Linely>
                  {!!birthdayTourists && (
                    <Typography color='textSecondary'>
                      <Trans
                        i18nKey='Tour.tourists.birthdys'
                        values={{ count: birthdayTourists }}
                        components={[
                          <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                        ]}
                      />
                    </Typography>
                  )}
                </Linely>
              </Linely>
              <PathLink
                href={router.getUrl([
                  [router.root.c.createWait.c.tour, local({ tourId: tour.id })]
                ])}
              >
                <CircleButton
                  big
                  variant='contained'
                >
                  <Icon
                    big
                    Component={AddIcon}
                  />
                </CircleButton>
              </PathLink>
            </LeftRight>
            <FilledSearch
              value={searchValue}
              onChange={onSetSearchValue}
            />
            <Box mx='-i1'>
              <SortableTable
                highlighted={checkIsHighlighted}
                searchValue={searchValue}
                columns={columns}
                data={sortedWaits}
                href={href}
                sorter={sorter}
                onSetSorter={onSetSorter}
                paginationProps={{
                  rowsPerPage: 20,
                  page: wPage ? wPage -1 : 0,
                  onPageChange: (newPage) => 
                    router.pushUrl(router.getUrl([
                      [router.root.c.tours.c.one.c.waits.c.wPage.c.value, local({ value: newPage + 1 })],
                    ]))
                }}
              />
            </Box>
          </Linely>
        )}
      </Loadable>
    </>
  )
}

export default WaitsTablePage
