import { useCallback, useMemo } from 'react'
import useOnEvent from './event/useOnEvent'
import { Event, browserEvent } from '../libs/event'
import useRefState from './useRefState'

export type TLsLikeValue = Array<any> | Object | boolean | number | string | null

export const lsChangeEvent = new Event<[string, string | undefined]>()

var originalSetItem = localStorage.setItem; 
localStorage.setItem = function(key: string, value: string) {
  //@ts-ignore
  originalSetItem.apply(this, arguments)
  lsChangeEvent.invoke(key, value)
}
var originalRemoveItem = localStorage.removeItem; 
localStorage.removeItem = function(key: string) {
  //@ts-ignore
  originalRemoveItem.apply(this, arguments)
  lsChangeEvent.invoke(key, undefined)
}

const valToString = (val: TLsLikeValue): string => typeof val === 'string' ? val : JSON.stringify(val)

const stringToVal = (str: string): TLsLikeValue => {
  try {
    return JSON.parse(str)
  }
  catch {
    return str
  }
}

function useLocalStorrage <T extends TLsLikeValue>(name: string, defaultValue?: T) {
  // localStorage.getItem(name) возвращает null если нет такой записи в localStorage

  const [val, refVal, setVal] = useRefState(
    useMemo(
      () => localStorage.getItem(name) != null ? stringToVal(localStorage.getItem(name)!) : defaultValue,
      [name, defaultValue]
    )
  )

  const changeVal = useCallback(
    (newVal: T) => {
      setVal(newVal !== undefined ? newVal : defaultValue)

      if (newVal === undefined) {
        localStorage.removeItem(name)
      }
      else {
        localStorage.setItem(name, valToString(newVal))
      }
    },
    [setVal, name, defaultValue]
  )

  useOnEvent(
    lsChangeEvent,
    useCallback(
      (updatedVariableName: string, value: string | undefined): void => {
        if(
          updatedVariableName !== name ||
          refVal.current === value
        ) {
          return
        }

        setVal(value ? stringToVal(value) : defaultValue)
      },
      [name, refVal, setVal, defaultValue]
    )
  )

  // подписка на обновления переменной на других страницах
  useOnEvent(
    browserEvent(window, 'storage'),
    useCallback(
      (e: any): void => {
        if(e.key !== name) {
          return
        }

        setVal(
          e.newValue ? stringToVal(e.newValue) : defaultValue
        )
      },
      [name, defaultValue, setVal]
    )
  )

  return [val, changeVal]
}

export default useLocalStorrage
