import { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import useOnEvent from '../../hooks/event/useOnEvent'
import useRefSync from '../../hooks/useRefSync'
import { BrowserEvent } from '../../libs/event'
import { mbFunctionalCloneElement } from '../../utils/react'

function OnEvent (
  {
    children,
    event,
    eventArgs = [],
    onEvent
  },
  outRef
) {
  const ref = useRef()
  const syncedRef = useRefSync(ref, outRef)

  const [trueEvent, setTrueEvent] = useState()

  useLayoutEffect(
    () => {
      setTrueEvent(
        typeof event === 'string'
          ? (new BrowserEvent(ref.current, event))
          : (new event(ref.current, ...eventArgs))
      )
    },
    // eslint-disable-next-line
    [event, ref, ...eventArgs]
  )

  useOnEvent(
    trueEvent,
    onEvent
  )

  return mbFunctionalCloneElement(children, { ref: syncedRef })
}

export default forwardRef(OnEvent)
