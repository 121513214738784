import * as Yup from 'yup'

export const touristFormSchema = Yup.object().shape({
  surname: Yup.string()
    .required('Validation.required'),
  first_name: Yup.string()
    .required('Validation.required'),
  birthday: Yup.mixed()
    .required('Validation.required'),
  status: Yup.string()
    .required('Validation.required'),
  sex: Yup.string()
    .required('Validation.required'),
  phone: Yup.string()
    .required('Validation.required'),
})