import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { TouristsEndpoint } from "./TouristsEndpoint";

export const TouristDeleteEndpoint = new DstSnRcHttp<
  number
>({
  method: HTTP_METHODS.delete,
  url: id => `/tourists/${id}`,
})

TouristDeleteEndpoint.transformUpdateFor(
  TouristsEndpoint,
  (oldData, _, id) => oldData.filter(t => t.id !== id)
)
