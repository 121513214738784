import useBoolean from '../hooks/useBoolean'
import { mbFunctionalCloneElement } from '../utils/react'

const ModalWrapper = ({
  Component,
  children,
  ...props
}) => {
  const [opened, , , handleOpen, handleClose] = useBoolean(false)
  return (
    <>
      {mbFunctionalCloneElement(children, { onClick: handleOpen })}
      {opened && (
        <Component
          {...props}
          opened={opened}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default ModalWrapper
