import { isObject } from "formik"
import { arrFirstLvlIsEqual } from "./array"
import { objFirstLvlIsEqual } from "./object"

export const sleep = (milliseconds: number) => 
    new Promise(
        resolve => setTimeout(
            resolve,
            milliseconds
        )
    )

export const anyFirstLvlIsEqual = (variable1: any, variable2: any) => 
    (Array.isArray(variable1) && Array.isArray(variable2))
        ? arrFirstLvlIsEqual(variable1, variable2) 
        : (isObject(variable1) && isObject(variable2))
            ? objFirstLvlIsEqual(variable1, variable2)
            : variable1 === variable2
    

export function localEventFn (func: any) {
    return (e: any) => {
        e && e.stopPropagation && e.stopPropagation()
        e && e.preventDefault && e.preventDefault()
        return func && func(e)
    }
}
