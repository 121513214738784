import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export const TourExcelEndpoint = new DstSnRcHttp<
  number
>({
  method: HTTP_METHODS.get,
  url: (id) => `/tours/${id}/tourists/excel`,
  responseTransform: async response => ({ 
    data: response.status < 400 ? await response.blob() : undefined, 
    error: response.status >= 400 ? await response.json() : undefined,
    status: response.status
  })
})
