import { useLayoutEffect } from 'react'
import { CrossPageEvent, Event, TEventCb } from '../../libs/event'

function useOnEvent<Args extends Array<any>>(
  event?: Event<Args>,
  cb?: TEventCb<Args>
): void

function useOnEvent<Args extends Array<any>>(
  event?: CrossPageEvent<Args>,
  cb?: TEventCb<[Args, boolean]>
): void

function useOnEvent<Args extends Array<any>>(
  event?: Event<Args>,
  cb?: TEventCb<Args>
) {
  useLayoutEffect(
    () => {
      event && cb && event.subscribe(cb)
      return () => { event && cb && event.unsubscribe(cb) }
    }, 
    [event, cb]
  )
}

export default useOnEvent
