import { autobind } from 'core-decorators'
import { Event as BaseEvent } from './Event'

@autobind
//@ts-ignore
export class BrowserResizeEvent extends BaseEvent<[ResizeObserverEntry[], ResizeObserver]> {
  public constructor(element: HTMLElement) {
    super()
    if (element) { 
      // @ts-ignore
    new ResizeObserver(this.invoke).observe(element)
    }
  }
}

export const browserResizeEvent = (element: HTMLElement) => new BrowserResizeEvent(element)
