import { Typography } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import TourStatus from '../widgets/TourStatus';
import { findStrInArray } from '../../tools/utils/array';

export const tourNameSearcher = (searchStr, _, { title }) => 
  findStrInArray([title], searchStr)

export const TourNameRenderer = ({ value: tour }) => (
  <Linely>
    <TourStatus
      tour={tour}
    />
    <Typography variant='body1'>
      {tour.title}
    </Typography>
  </Linely>
)
