import { useMemo, useCallback } from 'react'
import ClearTable from '../ClearTable'

function TableView ({
  selectedIds,
  ...tableProps
}) {
  const hasSelectedIds = useMemo(() => selectedIds && selectedIds.length > 0, [
    selectedIds
  ])

  const numSelected = useMemo(() => (hasSelectedIds ? selectedIds.length : 0), [
    hasSelectedIds,
    selectedIds
  ])

  const isSelected = useCallback(
    id => hasSelectedIds && selectedIds.indexOf(id) !== -1,
    [hasSelectedIds, selectedIds]
  )

  return (
    <ClearTable
      {...tableProps}
      hasSelectedIds={hasSelectedIds}
      numSelected={numSelected}
      isSelected={isSelected}
    />
  )
}

export default TableView
