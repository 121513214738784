import { Typography } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { findStrInArray } from '../../tools/utils/array';
import {
  userToFullFio
} from '../../utils/helpers';
import TouristStatus from '../widgets/TouristStatus';

export const touristFioSearcher = (searchStr, tourist) => 
  findStrInArray([userToFullFio(tourist)], searchStr)

export const TouristFioRenderer = ({ value: tourist, column: { index } }) => (
  <Linely>
    {index && (
      <Typography noWrap>
        {tourist.index}
      </Typography>
    )}
    <TouristStatus status={tourist.status} />
    <Linely vertical gap={0} minWidth={0}>
      <Typography noWrap>
        {tourist.surname}
      </Typography>
      <Typography noWrap>
        {tourist.first_name}
      </Typography>
      <Typography noWrap>
        {tourist.second_name}
      </Typography>
    </Linely>
  </Linely>
)
