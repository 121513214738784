import { forwardRef } from "react";
import { PathRouterComponentPathTypeError } from "../../hooks/paths/errors";
import usePathMatch from "../../hooks/paths/usePathMatch";
import { PathNode } from "../../libs/paths";
import { assert } from "../../utils/assert";
import PathCase, { IPathCase } from "./PathCase";

export interface IPathMatch extends Omit<IPathCase, 'opened'> { 
  exact?: boolean
}

function PathMatch<T>(
  { 
    path, 
    exact,
    ...props
  }: IPathMatch & T,
  ref: any
) {
  assert(path instanceof PathNode, new PathRouterComponentPathTypeError('PathMatch', typeof path))

  return (
    <PathCase
      {...props}
      path={path}
      ref={ref}
      opened={usePathMatch(path, exact)}
    />
  )
}

export default forwardRef(PathMatch)
