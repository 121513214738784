import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export interface IUserSmall {
  id: number
  surname: string       // фамилия
  first_name: string    // имя
  second_name: string   // отчество
}

export const UsersEndpoint = new DstSnRcHttp<
  string | undefined,
  IUserSmall[]
>({
  method: HTTP_METHODS.get,
  url: typeUser => `/users?type_user=${typeUser}`
})
