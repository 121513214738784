import api from '../../../../endpoints';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { userToFullFio } from '../../../../utils/helpers';
import router from '../../../../paths';
import { remove } from '../../../../tools/libs/paths/helpers';

const DeleteReserve = ({ tourId, touristId, onClose, ...props }) => {
  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)
  const [{ data: tourist }] = useEnObserve(api.tree.tourists.one, touristId, null, !!touristId)

  const handleSubmit = useCallback(
    async () => {
      const { error } = await api.tree.reserves.delete.request([tourId, touristId])

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one.c.reserves.c.one, remove()],
      ]))

      onClose()
    },
    [tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Reserve.deleteModal.title'
      message={(
        <Trans
          i18nKey='Reserve.deleteModal.message'
          values={{ 
            tourist: tourist ? userToFullFio(tourist) : '', 
            tour: tour?.title || ''
          }}
          components={[
            <Typography key={0} display='inline' variant='subtitle1' />,
          ]}
        />
      )}
      lSubmitLable='Common.delete'
      onSubmit={handleSubmit}
    />
  )
}

export default DeleteReserve
