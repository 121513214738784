import { getIn } from '../../tools/utils/object'

export function searchByValue (searchValue, data, columns) {
  const searchableColumns = columns.filter(({ search }) => !!search)

  return searchValue 
    ? data.filter(
      item => searchableColumns.some(column => {
        let columnValue = getIn(item, column.id)
          
        columnValue = column.preprocess
          ? column.preprocess(columnValue, item)
          : columnValue

        return column.search(searchValue, columnValue, item)
      })
    )
    : data
}
