import { useTheme } from '@mui/material/styles';
import Box from '../../tools/Components/atoms/Box';
import logoBackground from '../../static/images/logo-background.svg'
import Stickly from '../../tools/Components/Stickly';
import FixedYBackground from './FixedYBackground';

const RightPanel = ({ children }) => {
  const theme = useTheme()

  return (
    <Box
      bgcolor={theme.palette.background.paper}
      w='30%'
      height='calc(100vh - 64px)'
      outline={`${theme.palette.divider} 1px solid`}
      position='relative'
    >
      <FixedYBackground
        position='fixed'
        right={0}
        bottom={0}
        style={{
          width: 'inherit'
        }}
        img={logoBackground}
        opacity={0.03}
        height={700}
        scale={2.374}
        rotate='5.9deg'
        translateX='-38px'
      />
      <Stickly
        position='relative'
        zIndex={2}
        minTop={64}
        fullWidth
        fullHeight
        StickProps={{
          p: 'i1',
        }}
        style={{
          overflowY: 'auto'
        }}
      >
        {children}
      </Stickly>
    </Box>
  )
}

export default RightPanel
