import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import api from '../../../endpoints';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import usePathRouter from '../../../tools/hooks/paths/usePathRouter';
import Icon from '../../../ui/widgets/Icon';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { local } from '../../../tools/libs/paths/helpers';
import useBoolean from '../../../tools/hooks/useBoolean';
import TourTouristView from '../TourTouristView';
import DeleteWait from './Modals/DeleteWait';
import ChairIcon from '@mui/icons-material/Chair';
import WaitToReserveModal from './Modals/WaitToReserveModal';

const WaitPage = () => {
  const { t } = useTranslation()

  const [router] = usePathRouter()
  const [{ tourId, touristId }] = usePathParams()

  const [waitToReserveModalOpened, , , waitToReserveModalOpen, waitToReserveModalClose] = useBoolean()
  const [deleteWaitOpened, , , deleteWaitOpen, deleteWaitClose] = useBoolean()
  
  const [{ data: tour, isLoading: isLoadingTour }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: wait, isLoading: isLoadingWait }] = useEnObserve(api.tree.reserves.one, [tourId, touristId])
  
  const waitOptions = useMemo(
    () => tour && wait && [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Wait.editModal.title'),
        href: router.getUrl([
          [router.root.c.editWait.c.tour.c.tourist, local({ touristId: wait.tourist.id, tourId: tour.id })]
        ])
      },
      {
        icon: (<Icon Component={PersonIcon} />),
        text: t('Tourist.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: wait.tourist.id })]
        ])
      },
      {
        icon: (<Icon Component={ChairIcon} />),
        text: t('Wait.moveToReserve'),
        onClick: waitToReserveModalOpen
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Wait.delete'),
        color: 'error',
        onClick: deleteWaitOpen
      }
    ],
    [t, wait, tour, router, waitToReserveModalOpen, deleteWaitOpen]
  )

  return (
    <>
      <WaitToReserveModal
        tourId={tourId}
        touristId={touristId}
        opened={waitToReserveModalOpened}
        onClose={waitToReserveModalClose}
      />
      <DeleteWait
        tourId={tourId}
        touristId={touristId}
        opened={deleteWaitOpened}
        onClose={deleteWaitClose}
      />
      <TourTouristView
        type='wait'
        tour={tour}
        tourTourist={wait}
        loading={(!tour && isLoadingTour) || (!wait && isLoadingWait)}
        empty={!tour || !wait}
        options={waitOptions}
        goBackHref={router.root.c.tours.c.one.getUrl()}
      />
    </>
  )
}

export default WaitPage
