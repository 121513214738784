import { assert } from "../../utils/assert"
import { IHashMap } from "../../utils/types"
import { 
    PathRouterOptionalAndParametrNodeError,
    PathRouterParamIsNotDefinedError,
    PathRouterNamesConflictError,
    PathRouterSystemError,
    PathRouterPathMultipleNodeNotSameInsideError,
    PathRouterPathMultipleNodeNotInsideError,
    PathRouterRemoveNodeRootError,
} from "./errors"
import deepmerge from 'deepmerge';
import { 
    PathNode,
    PathNodeRoot,
    EURL_STYLE, 
    IPathParamsLocal, 
    IPathParamsRemove, 
    IUrlParts, 
    TNodeParam, 
    TPathParams, 
    TPathParamValue, 
    TQueryParams,
    TParametrType,
    PathMultipleNode,
    IPathParamsClosed
} from "./"
import { getNoUniqOnlyArray, arSplice } from "../../utils/array"





export const checkMatchParam = (strValue: string, paramType: TParametrType) => {
    if(paramType === 'string') {
        return true
    }
    else if(paramType === 'number') {
        return !isNaN(Number(strValue))
    }
    else {
        // TODO: strValue по идее всегда должен быть строкой
        // либо если может быть чем то ещё, пофиксить тип strValue
        return !!strValue && !!strValue.match(paramType)
    }
}

// пущай по дефолту, даже если не задано что число, если может преобразовать - пусть преобразовывает
export const parseParam = (strValue: string, type: TParametrType): TPathParamValue =>
    type instanceof RegExp
        ? [
            ...(strValue.match(type) || [])
                .map(parsePathParamValue)
        ]
        : parsePathParamValue(strValue)

export const parsePathParamValue = (strValue: string): string | number =>
    (strValue !== '' && !isNaN(Number(strValue))) ? Number(strValue) : strValue

export const parseQueryParamValue = (strValue: string): string | number | boolean =>
    strValue === '' ? true : parsePathParamValue(strValue)

export const compileUrlFromUrlQueryParamsAndHash = (mainUrl: string, queryParams: TQueryParams = {}, hash: string = '') => {
    const realQueryParams = Object.fromEntries(
        Object.entries(queryParams)
            .filter(([, val]) => val != null && val !== false && val !== '')
    )

    return mainUrl + 
        (Object.values(realQueryParams).length > 0 ? `?${
            // не экранируется "/"
            Object.entries(realQueryParams).map(([key, value]) => value === true ? key : `${key}=${value}`).join('&')
        }` : '') + 
        (hash !== '' ? ('#' + hash) : '' )
}

export const compileUrlQueryParamsAndHashFromUrl = (url: string): [string, TQueryParams, string] => {
    // eslint-disable-next-line no-useless-escape
    const [, mainUrl, strQueryParams, dirtyHash] = [...(url.match(/^(.*?)(\?.*?)?(\#.*)?$/) || [])].map(val => val || '')

    const queryParams = Object.fromEntries([...(new URLSearchParams(strQueryParams.slice(1)))].map(
        ([key, val]: [string, string]) => [
            key, 
            parseQueryParamValue(val)
        ]
    ))

    return [
        mainUrl, 
        queryParams,
        dirtyHash.slice(1)
    ]
}



export const compileMainStyleUrlFromUrlPartsBuildMainUrl = ({ mainPath, optionalPaths }: IUrlParts): string => 
    '/' + mainPath.join('/') + 
    (optionalPaths.map(op => '(' + compileMainStyleUrlFromUrlPartsBuildMainUrl(op) + ')')).join('')

export const compileMainStyleUrlFromUrlParts = (urlParts: IUrlParts, queryParams?: TQueryParams, hash?: string) => 
    compileUrlFromUrlQueryParamsAndHash(compileMainStyleUrlFromUrlPartsBuildMainUrl(urlParts), queryParams, hash)



export const compileQueryParamsStyleUrlFromUrlPartsBuildMainUrlAndQueryParams = (
    { mainPath, optionalPaths }: IUrlParts
): [string, TQueryParams] => {
    const mainUrl = '/' + mainPath.join('/')

    let optionParamsUrl = {}

    for (const optionalPath of optionalPaths) {
        const [mainUrl, mainQueryParams] = compileQueryParamsStyleUrlFromUrlPartsBuildMainUrlAndQueryParams(optionalPath)

        const optionalStarter = optionalPath.mainPath[0]
        optionParamsUrl = {
            ...optionParamsUrl,
            [`/${optionalStarter}`]: mainUrl,
            ...Object.fromEntries(Object.entries(mainQueryParams).map(
                ([key, value]) => [`/${optionalStarter}${key}`, value]
            ))
        }
    }

    return [mainUrl, optionParamsUrl]
}

export const compileQueryParamsStyleUrlFromUrlParts = (urlParts: IUrlParts, queryParams?: TQueryParams, hash?: string) => {
    const [mainUrl, mainQueryParams] = compileQueryParamsStyleUrlFromUrlPartsBuildMainUrlAndQueryParams(urlParts)

    return compileUrlFromUrlQueryParamsAndHash(
        mainUrl,
        { ...queryParams, ...mainQueryParams },
        hash
    )
}



export const compileUrlPartsFromMainStyleUrl = (mainUrl: string): IUrlParts => {
    // eslint-disable-next-line no-useless-escape
    const urlLexems = [...(mainUrl.match(/([^\/\(\)]+)|([\(\)])/g) || [])]

    const urlParts: IUrlParts = { mainPath: [], optionalPaths: [] }
    const optionalsStack = [urlParts]

    for (const lexem of urlLexems) {
        if (lexem === '(') {            
            const newOptional = { mainPath: [], optionalPaths: [] }

            optionalsStack[optionalsStack.length-1].optionalPaths.push(newOptional)
            optionalsStack.push(newOptional)
        }
        else if (lexem === ')') {
            if(optionalsStack.length === 1) {
                continue
            }

            const lastItem = optionalsStack.pop()

            if (lastItem?.mainPath.length === 0) {
                optionalsStack[optionalsStack.length-1].optionalPaths.pop()
            }
        }
        else {
            optionalsStack[optionalsStack.length-1].mainPath.push(lexem)
        }
    }

    return urlParts
}



// eslint-disable-next-line no-useless-escape
const compileUrlPartsFromQueryParamsStyleUrlGetUrlPartsFromMainUrl = (mainUrl: string) => [...(mainUrl.match(/([^\/]+)/g) || [])]

export const compileUrlPartsFromQueryParamsStyleUrl = (mainUrl: string, queryParams: TQueryParams): IUrlParts => {
    
    //@ts-ignore
    const queryParamsPreprocessed: [string, string][] = Object.entries(queryParams)
        .filter(([key]) => key[0] === '/')
        .map(([key, value]) => {
            let preprocessedKey = key.slice(1)
            preprocessedKey = preprocessedKey[preprocessedKey.length-1] === '/' ? preprocessedKey.slice(0,-1) : preprocessedKey

            return [preprocessedKey, value]
        })
        // хз как он сортирует - но делает это правильно
        .sort()

    const urlParts: IUrlParts = { mainPath: [], optionalPaths: [] }
    let optionalsStack = [urlParts]

    urlParts.mainPath = compileUrlPartsFromQueryParamsStyleUrlGetUrlPartsFromMainUrl(mainUrl)

    // алгоритм работает при условии что отсортировались queryParamsPreprocessed правильно
    let lastCorrectI = 0
    for (let i = 0; i < queryParamsPreprocessed.length; i++) {
        const [name, value] = queryParamsPreprocessed[i]

        const namePaths = name.split('/')

        const newStackItem: IUrlParts = { mainPath: [], optionalPaths: [] }

        if(namePaths.length === optionalsStack.length + 1) {
            if(i !== 0 && namePaths.slice(0,-1).join('/') !== queryParamsPreprocessed[lastCorrectI][0]) {
                continue
            }

            optionalsStack.push(optionalsStack[optionalsStack.length-1].optionalPaths[
                optionalsStack[optionalsStack.length-1].optionalPaths.length - 1
            ])
        }
        else if(namePaths.length < optionalsStack.length) {
            if(i !== 0 && name !== queryParamsPreprocessed[lastCorrectI][0].split('/').slice(0,namePaths.length).join('/')) {
                continue
            }

            optionalsStack = optionalsStack.slice(0, namePaths.length)
        }
        else if(i !== 0 && namePaths.slice(0,-1).join('/') !== queryParamsPreprocessed[lastCorrectI][0].split('/').slice(0,-1).join('/')) {
            continue
        }

        lastCorrectI = i

        optionalsStack[optionalsStack.length-1].optionalPaths.push(newStackItem)
        newStackItem.mainPath = compileUrlPartsFromQueryParamsStyleUrlGetUrlPartsFromMainUrl(value)
    }

    return urlParts
}



export const compileUrl = (
    urlStyle: EURL_STYLE,
    urlParts: IUrlParts, 
    queryParams: TQueryParams = {}, 
    hash?: string
) => urlStyle === EURL_STYLE.main
    ? compileMainStyleUrlFromUrlParts(urlParts, queryParams, hash)
    : compileQueryParamsStyleUrlFromUrlParts(urlParts, queryParams, hash)

export const getUrlPartsFromUrl = (
    urlStyle: EURL_STYLE,
    url: string,
    queryParams: TQueryParams
): IUrlParts => urlStyle === EURL_STYLE.main
    ? compileUrlPartsFromMainStyleUrl(url)
    : compileUrlPartsFromQueryParamsStyleUrl(url, queryParams)

export const checkIsOurUrl = (url: string) => !url.match(/^[\w]+:\/\//)

export const cleareUrlPartsFromMergeable = ({ mainPath, optionalPaths }: IUrlParts): IUrlParts => ({
    mainPath: (mainPath as string[]),
    optionalPaths: optionalPaths.map(path => cleareUrlPartsFromMergeable(path))
})

// прежде чем утверждать что что то работает неправильно - попытайся представить a и b в виде урла,
// так же представь результат их мерджа в виде урла, если урл получается - мб ты и прав.
// Если урл не получается - значит mergeUrlParts так работать не должен.
// 
// По-максимуму сохраняем старый урл (urlParts). Превносим все изменения из нового урла (urlParts)
// Но только если newUprtIsClosedPath === false
export const mergeUrlParts = (oldUprt: IUrlParts, newUprt: IUrlParts, newUprtIsClosedPath: boolean = false): IUrlParts => {
    assert(
        oldUprt.parentMainPathPosition === newUprt.parentMainPathPosition, 
        new PathRouterSystemError()
    )

    // Если новый путь лежит в старом пути - нужно сохранить старый путь
    const newUrlsPartsContainsInOldurlsParts = !newUprt.mainPath.some(
        (key, i) => key !== oldUprt.mainPath[i]
    )

    const mainPath = newUrlsPartsContainsInOldurlsParts && !newUprtIsClosedPath 
        ? oldUprt.mainPath 
        : newUprt.mainPath
    
    const [oldOptionalStarters, currentOptionalStarters] = [oldUprt, newUprt].map(
        path => Object.fromEntries(
            path.optionalPaths
                .filter(oPath => {
                    let savedPath = mainPath.length > path.mainPath.length ? path.mainPath : mainPath
                    const outSavedPath = mainPath.length > path.mainPath.length ? mainPath : path.mainPath

                    for(let i=0; i < savedPath.length; i++) {
                        if(savedPath[i] !== outSavedPath[i]) {
                            savedPath = savedPath.slice(0, i)
                            break
                        }
                    }

                    const isApproved = !oPath.parentMainPathPosition || oPath.parentMainPathPosition < savedPath.length

                    return isApproved
                })
                .map(oPath => [oPath.mainPath[0], oPath])
        )
    )

    const optionalPaths: IUrlParts[] = []

    for (const [key, path] of Object.entries(oldOptionalStarters)) {
        optionalPaths.push(
            currentOptionalStarters[key]
                ? mergeUrlParts(path, currentOptionalStarters[key])
                : path
        )
    }

    for (const [key, path] of Object.entries(currentOptionalStarters)) {
        if(oldOptionalStarters[key]) {
            continue
        }

        optionalPaths.push(path)
    }

    return {
        parentMainPathPosition: newUprt.parentMainPathPosition, // в current и в old должны совпадать
        mainPath,
        optionalPaths
    }
}

export const removeFromUrlParts = (urlParts: IUrlParts, node: PathNode<any>) => {
    assert(!(node instanceof PathNodeRoot), new PathRouterRemoveNodeRootError())

    const arrayPath = getArrayPath(node)

    const newUrlParts = { ...urlParts }

    let curUrlParts = newUrlParts
    let curUrlPartIndex = 0

    for (let j = 0; j < arrayPath.length; j++) {
        const n = arrayPath[j]

        if(n.optional) {
            const i = curUrlParts.optionalPaths.findIndex(({ mainPath }) => mainPath[0] === n.name)

            if (i === -1) {
                break
            }

            if(j === arrayPath.length - 1) {
                curUrlParts.optionalPaths = arSplice(curUrlParts.optionalPaths, i, 1)

                break
            }

            curUrlParts.optionalPaths[i] = { ...curUrlParts.optionalPaths[i] }
            curUrlParts = curUrlParts.optionalPaths[i]
            curUrlPartIndex = 0
        }
        else {
            if(
                curUrlPartIndex === curUrlParts.mainPath.length ||
                (!n.isParameter && curUrlParts.mainPath[curUrlPartIndex] !== n.name) ||
                (n.isParameter && !checkMatchParam(curUrlParts.mainPath[curUrlPartIndex], n.parametrType))
            ) {
                break
            }

            if(j === arrayPath.length - 1) {
                curUrlParts.mainPath = curUrlParts.mainPath.slice(0, curUrlPartIndex)
                curUrlParts.optionalPaths = curUrlParts.optionalPaths.filter(
                    // eslint-disable-next-line no-loop-func
                    ({ parentMainPathPosition }) => parentMainPathPosition && parentMainPathPosition < curUrlPartIndex
                )

                break
            }

            curUrlPartIndex++
        }
    }

    return newUrlParts
}

export const nodeParamsToUrlParts = (oldUrlParts: IUrlParts, nodeParams: TNodeParam[]) => 
    nodeParams.reduce(
        ([accUrlParts, accParams]: [IUrlParts, TPathParams | IPathParamsLocal], [node, params]) => {
            const arrayPath = getArrayPath(node)

            let realParams = params

            if(isRemoveParams(realParams)) {
                realParams = {}
            }

            if(isClosedPath(realParams)) {
                realParams = realParams.params
            }

            if(isLocalParams(realParams)) {
                realParams = localParamsToParams(arrayPath, realParams) 
            }

            const curParams = deepmerge(accParams, realParams)

            return [
                isRemoveParams(params)
                    ? removeFromUrlParts(accUrlParts, node)
                    : mergeUrlParts(accUrlParts, node.__getUrlParts(curParams), isClosedPath(params)),
                curParams
            ] as [IUrlParts, TPathParams | IPathParamsLocal]
        },
        [oldUrlParts, {}]
    )[0]


export const pathParamsToUrlParts = (arrayPath: PathNode<any>[], params: TPathParams) => {
    const mainPath: string[] = [] 
    const optionalPaths: IUrlParts[] = []

    for (let i = 0; i < arrayPath.length; i++) {
        const path = arrayPath[i]

        // ограничение на первая нода не может быть optional
        if (path.optional && i !== 0) {
            const optionalPathParams = params[path.name as string] || {}
            
            optionalPaths.push(pathParamsToUrlParts(
                arrayPath.slice(i),
                optionalPathParams as TPathParams
            ))
            
            break
        }
        else if(path.isParameter) {
            const paramValue = params[path.name as string]
            assert(!!paramValue, new PathRouterParamIsNotDefinedError(path.name as string))

            mainPath.push(paramValue.toString())
        }
        else {
            mainPath.push(path.name as string)
        }
    }

    return {
        parentMainPathPosition: 0, 
        mainPath,
        optionalPaths: optionalPaths
            .map(path => ({
                ...path,
                parentMainPathPosition: mainPath.length - 1,
            }))
    }
} 

// убираются optionals через "зануление в параметрах"
// в setUrl можно задать несколько путей и несколько параметров к каждому из них


export const getArrayPath = (node: PathNode<any>): PathNode<any>[] => {
    const path = []

    for(
        let current = node;
        true;
        //@ts-ignore
        current = current.parent
    ) {
        path.push(current)

        if(!current.parent) {
            break
        }
    }

    return path.reverse()
}



// указывает, что в качестве параметров пути используются параметры ближайшей к листу опциональной ноды
export const local = (params: TPathParams = {}): IPathParamsLocal => ({ __isPathParamsLocal: 'true', params })

// указывает, что все дочерние ноды этого листа, которые существуют в дереве надо исключить
export const closed = (params: TPathParams | IPathParamsLocal = {}): IPathParamsClosed => ({ __isPathParamsClosed: 'true', params })

// указывает, что данный путь нужно исключить из дерева
export const remove = (): IPathParamsRemove => ({ __isPathParamsRemove: 'true' })



export const isPathParamValue = (variable: any): variable is TPathParamValue => typeof variable !== 'object' || variable === null

export const isLocalParams = (variable: any): variable is IPathParamsLocal => !!variable?.__isPathParamsLocal

export const isRemoveParams = (variable: any): variable is IPathParamsRemove => !!variable?.__isPathParamsRemove

export const isClosedPath = (variable: any): variable is IPathParamsClosed => !!variable?.__isPathParamsClosed



export const localParamsToParams = (arrayPath: PathNode<any>[], localParams: IPathParamsLocal): TPathParams => ({
    [
        arrayPath
            .filter(node => node.optional)
            .map(node => node.name as string)
            .join('.')
    ]: localParams.params
})

// в роутере после изменения урла надо составить текущие urlPats
// очищает от левых urlParts, ненужных этому конкретному дереву
export const growOldUrlPats = (
    { mainPath, optionalPaths }: IUrlParts, 
    node: PathNode<any>, 
    parentMainPathPosition: number
): IUrlParts => {
    const optionalStarterParentMainPathPosition: IHashMap<[PathNode<any> | null, number]> = Object.fromEntries(
        optionalPaths.map(({ mainPath }) => [mainPath[0], [null, 0]])
    )

    let i = 0
    let currentNode
    let currentNodeChilds = [node]
    
    for (; i < mainPath.length; i++) {
        // eslint-disable-next-line no-loop-func
        currentNode = currentNodeChilds.find(n => 
            (n.isParameter && checkMatchParam(mainPath[i], n.parametrType)) || 
            (!n.isParameter && n.name === mainPath[i])
        )

        if(!currentNode) {
            break
        }

        currentNodeChilds = Object.values(currentNode.childs)

        for (const child of currentNodeChilds) {
            if(child.optional && optionalStarterParentMainPathPosition[child.name as string]) {
                optionalStarterParentMainPathPosition[child.name as string] = [
                    child,
                    i
                ]
            }
        }
    }
    
    return {
        mainPath: mainPath.slice(0, i),
        parentMainPathPosition,
        optionalPaths: optionalPaths
            .filter(path => optionalStarterParentMainPathPosition[path.mainPath[0]][0] !== null)
            .map(path => growOldUrlPats(
                path, 
                ...(optionalStarterParentMainPathPosition[path.mainPath[0]]) as [PathNode<any>, number]
            )
        )
    }
}


export const urlPartsRootNodeable = (urlParts: IUrlParts): IUrlParts => ({
    ...urlParts,
    mainPath: [PathNodeRoot.ROOT_NODE_NAME, ...urlParts.mainPath]
})

export const urlPartsRootNodeles = (urlParts: IUrlParts): IUrlParts => ({
    ...urlParts,
    mainPath: urlParts.mainPath.slice(1)
})

export const checkNodeCorrect = (
    node: PathNode<any>,
    prevParamOrOprionalNames: string[] = []
) => {
    assert(!node.optional || !node.isParameter, new PathRouterOptionalAndParametrNodeError(node.name as string))

    const noUniqueChilds = getNoUniqOnlyArray(
        (Object.values(node.childs as IHashMap<PathNode<any>>).map(child => child.name as string))
    )
    assert(
        noUniqueChilds.length === 0, 
        new PathRouterNamesConflictError(noUniqueChilds)
    )

    assert(
        (!node.optional && !node.isParameter) || !prevParamOrOprionalNames.some(name => node.name === name), 
        new PathRouterNamesConflictError([node.name as string])
    )

    for (const child of Object.values(node.childs) as PathNode<any>[]) {
        checkNodeCorrect(
            child,
            node.optional ? [] : [...prevParamOrOprionalNames, ...(node.isParameter ? [node.name as string] : [])]
        )
    }
}

export const cloneNode = <T extends IHashMap<PathNode<any>>>(node: PathNode<T>): PathNode<T> => new PathNode(
    node.options,
    Object.fromEntries(Object.entries(node.childs as T)
        .map(([key, node]) => [key, cloneNode(node)])
    )
) as PathNode<T>

export const getFromMultiple = <T extends IHashMap<PathNode<any>>>(nodeInMultiple: PathNode<T>, anyNode: PathNode<any>): PathNode<T> => {
    let multipleNode: PathMultipleNode<any>
    let currentNode: PathNode<any> | undefined = nodeInMultiple
    let nodesPath = []
    for(
        ; 
        !!currentNode && !currentNode?.multipleRoot; 
        currentNode = currentNode.parent
    ) {
        // eslint-disable-next-line no-loop-func
        const childName = Object.entries((currentNode.parent?.childs as IHashMap<PathNode<any>>) || {}).find(([_, child]) => child === currentNode)?.[0]
        if(childName) {
            nodesPath.push(childName)
        }
    }
    nodesPath = nodesPath.reverse()

    assert(!!currentNode?.multipleRoot, new PathRouterPathMultipleNodeNotInsideError(nodeInMultiple.name as string))

    multipleNode = currentNode?.multipleRoot as PathMultipleNode<any>

    let anyNodeMultipleRoot: PathNode<any>
    currentNode = anyNode
    for(
        ; 
        !!currentNode && !currentNode?.multipleRoot; 
        currentNode = currentNode.parent
    ) {}

    assert(!!currentNode, new PathRouterPathMultipleNodeNotInsideError(anyNode.name as string))

    assert(
        currentNode?.multipleRoot === multipleNode, 
        new PathRouterPathMultipleNodeNotSameInsideError(nodeInMultiple.name as string, anyNode.name as string)
    )

    anyNodeMultipleRoot = currentNode as PathNode<any>

    let findedNode: PathNode<T> = anyNodeMultipleRoot
    currentNode = multipleNode.root
    for(let i = 0; currentNode !== nodeInMultiple; i++) {
        assert(!!findedNode && !!currentNode && i < nodesPath.length, new PathRouterSystemError())

        findedNode = findedNode.childs[nodesPath[i]]
        currentNode = currentNode?.childs[nodesPath[i]]
    }

    return findedNode
}
