import { ButtonBase } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../widgets/Icon';
import Centered from '../../../tools/Components/atoms/Centered';

function SortableTableCleareAll ({ canClear, onClear }) {
  return (
    <Centered
      hide={!canClear}
    >
      <ButtonBase onClick={onClear}>
        <Icon
          small
          color='error'
          Component={CloseIcon}
        />
      </ButtonBase>
    </Centered>
  )
}

export default SortableTableCleareAll
