import api from '../../../endpoints';
import router from '../../../paths';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { remove } from '../../../tools/libs/paths/helpers';
import Dialog from '../../../ui/widgets/Dialog';
import { useCallback, useMemo } from 'react'
import { Formik } from 'formik';
import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import TourModalForm from './TourModalForm';
import { tsToMomentMoscow } from '../../../utils/moment';
import { tourFormSchema } from './tourFormSchema';

const EditTour = ({ ...props }) => {
  const [{ editTour }] = usePathParams()
  const { tourId } = editTour || {}

  const [{ data }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const initialValues = useMemo(
    () => data && ({
      ...data,
      start_date: tsToMomentMoscow(data.start_date),
      end_date: tsToMomentMoscow(data.end_date),
      responsible_manager: data.manager.id,
      responsible_escort: data.escort?.id,
    }),
    [data]
  )

  const onClose = useCallback(
    () => router.pushUrl(router.getUrl([
        [router.root.c.editTour.c.one, remove()]
      ]
    )),
    []
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { error } = await api.tree.tours.edit.request([
        values.id,
        {
          ...values,
          start_date: values.start_date?.unix(),
          end_date: values.end_date?.unix()
        }
      ])

      setSubmitting(false)

      if(error) {
        return
      }

      onClose()
    },
    [onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Tour.editModal.title'
      lSubmitLable='Common.save'
      withoutBtns={!!initialValues}
    >
      {initialValues && (
        <Formik
          initialValues={initialValues}
          validationSchema={tourFormSchema}
          onSubmit={onSubmit}
        >
          <TourModalForm
            lSubmitLable='Common.save'
            onClose={onClose}
          />
        </Formik>
      )}
    </Dialog>
  )
}

export default EditTour
