import { memo, useMemo } from 'react'
import merge from 'deepmerge'
import { Typography, TableCell } from '@mui/material'
import clsx from 'clsx'
import { getIn } from '../../../tools/utils/object'

/* const useStyles = createUseStyles(theme => ({
  root: {
    height: 50,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  sizeSmall: {
    height: 36,
    paddingTop: 3,
    paddingBottom: 3
  },
  paddingNone: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  clickable: {
    cursor: 'pointer'
  },
  cellText: {
    wordBreak: 'break-word'
  }
})) */

function TableViewColumn ({
  column,
  row,
  rendererProps,
  rowIndex,
  columnIndex,
  clickable,
  compiledRowProps,
  onClick,
  onHover,
  onUnhover
}) {
  const value = useMemo(
    () =>
      column.preprocess
        ? column.preprocess(getIn(row, column.id), row, column)
        : getIn(row, column.id),
    [column, row]
  )

  const renderComponentColumn = useMemo(() => merge(column, compiledRowProps), [
    column,
    compiledRowProps
  ])

  const RenderComponent = column.renderer

  let renderedValue = column.hideValue || null

  if (!column.hide?.(value, row)) {
    if (RenderComponent != null) {
      renderedValue = (
        <RenderComponent
          global={rendererProps}
          value={value}
          column={renderComponentColumn}
          row={row}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
        />
      )
    } else if (
      typeof value === 'number' ||
      typeof value === 'string' ||
      value instanceof String
    ) {
      renderedValue = (
        <Typography
          variant='body2'
          {...column.typographyProps}
          className={clsx({
            // [classes.cellText]: !column.doNotCarry
          })}
        >
          {value}
        </Typography>
      )
    } else {
      renderedValue = value
    }
  }

  return (
    <TableCell
      key={columnIndex}
      {...column.rowCellProps}
      style={{ 
        maxWidth: column.maxWidth, 
        width: column.width, 
        minWidth: column.minWidth, 
        ...column.rowCellProps?.style
      }}
      className={clsx(
        // classes.root, 
        column.rowCellProps?.className,
        // clickable && !column.disableClick && classes.clickable,
      )}
      classes={{
        ...column.rowCellProps?.classes,
        // paddingNone: classes.paddingNone,
        // sizeSmall: classes.sizeSmall
      }}
      padding={column.padding}
      size={column.size}
      align={column.numeric ? 'right' : 'left'}
      onMouseOver={onHover}
      onMouseOut={onUnhover}
      onClick={
        column.onClick?.(value, row) ||
        (column.disableClick ? undefined : onClick)
      }
    >
      {renderedValue}
    </TableCell>
  )
}

export default memo(TableViewColumn)
