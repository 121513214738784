import { PathNode } from "../../libs/paths"
import { assert } from "../../utils/assert"
import { PathRouterComponentPathTypeError } from "./errors"
import usePathRouter from "./usePathRouter"

const usePathMatch = (path: PathNode<any>, exact?: boolean) => {
  usePathRouter(path?.router, 'usePathMatch')

  assert(path instanceof PathNode, new PathRouterComponentPathTypeError('usePathMatch', typeof path))

  return path[exact ? 'isMatchExact' : 'isMatch']
}

export default usePathMatch
