import { memo, useMemo, useCallback } from 'react'
import { TableRow, Checkbox, Radio } from '@mui/material'
import clsx from 'clsx'
import TableViewColumn from './TableViewColumn'
import Clickly from '../../../tools/Components/atoms/Clickly'
import Childer from '../../../tools/Components/Childer'
import PathLink from '../../../tools/Components/paths/PathLink'

/* const useStyles = createUseStyles(theme => ({
  clickly: {
    verticalAlign: 'inherit'
  },
  highlighted: {
    backgroundColor: (params) =>
      `${theme.palette.expandable[`level${params?.selectLvl || 1}`]} !important`
  },
  selected: {
    '&, &:hover': {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`
    }
  },
  checkbox: {
    padding: 6
  }
})) */

const Select = ({
  column: { size, selected, handleSelectCompiled, multipleSelection, disabled }
}) => {
  const Component = multipleSelection ? Checkbox : Radio

  return (
    <Component
      color='primary'
      checked={selected}
      onClick={handleSelectCompiled}
      disabled={disabled}
    />
  )
}

function TableViewRow ({
  hasSelectedIds,
  isSelected,
  row,
  highlighted,
  selectable,
  onSelect,
  clickable,
  onClick,
  onHover,
  onUnhover,
  href,
  rowIndex,
  rowProps,
  tableRowProps,
  multipleSelection,
  columns,
  rendererProps,
  selectLvl,
  size
}) {
  // const classes = useStyles({ selectLvl })

  const selected = useMemo(() => hasSelectedIds && isSelected(row.id), [
    hasSelectedIds,
    isSelected,
    row.id
  ])

  const isHighlighted = useMemo(
    () =>
      typeof highlighted !== 'undefined' &&
      (typeof highlighted === 'function'
        ? highlighted(row)
        : highlighted === row.id),
    [highlighted, row]
  )

  const handleSelectCompiled = useCallback(() => selectable && onSelect(row.id), [
    onSelect,
    row.id,
    selectable
  ])

  const clickHandler = useMemo(
    () => onClick ? () => onClick(row, rowIndex) : undefined,
    [rowIndex, onClick, row]
  )

  const handleHover = useCallback(
    () => onHover && onHover(row, rowIndex),
    [rowIndex, onHover, row]
  )

  const handleUnhover = useCallback(
    () => onUnhover && onUnhover(row, rowIndex),
    [rowIndex, onUnhover, row]
  )

  const hrefCompiled = useMemo(
    () => (href ? href(row, rowIndex) : undefined),
    [rowIndex, href, row]
  )

  const compiledRowProps = useMemo(
    () => (typeof rowProps === 'function' ? rowProps(row) : rowProps || {}),
    [row, rowProps]
  )

  const compiledRowTagProps = useMemo(
    () =>
      typeof tableRowProps === 'function'
        ? tableRowProps(row)
        : tableRowProps || {},
    [row, tableRowProps]
  )

  /*
    есть 3 стратегии развития подхода инъекции сторонних данных, обрабатываемых не через columns, а отдельно:
    1. свойства, которые добавляются ниже в TableRow дописывать и в renderComponent и в renderElement через cloneElement
    2. принимать флаг, требуется ли инъектировать эти свойства
    3. ничего не делать
  */
  if (row.renderElement) {
    return row.renderElement
  }

  if (row.renderComponent) {
    const Rc = row.renderComponent
    return <Rc key={row.id} value={row.value} />
  }

  return (
    <TableRow
      // classes={{ selected: classes.selected }}
      {...compiledRowTagProps}
      className={clsx(
        // !selected && clickable && classes.rootHover,
        row.className,
        // !selected && isHighlighted && classes.highlighted,
        compiledRowTagProps.className
      )}
      hover={clickable}
      tabIndex={-1}
      key={row.id}
      selected={selected || isHighlighted}
    >
      {selectable && (
        <TableViewColumn
          compiledRowProps={compiledRowProps}
          column={{
            id: 'checked',
            selected,
            handleSelectCompiled,
            multipleSelection,
            renderer: Select,
            padding: 'none',
            width: 48
          }}
          row={row}
          rendererProps={rendererProps}
          rowIndex={rowIndex}
          selectable={selectable}
        />
      )}
        {columns.map((column, columnIndex) => (
          <Childer
            need={clickable && !column.noClick}
            parrent={children => (
              <PathLink href={hrefCompiled} onClick={clickHandler}>
                <Clickly>
                  {children}
                </Clickly>
              </PathLink>
            )}
          >
            <TableViewColumn
              key={columnIndex}
              compiledRowProps={compiledRowProps}
              column={column}
              row={row}
              rendererProps={rendererProps}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
              selectable={selectable}
              clickable={clickable}
              onHover={handleHover}
              onUnhover={handleUnhover}
            />
          </Childer>
        ))}
    </TableRow>
  )
}

export default memo(TableViewRow)
