import { useState, useContext } from 'react'
import { Event } from '../../libs/event'
import { PathNode, PathRouter, TURL_SET_TYPE_EVENT } from '../../libs/paths'
import { IHashMap } from '../../utils/types'
import useOnEventBefore from '../event/useOnEventBefore'
import { assert } from '../../utils/assert'
import { PathCaseContext } from '../../Components/paths/PathCase'
import { PathRouterContext } from '../../Components/paths/PathRouterProvider'
import { PathRouterHooksSomeIsNotDefinedError } from './errors'
import { PathRouterPathNodeIsNotDefinedError } from '../../libs/paths/errors'

const usePathRouter = <T extends IHashMap<PathNode<any>>>(outRouter?: PathRouter<T>, errorPoint: string = 'usePathRouter') => {
  const { currentPath } = useContext(PathCaseContext)
  const { router: ctxRouter } = useContext(PathRouterContext)

  assert(!!outRouter || !!ctxRouter || !!currentPath, new PathRouterHooksSomeIsNotDefinedError(errorPoint))
  assert(!!outRouter || !!ctxRouter || !!currentPath?.router, new PathRouterPathNodeIsNotDefinedError(errorPoint))

  const router = (outRouter || ctxRouter || currentPath?.router) as PathRouter<T>

  const [state, setState] = useState(router.state)

  useOnEventBefore(
    //@ts-ignore
    router.onSetUrl as Event<[TURL_SET_TYPE_EVENT]>,
      () => setState(router.state)
  )

  return [router, state] as [PathRouter<T>, PathRouter<T>['state']]
}

export default usePathRouter
