import PagedSearchTable from '../PagedSearchTable'
import SortableTableHeadCell from './SortableTableHeadCell'
import { Typography } from '@mui/material'

function SortableTable ({ 
  columns, 
  sorter = {}, 
  onSetSorter, 
  data, 
  ...tableProps
}) {

  return (
    <PagedSearchTable
      {...tableProps}
      data={data}
      columns={columns}
      headerColumnCell={column =>
        (column.sorter || column.filter) ? (
          <SortableTableHeadCell
            sorter={sorter}
            onSetSorter={onSetSorter}
            column={column}
          />
        ) : (
          <Typography variant='body2'>
            {column.label}
          </Typography>
      )}
    />
  )
}

export default SortableTable
