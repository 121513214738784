import React from "react";
import usePathRouter from "../../hooks/paths/usePathRouter";

function PathSwitch ({ children }: { children: any }) {
  // для обновлений
  usePathRouter()

  let anyHasMatch = false

  return React.Children.map(children, (child, i) => {
    let opened = false

    if(!anyHasMatch) {
      opened = !child.props.path || child.props.path[child.props.exact ? 'isMatchExact' : 'isMatch']
      anyHasMatch = opened
    }

    return React.cloneElement(child, { ...child.props, opened })
  })
}

export default PathSwitch
