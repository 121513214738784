import api from '../../../../endpoints';
import router from '../../../../paths';
import Dialog from '../../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import useEnObserve from '../../../../tools/hooks/endpoints/useEnObserve';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { userToFullFio } from '../../../../utils/helpers';

const ReserveToWaitModal = ({ tourId, touristId, onClose, ...props }) => {
  const [{ data }] = useEnObserve(api.tree.reserves.one, [tourId, touristId], null, !!touristId)

  const [{ data: tour }] = useEnObserve(api.tree.tours.one, tourId, null, !!tourId)

  const onSubmit = useCallback(
    async () => {
      const { error } = await api.tree.reserves.edit.request([
        tourId,
        touristId,
        {
          ...data,
          tourist: undefined,
          status_payment: 'wait_list',
        }
      ])

      if(error) {
        return
      }

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.one.c.waits.c.one, { tourId, touristId }]
      ]))

      onClose()
    },
    [data, tourId, touristId, onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Reserve.moveToWait'
      onSubmit={onSubmit}
      message={data && (
        <Trans
          i18nKey='Reserve.moveToWait.message'
          values={{ tourist: userToFullFio(data.tourist), tour: tour.title }}
          components={[
            <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />,
            <Typography key={1} display='inline' variant='subtitle1' color='textPrimary' />,
          ]}
        />
      )}
      lSubmitLable='Reserve.moveToWait'
    />
  )
}

export default ReserveToWaitModal
