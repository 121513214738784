import { Typography } from '@mui/material'
import { 
  userToFullFio 
} from '../../utils/helpers';
import { DASH_LINE } from '../../tools/utils/string';

export const TourManagerRenderer = ({ value: tour }) => (
  <Typography variant='body1'>
    {tour.manager ? userToFullFio(tour.manager) : DASH_LINE}
  </Typography>
)
