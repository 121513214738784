import { Typography, List, Popover, ListItemButton, Radio } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react'

export const TouristArchiveFilter = ({
  onClose,
  anchorEl,
  filterState,
  onChange,
  opened,
}) => {
  const { t } = useTranslation()

  const clickArchive = useCallback(
    () => onChange(true),
    [onChange]
  )

  const clickNoArchive = useCallback(
    () => onChange(false),
    [onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItemButton
          onClick={clickNoArchive}
        >
          <Radio
            color='primary'
            checked={!filterState}
          />
          <Typography>
            {t('Tourist.filters.isNoArchive')}
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={clickArchive}
        >
          <Radio
            color='primary'
            checked={!!filterState}
          />
          <Typography>
            {t('Tourist.filters.isArchive')}
          </Typography>
        </ListItemButton>
      </List>
    </Popover>
  )
}
