import { getIn } from "./object"

export const arrFirstLvlIsEqual = (arr1: any[], arr2: any[]) => 
    arr1?.length === arr2?.length && !arr1.some((val, i) => arr2[i] !== val)

export const arSplice = (arr: any[], start: number, deleteCount?: number, newElems: any[] = []) => {
    const newArr = [...arr]
    //@ts-ignore
    newArr.splice(start, deleteCount, ...newElems)
    return newArr
}

export const getUniqArray = <T>(
    array: T[],
    keyPath?: string
) => keyPath 
    ? Array.from(new Map(array.map(el => [getIn(el, keyPath), el]))).map(([, el]) => el)
    : Array.from(new Set(array))

export const getRepeatCountArrayItemsMap = <T>(
    array: T[],
) => {
    const itemsMap = new Map<T, number>()

    for (const item of array) {
        let count = itemsMap.get(item)
        count = count ? (count + 1) : 1
        itemsMap.set(item, count)
    }

    return itemsMap
}

export const getNoUniqOnlyArray = <T>(
    array: T[],
) => [...getRepeatCountArrayItemsMap(array).entries()]
    .filter(([, count]) => count > 1)
    .map(([value]) => value)

export const arrayIsUniqItems = <T>(
    array: T[],
) => getUniqArray(array).length === array.length

export const makeSortComparer = (path: string, reverse = false) => <T>(a: T, b: T) => {
    const valA = getIn(a, path) as any
    const valB = getIn(b, path) as any

    return valA > valB ? (reverse ? -1 : 1) : valA < valB ? (reverse ? 1 : -1) : 0
}
export type TArrayable<T> = T[] | T

export const transformArryableVar = <T>(arryableVar: TArrayable<T>) =>
  Array.isArray(arryableVar) ? arryableVar : [arryableVar]
  

export const findStrInArray = (arr: any[], str: string, finder: any) =>
  arr.some(el =>
    (typeof el === 'string' || typeof el === 'number') && finder
      ? finder(String(el), String(str))
      : String(el)
        .toLowerCase()
        .indexOf(String(str).toLowerCase()) > -1
  )