import { autobind } from "core-decorators";
import { PathUrlStore } from "./PathUrlStore";
import { checkIsOurUrl } from "../helpers";
import { logger } from "../../../utils/logger";
import { PathRouter } from "../";

const getUrl = () => decodeURI(window.location.pathname + window.location.search + window.location.hash)

@autobind
export class PathUrlStoreBrowser extends PathUrlStore {
    
    public constructor () {
        super(getUrl())
    }

    public override pushUrl(url: string) {
        super.pushUrl(url)

        if(checkIsOurUrl(url)) {
            window.history.pushState(null, '', url)
        }
        else {
            window.location.href = url
        }

        logger.log('PathUrlStoreBrowser onPushUrl', this.url)
    }

    public override replaceUrl(url: string) {
        super.replaceUrl(url)

        if(checkIsOurUrl(url)) {
            window.history.replaceState(null, '', url)
        }
        else {
            window.location.href = url
        }
        logger.log('PathUrlStoreBrowser onReplaceUrl', this.url)
    }

    public override goBack() {
        // конфликтует с __applyRouter
        // super.goBack()

        window.history.back()
    }

    public override goNext() {
        // конфликтует с __applyRouter
        // super.goNext()

        window.history.forward()
    }

    // TODO: БУЛЩИТ!!!
    // При адекватном поведении goBack и goNext, если не было бы логики __applyRouter
    // и были бы раскоменчены super.goBack() и super.goNext()
    // В момент загрузки страницы в браузере может храниться история, но из браузера её не вытянишь
    // Но в момент загрузки страницы, так как её не вытянуть из браузера, в this.history будет только текущий урл
    // И при вызовах goBack и goNext урл в браузере меняться будет, но this.history и this._historyPosition не изменятся.
    // !!!!!!! переписать на что то вменяемое !!!!!!!
    public override __applyRouter(router: PathRouter<any>) {
        super.__applyRouter(router)
        
        global.addEventListener('popstate', () => {
            this._history = [getUrl()]
            this._historyPosition = 0

            this.router?.__onBackNextButton()

            logger.log('PathUrlStoreBrowser onBackOrNext', this.url)
        })
    }
}
