import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { Typography } from '@mui/material'
import Linely from '../../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { SortableTable } from '../../../ui/tables';
import { useMemo, useState, useCallback } from 'react'
import Box from '../../../tools/Components/atoms/Box';
import FilledSearch from '../../../ui/widgets/FilledSearch';
import api from '../../../endpoints';
import usePathRouter from '../../../tools/hooks/paths/usePathRouter';
import AddIcon from '@mui/icons-material/Add'
import LeftRight from '../../../tools/Components/atoms/LeftRight';
import CircleButton from '../../../ui/widgets/CircleButton';
import PathLink from '../../../tools/Components/paths/PathLink';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { TouristPhoneRenderer, touristPhoneSearcher } from '../../../ui/tableRenderers/TouristPhoneRenderer';
import { TouristSexRenderer } from '../../../ui/tableRenderers/TouristSexRenderer';
import { TouristSexFilter } from '../../../ui/TableFilters/TouristSexFilter';
import { TouristBirthdayRenderer } from '../../../ui/tableRenderers/TouristBirthdayRenderer';
import { TouristBirthdayFilter } from '../../../ui/TableFilters/TouristBirthdayFilter';
import { TouristFioRenderer, touristFioSearcher } from '../../../ui/tableRenderers/TouristFioRenderer';
import { TouristStatusFilter } from '../../../ui/TableFilters/TouristStatusFilter';
import { 
  sortedIsAsc, 
  touristIsBirthdayInTour, 
  userToFullFio 
} from '../../../utils/helpers';
import { local, remove } from '../../../tools/libs/paths/helpers';
import Icon from '../../../ui/widgets/Icon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Options from '../../../ui/widgets/Options';
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import PersonIcon from '@mui/icons-material/Person';
import ActionRenderer, { optionsRendererColumn } from '../../../ui/tableRenderers/ActionRenderer';
import DeleteReserve from './Modals/DeleteReserve';
import { useModal } from '../../../hooks/useModal';
import { usePercentWidth } from '../../../hooks/usePercentWidth';
import Loadable from '../../../ui/widgets/Loadable';
import SortableTableCleareAll from '../../../ui/tables/SortableTable/SortableTableCleareAll';
import { getPersonYears, tsToMomentMoscow } from '../../../utils/moment';
import moment from 'moment';
import { merge } from 'lodash';
import { TourCommentRenderer } from '../../../ui/tableRenderers/TourCommentRenderer';
import { ReserveTicketsRenderer } from '../../../ui/tableRenderers/ReserveTicketsRenderer';
import { ReserveStatusRenderer } from '../../../ui/tableRenderers/ReserveStatusRenderer';
import { ReservePaidRenderer } from '../../../ui/tableRenderers/ReservePaidRenderer';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ReserveToWaitModal from './Modals/ReserveToWaitModal';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ReserveToCancelModal from './Modals/ReserveToCancelModal';

const ReservesTablePage = () => {
  const { t } = useTranslation()
  
  const [optionsElement, setOptionsElement] = useState()

  const [router] = usePathRouter()

  const [{ 
    tourId 
  }] = usePathParams(router.root.c.tours.c.one)
  const [{ touristId }] = usePathParams(router.root.c.tours.c.one.c.reserves.c.one)

  const [{ rSearch: { value: rSearch } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rSearch.c.value), [{ rSearch: {} }])
  const [{ rSorted: { value: rSorted } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rSorted.c.value), [{ rSorted: {} }])
  const [{ rSex: { value: filterSex } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rSex.c.value), [{ rSex: {} }])
  const [{ rBirthdayBy: { value: rBirthdayBy } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rBirthdayBy.c.value), [{ rBirthdayBy: {} }])
  const [{ rAgeStart: { value: rAgeStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rAgeStart.c.value), [{ rAgeStart: {} }])
  const [{ rAgeEnd: { value: rAgeEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rAgeEnd.c.value), [{ rAgeEnd: {} }])
  const [{ rBirthdayStart: { value: rBirthdayStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rBirthdayStart.c.value), [{ rBirthdayStart: {} }])
  const [{ rBirthdayEnd: { value: rBirthdayEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rBirthdayEnd.c.value), [{ rBirthdayEnd: {} }])
  const [{ rBirthdayMonthStart: { value: rBirthdayMonthStart } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthStart.c.value), [{ rBirthdayMonthStart: {} }])
  const [{ rBirthdayMonthEnd: { value: rBirthdayMonthEnd } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthEnd.c.value), [{ rBirthdayMonthEnd: {} }])
  const [{ rTouristStatus: { value: rTouristStatus } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rTouristStatus.c.value), [{ rTouristStatus: {} }])
  const [{ rPage: { value: rPage } }] = merge(usePathParams(router.root.c.tours.c.one.c.reserves.c.rPage.c.value), [{ rPage: {} }])

  const searchValue = (rSearch ?? '').toString()

  const pageWidth = usePercentWidth()
  
  const [reserveToCancelModal, reserveToCancelModalOpen] = useModal(ReserveToCancelModal)
  const [reserveToWaitModal, reserveToWaitModalOpen] = useModal(ReserveToWaitModal)
  const [deletedReserveModal, deletedReserveOpen] = useModal(DeleteReserve)

  const [{ data: tour, isLoading }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: tourTourists }] = useEnObserve(api.tree.reserves.list, tourId)

  const tourReserves = useMemo(
    () => [...(tourTourists ?? [])]
      .filter(({ status_payment }) => ['prepayment', 'full'].indexOf(status_payment) > -1)
      .sort((a, b) => a.booking_date < b.booking_date ? 1 : -1)
      .map((reserve, index) => ({ ...reserve, tourist: { ...reserve.tourist, index: index + 1 } })),
    [tourTourists]
  )

  const mansTourists = useMemo(
    () => (tourReserves || [])
      .filter(({ status_payment, tourist: { sex } }) => status_payment !== 'cancellation' && sex === 'М')
      .length,
    [tourReserves]
  )

  const womansReserves = useMemo(
    () => (tourReserves || [])
    .filter(({ status_payment, tourist: { sex } }) => status_payment !== 'cancellation' && sex !== 'М')
      .length,
    [tourReserves]
  )

  const birthdayReserves = useMemo(
    () => tour && tourReserves
      ? tourReserves
        .filter(({ tourist }) => touristIsBirthdayInTour(tour, tourist))
        .length
      : 0,
    [tour, tourReserves]
  )
  
  const sorter = useMemo(
    () => {
      const [name, ascDesc] = rSorted?.split(',') || []

      return {
        name,
        asc: ascDesc === 'asc'
      }
    },
    [rSorted]
  )

  const filterBirthday = useMemo(
    () => ({
      by: rBirthdayBy,
      ageStart: rAgeStart,
      ageEnd: rAgeEnd,
      birthdayStart: rBirthdayStart ? moment.unix(rBirthdayStart) : null,
      birthdayEnd: rBirthdayEnd ? moment.unix(rBirthdayEnd) : null,
      birthdayMonthStart: rBirthdayMonthStart ? moment.unix(rBirthdayMonthStart) : null,
      birthdayMonthEnd: rBirthdayMonthEnd ? moment.unix(rBirthdayMonthEnd) : null,
    }),
    [
      rBirthdayBy,
      rAgeStart,
      rAgeEnd,
      rBirthdayStart,
      rBirthdayEnd,
      rBirthdayMonthStart,
      rBirthdayMonthEnd,
    ]
  )

  const filterTouristStatus = useMemo(
    () => (rTouristStatus && typeof rTouristStatus !== 'boolean') ? rTouristStatus.toString().split(',') : [],
    [rTouristStatus]
  )
  
  const filteredReserves = useMemo(
    () => (tourReserves || []).filter(
      (reserve) => 
        (
          (!filterTouristStatus || filterTouristStatus.length === 0) ||
          (filterTouristStatus.indexOf('normal') > -1 && reserve.tourist.status === 'normal') || 
          (filterTouristStatus.indexOf('dangerZone') > -1 && reserve.tourist.status === 'danger_zone') || 
          (filterTouristStatus.indexOf('blocked') > -1 && reserve.tourist.status === 'blocked')
        ) &&
        (
          // Фильтр должен быть выбран
          !filterBirthday.by || 
          (
            filterBirthday.by === 'age' && 
            (!filterBirthday.ageStart || (reserve.tourist.birthday && filterBirthday.ageStart <= getPersonYears(tsToMomentMoscow(reserve.tourist.birthday)))) &&
            (!filterBirthday.ageEnd || (reserve.tourist.birthday && filterBirthday.ageEnd >= getPersonYears(tsToMomentMoscow(reserve.tourist.birthday))))
          ) ||
          (
            filterBirthday.by === 'birthday' && 
            (!filterBirthday.birthdayStart || (reserve.tourist.birthday && filterBirthday.birthdayStart.unix() <= reserve.tourist.birthday)) &&
            (!filterBirthday.birthdayEnd || (reserve.tourist.birthday && filterBirthday.birthdayEnd.unix() >= reserve.tourist.birthday))
          ) ||
          (
            filterBirthday.by === 'birthdayMonth' && 
            (!filterBirthday.birthdayMonthStart || (reserve.tourist.birthday && filterBirthday.birthdayMonthStart.month() <= tsToMomentMoscow(reserve.tourist.birthday).month())) &&
            (!filterBirthday.birthdayMonthEnd || (reserve.tourist.birthday && filterBirthday.birthdayMonthEnd.month() >= tsToMomentMoscow(reserve.tourist.birthday).month()))
          ) ||
          (
            filterBirthday.by === 'thisTour' && 
            tour && reserve.tourist.birthday && touristIsBirthdayInTour(tour, reserve.tourist)
          )
        ) &&
        (
          filterSex == null ||
          (filterSex === 'm' && reserve.tourist.sex === 'М') ||
          (filterSex !== 'm' && reserve.tourist.sex !== 'М')
        )
    ),
    [
      tour,
      tourReserves, 
      filterSex, 
      filterTouristStatus, 
      filterBirthday
    ]
  )
  
  const sortedReserves = useMemo(
    () => [
      ...filteredReserves.sort((a, b) => {
        if(sorter.name === 'fio') {
          return sortedIsAsc(sorter.asc, userToFullFio(a.tourist) > userToFullFio(b.tourist))
        }
        else if(sorter.name === 'birthday') {
          const nowTs = moment().unix()
  
          return sortedIsAsc(sorter.asc, (a.tourist.birthday || nowTs) < (b.tourist.birthday || nowTs))
        }

        return a.tourist.index > b.tourist.index ? 1 : -1
      })
    ],
    [filteredReserves, sorter]
  )


  const onSetSearchValue = useCallback(
    (search) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rSearch.c.value, search ? local({ value: search }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetSorter = useCallback(
    ({ name, asc }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rSorted.c.value, local({ value: `${name},${asc ? 'asc' : 'desc'}` })],
      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterSex = useCallback(
    (sex) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rSex.c.value, sex ? local({ value: sex }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterBirthday = useCallback(
    ({
      by,
      ageStart,
      ageEnd,
      birthdayStart,
      birthdayEnd,
      birthdayMonthStart,
      birthdayMonthEnd,
    }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayBy.c.value, by ? local({ value: by }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rAgeStart.c.value, ageStart ? local({ value: ageStart }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rAgeEnd.c.value, ageEnd ? local({ value: ageEnd }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayStart.c.value, birthdayStart?.isValid() ? local({ value: birthdayStart?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayEnd.c.value, birthdayEnd?.isValid() ? local({ value: birthdayEnd?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthStart.c.value, birthdayMonthStart?.isValid() ? local({ value: birthdayMonthStart?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthEnd.c.value, birthdayMonthEnd?.isValid() ? local({ value: birthdayMonthEnd?.unix() }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterTouristStatus = useCallback(
    (touristStatus) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rTouristStatus.c.value, touristStatus?.length > 0 ? local({ value: touristStatus.join(',') }) : remove()],
      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )

  const reserveOptions = useCallback(
    ({ id_tour, tourist: { id } }) => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Reserve.editModal.title'),
        href: router.getUrl([
          [router.root.c.editReserve.c.tour.c.tourist, local({ touristId: id, tourId: id_tour })]
        ])
      },
      {
        icon: (<Icon Component={PersonIcon} />),
        text: t('Tourist.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: id })]
        ])
      },
      {
        icon: (<Icon Component={DisabledByDefaultIcon} color='error' />),
        text: t('Reserve.moveToCancel'),
        color: 'error',
        onClick: () => reserveToCancelModalOpen({ tourId: id_tour, touristId: id })
      },
      {
        icon: (<Icon Component={HourglassFullIcon} color='error' />),
        text: t('Reserve.moveToWait'),
        color: 'error',
        onClick: () => reserveToWaitModalOpen({ tourId: id_tour, touristId: id })
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Reserve.delete'),
        color: 'error',
        onClick: () => deletedReserveOpen({ tourId: id_tour, touristId: id })
      },
    ],
    [
      t, 
      router, 
      deletedReserveOpen, 
      reserveToWaitModalOpen,
      reserveToCancelModalOpen
    ]
  )
  
  const onClearAllFilters = useCallback(
    () => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.reserves.c.rSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.reserves.c.rSex.c.value, remove()],

      [router.root.c.tours.c.one.c.reserves.c.rSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.reserves.c.rBirthdayBy.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rAgeStart.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rAgeEnd.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayStart.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayEnd.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthStart.c.value, remove()],
      [router.root.c.tours.c.one.c.reserves.c.rBirthdayMonthEnd.c.value, remove()],

      [router.root.c.tours.c.one.c.reserves.c.rTouristStatus.c.value, remove()],

      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, remove()],
    ])),
    [router]
  )
  
  const columns = useMemo(
    () => [
      {
        id: 'tourist',
        sorter: 'fio',
        label: t('Common.fio'),
        filter: 'status',
        index: true,
        filterComponent: TouristStatusFilter,
        filterIsActive: filterTouristStatus?.length > 0,
        filterComponentProps: {
          filterState: filterTouristStatus,
          onChange: onSetFilterTouristStatus,
        },
        search: touristFioSearcher,
        renderer: TouristFioRenderer,
        maxWidth: pageWidth * 0.166,
      },
      {
        id: 'tourist',
        sorter: 'birthday',
        filter: 'birthday',
        filterComponent: TouristBirthdayFilter,
        filterIsActive: !!filterBirthday.by,
        filterComponentProps: {
          onThisTourAvailable: true,
          filterState: filterBirthday,
          onChange: onSetFilterBirthday,
        },
        label: t('Common.birthday'),
        tourDates: tour,
        renderer: TouristBirthdayRenderer
      },
      {
        id: 'tourist',
        filter: 'sex',
        filterComponent: TouristSexFilter,
        filterIsActive: filterSex != null,
        filterComponentProps: {
          filterState: filterSex,
          onChange: onSetFilterSex,
        },
        label: t('Common.sex'),
        renderer: TouristSexRenderer
      },
      {
        id: 'tourist',
        label: t('Common.phone'),
        search: touristPhoneSearcher,
        renderer: TouristPhoneRenderer
      },
      {
        id: '',
        sorter: 'payed',
        label: t('Common.payed'),
        tourPrice: tour?.price,
        renderer: ReservePaidRenderer
      },
      {
        id: '',
        label: t('Reserve.status'),
        tourPrice: tour?.price,
        renderer: ReserveStatusRenderer
      },
      {
        id: '',
        label: t('Common.tickets'),
        tourPrice: tour?.price,
        renderer: ReserveTicketsRenderer
      },
      {
        id: '',
        label: t('Tour.tourists.reserve.comment'),
        tourPrice: tour?.price,
        renderer: TourCommentRenderer
      },
      {
        id: '',
        noClick: true,
        columnView: () => (
          <SortableTableCleareAll
            canClear={
              filterTouristStatus?.length > 0 ||
              !!filterBirthday.by ||
              filterSex != null ||
              !!rSorted
            }
            onClear={onClearAllFilters}
          />
        ),
        action: { 
          ...optionsRendererColumn, 
          onClick: (tourist, e) => {
            setOptionsElement(
              <Options
                opened
                onClose={() => setOptionsElement(null)}
                options={reserveOptions(tourist)}
                anchorEl={e.target}
              />
            )
          }
        },
        renderer: ActionRenderer
      },
    ],
    [
      t, 
      onSetFilterSex,
      onSetFilterBirthday,
      onSetFilterTouristStatus,
      onClearAllFilters,
      pageWidth, 
      tour, 
      reserveOptions,
      filterSex,
      filterTouristStatus,
      filterBirthday,
      rSorted
    ]
  )

  const href = useCallback(
    ({ tourist: { id } }) => router.root.c.tours.c.one.c.reserves.c.one.getUrl(
      {
        touristId: id
      }
    ),
    [
      router,
    ]
  )

  const checkIsHighlighted = useCallback(
    ({ tourist: { id } }) => id === touristId,
    [touristId]
  )

  return (
    <>
      {deletedReserveModal}
      {reserveToCancelModal}
      {reserveToWaitModal}
      {optionsElement}
      <Loadable
        loading={!tourTourists && isLoading}
        empty={!tour || !tourTourists}
      >
        {() => (
          <Linely
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely
                gap={0}
                vertical
              >
                <Typography variant='h6'>
                  {t('Tour.tourists.reserves.title')}
                </Typography>
                <Linely gap='i2'>
                  <Typography color='textSecondary'>
                    <Trans
                      i18nKey='Tour.tourists.count'
                      values={{ count: tour.busy_places, total: tour.total_places }}
                      components={[
                        <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />,
                        <Typography key={1} display='inline' variant='subtitle1' color='textPrimary' />
                      ]}
                    />
                  </Typography>
                  <Linely gap='i4'>
                    <Icon
                      Component={FaceIcon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {mansTourists}
                    </Typography>
                  </Linely>
                  <Linely gap='i4'>
                    <Icon
                      Component={Face2Icon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {womansReserves}
                    </Typography>
                  </Linely>
                  {!!birthdayReserves && (
                    <Typography color='textSecondary'>
                      <Trans
                        i18nKey='Tour.tourists.birthdys'
                        values={{ count: birthdayReserves }}
                        components={[
                          <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                        ]}
                      />
                    </Typography>
                  )}
                </Linely>
              </Linely>
              {tour.total_places > tour.busy_places && (
                <PathLink
                  href={router.getUrl([
                    [router.root.c.createReserve.c.tour, local({ tourId: tour.id })]
                  ])}
                >
                  <CircleButton
                    big
                    variant='contained'
                  >
                    <Icon
                      big
                      Component={AddIcon}
                    />
                  </CircleButton>
                </PathLink>
              )}
            </LeftRight>
            <FilledSearch
              value={searchValue}
              onChange={onSetSearchValue}
            />
            <Box mx='-i1'>
              <SortableTable
                highlighted={checkIsHighlighted}
                searchValue={searchValue}
                columns={columns}
                data={sortedReserves}
                href={href}
                sorter={sorter}
                onSetSorter={onSetSorter}
                paginationProps={{
                  rowsPerPage: 20,
                  page: rPage ? rPage -1 : 0,
                  onPageChange: (newPage) => 
                    router.pushUrl(router.getUrl([
                      [router.root.c.tours.c.one.c.reserves.c.rPage.c.value, local({ value: newPage + 1 })],
                    ]))
                }}
              />
            </Box>
          </Linely>
        )}
      </Loadable>
    </>
  )
}

export default ReservesTablePage
