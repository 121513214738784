import { useTranslation } from 'react-i18next';
import { Grid, Button, MenuItem, Typography } from '@mui/material'
import { Field } from 'formik';
import Box from '../../../tools/Components/atoms/Box';
import SubmitButtonWrapper from '../../../tools/Components/formik/SubmitButtonWrapper';
import Linely from '../../../tools/Components/atoms/Linely';
import DatePickerField from '../../../ui/formik/DatePickerField';
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import TouristStatus from '../../../ui/widgets/TouristStatus';
import TextField from '../../../ui/formik/TextField';
import SelectField from '../../../ui/formik/SelectField';
import FormRequiredFieldsHelper from '../../../ui/widgets/FormRequiredFieldsHelper';
import { countries_list } from '../../../utils/countries';
import SearchableSelectField from '../../../ui/formik/SearchableSelectField';

const TouristModalForm = ({ lSubmitLable, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box
        px='i1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('Tourist.mainInfo')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              required
              name='surname'
              label={t('Common.surname')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              required
              name='first_name'
              label={t('Common.firstName')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name='second_name'
              label={t('Common.secondName')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              required
              name='phone'
              label={t('Common.phone')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              required
              name='status'
              label={t('Tourist.status')}
              as={SelectField}
            >
              <MenuItem value='normal'>
                <Linely>
                  <TouristStatus status='normal' />
                  <Typography>
                    {t('Tourist.status.normal')}
                  </Typography>
                </Linely>
              </MenuItem>
              <MenuItem value='danger_zone'>
                <Linely>
                  <TouristStatus status='danger_zone' />
                  <Typography>
                    {t('Tourist.status.dangerZone')}
                  </Typography>
                </Linely>
              </MenuItem>
              <MenuItem value='blocked'>
                <Linely>
                  <TouristStatus status='blocked' />
                  <Typography>
                    {t('Tourist.status.blocked')}
                  </Typography>
                </Linely>
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name='birthday'
              label={t('Common.birthday')}
              component={DatePickerField}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    variant='filled'
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              required
              name='sex'
              label={t('Common.sex')}
              as={SelectField}
            >
              <MenuItem value='М'>
                <Linely>
                  <FaceIcon />
                  <Typography>
                    {t('Common.sex.man')}
                  </Typography>
                </Linely>
              </MenuItem>
              <MenuItem value='Ж'>
                <Linely>
                  <Face2Icon />
                  <Typography>
                    {t('Common.sex.women')}
                  </Typography>
                </Linely>
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='comment'
              label={t('Tourist.info')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('Tourist.contacts')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name='telegram'
              label={t('Common.telegram')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name='instagram'
              label={t('Common.instagram')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name='email'
              label={t('Common.email')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name='vk'
              label={t('Common.vk')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('Tourist.passport.title')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Field
              name='citizenship'
              label={t('Tourist.citizenship.label')}
              as={SearchableSelectField}
              items={countries_list}
              itemStrSearch={country => country.name}
              renderItem={country => (
                <MenuItem key={country.code} value={country.code}>
                  <Linely>
                    <img alt={country.code} src={country.icon} />
                    <Typography>
                      {country.name}
                    </Typography>
                  </Linely>
                </MenuItem>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              name='passport'
              label={t('Tourist.passport.label')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              name='passport_international'
              label={t('Tourist.passport_international.label')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name='passport_info'
              label={t('Tourist.passport_info.label')}
              variant="filled"
              as={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        px='i1'
        pt='i1'
      >
        <FormRequiredFieldsHelper />
      </Box>
      <Linely
        end
        gap='i2'
        px='i1'
      >
        <Button
          variant="contained"
          size='large'
          color='inherit'
          onClick={onClose}
        >
          {t('Common.close')}
        </Button>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size='large'
          >
            {t(lSubmitLable)}
          </Button>
        </SubmitButtonWrapper>
      </Linely>
    </>
  )
}

export default TouristModalForm
