import { Typography, List, Popover, ListItemButton, Checkbox } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import TouristStatus from '../widgets/TouristStatus';

export const TouristStatusFilter = ({
  onClose,
  anchorEl,
  filterState,
  onChange,
  opened,
}) => {
  const { t } = useTranslation()

  const clickValue = useCallback(
    value => () => onChange(
      filterState.indexOf(value) > -1
        ? filterState.filter(el => el !== value)
        : [...filterState, value]
      ),
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItemButton
          onClick={clickValue('normal')}
        >
          <Checkbox
            color='primary'
            checked={filterState.indexOf('normal') > -1}
          />
          <Linely>
            <TouristStatus status='normal' />
            <Typography>
              {t('Tourist.status.normal')}
            </Typography>
          </Linely>
        </ListItemButton>
        <ListItemButton
          onClick={clickValue('dangerZone')}
        >
          <Checkbox
            color='primary'
            checked={filterState.indexOf('dangerZone') > -1}
          />
          <Linely>
            <TouristStatus status='danger_zone' />
            <Typography>
              {t('Tourist.status.dangerZone')}
            </Typography>
          </Linely>
        </ListItemButton>
        <ListItemButton
          onClick={clickValue('blocked')}
        >
          <Checkbox
            color='primary'
            checked={filterState.indexOf('blocked') > -1}
          />
          <Linely>
            <TouristStatus status='blocked' />
            <Typography>
              {t('Tourist.status.blocked')}
            </Typography>
          </Linely>
        </ListItemButton>
      </List>
    </Popover>
  )
}
