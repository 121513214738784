import { TextField as MuiTextField } from '@mui/material'
import RequiredableLable from './ui/RequiredableLable'

const TextField = ({ label, required, ...props }) => (
    <MuiTextField
      {...props}
      label={(
        <RequiredableLable
          label={label}
          required={required}
        />
      )}
    />
  )

export default TextField
