import 'moment'
import moment from 'moment-timezone'

window.moment = moment

export const getDifference = (from, to) => to.unix() - from.unix()

export const getDifferenceFromNow = to => (to - moment().unix()) * 1000
export const getDifferenceToNow = from => -getDifferenceFromNow(from)

export function timeIsRight (timeObject) {
  return (
    !timeObject ||
    (Number.isInteger(timeObject.hour) && Number.isInteger(timeObject.minute))
  )
}

export const fromUTC = timestamp => moment.tz(timestamp, 'UTC')

export const secondsInDay = m => m.diff(m.clone().startOf('day'), 'seconds')

// dt: { hour, minute, second }
export function dtToMoment (dt) {
  return moment(fromUTC(0)).set(dt)
}

export function cleareDate (moment) {
  return moment.set({ year: 0, month: 0, day: 0 })
}

export function toUTC (moment) {
  return moment.utc().unix()
}

export const momentWithTimezoneFromSeconds = (ts, tz) =>
  moment.unix(ts).tz(tz || moment.tz.guess())

export const momentWithTimezone = (value, tz) => moment.tz(value, tz)

export const setCurrentYear = date => date.set(moment().year(), 'year')

// export const setToCurrentDay = date => date.set({ year: moment().year(), month: moment().month(), day: moment().day() })

export const dateFormat1 = 'L'
export const dateFormat2 = 'DD.MM'

export const timeFormat1 = 'LT'
export const timeFormat2 = 'LTS'
export const timeFormat3 = 'mm:ss'

export const dateTimeFormat = 'L LT'
export const dateTimeFormat2 = 'LT L'
export const dateTimeFormat3 = 'L LTS'

export const getDateFormat1 = moment => moment.format(dateFormat1)
export const getDateFormat2 = moment => moment.format(dateFormat2)

export const getTimeFormat1 = moment => moment.format(timeFormat1)
export const getTimeFormat2 = moment => moment.format(timeFormat2)
export const getTimeFormat3 = moment => moment.format(timeFormat3)

export const getDateTimeFormat = moment => moment.format(dateTimeFormat)
export const getDateTimeFormat2 = moment => moment.format(dateTimeFormat2)
export const getDateTimeFormat3 = moment => moment.format(dateTimeFormat3)

export const fromDateFormat1 = timestamp => moment(timestamp, dateFormat1)
export const fromDateFormat2 = timestamp => moment(timestamp, dateFormat2)

export const fromTimeFormat1 = timestamp => moment(timestamp, timeFormat1)
export const fromTimeFormat2 = timestamp => moment(timestamp, timeFormat2)

export const fromDateTimeFormat = timestamp => moment(timestamp, dateTimeFormat)
export const fromDateTimeFormat2 = timestamp => moment(timestamp, dateTimeFormat2)
export const fromDateTimeFormat3 = timestamp => moment(timestamp, dateTimeFormat3)

export const momentClamp = (start, end, cur) =>
  cur.isBefore(start) ? start : cur.isAfter(end) ? end : cur

export const getTimezone = (m) => {
  const timeZone = m.format('zz')
  const utcOffset = m.format('UTCZ')

  let displayedTimeZone
  if (/^[-+].+/.test(timeZone)) {
    displayedTimeZone = utcOffset
  } else {
    displayedTimeZone = `${timeZone} (${utcOffset})`
  }

  return displayedTimeZone
}

// после 45 секунд
export const fromNow = (mtime, t, isShort) =>
  isShort
    ? moment().unix() - mtime.unix() > 45
      ? mtime.fromNow()
      : t('TimeAgo.short.justNow')
    : mtime.fromNow()
