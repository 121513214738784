import { createContext } from "react";
import { PathNode, PathRouter } from "../../libs/paths";
import { IHashMap } from "../../utils/types";
import useMemoObject from "../../hooks/useMemoObject";

export interface IPathRouterContext {
  ErrorViewComponent: any,
  router: PathRouter<any> 
}

//@ts-ignore
export const PathRouterContext = createContext<IPathRouterContext>({ router: null })


function PathRouterProvider<T extends IHashMap<PathNode<any>>>({ 
  router,
  ErrorViewComponent,
  children 
}: { 
  router: PathRouter<T>, 
  ErrorViewComponent: any
  children: any 
}) {

  const contextValue = useMemoObject<IPathRouterContext>({
    ErrorViewComponent,
    router
  })

  return (
    <PathRouterContext.Provider value={contextValue}>
      {children}
    </PathRouterContext.Provider>
  )
}

export default PathRouterProvider
