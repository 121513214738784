import { useCallback, useRef } from 'react'
import RemoveIcon from '@mui/icons-material/Clear'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip, IconButton } from '@mui/material'
import Icon from '../widgets/Icon'

export const removeRendererColumn = {
  IconComponent: RemoveIcon,
  color: 'error'
}

export const editRendererColumn = {
  IconComponent: EditIcon,
  color: 'primary'
}

export const optionsRendererColumn = {
  IconComponent: MoreVertIcon
}

export default function ActionRenderer ({
  row,
  column: {
    size,
    action: { color, IconComponent, title, onClick, disabled }
  }
}) {
  const refButton = useRef(null)

  // правильно было бы сюда отдать event.target, но
  // по непонятным причинам он в 90% случаев несуществующий элемент
  // тупо
  const handleClick = useCallback(
    e => onClick?.(row, { target: refButton.current }, e),
    [onClick, row]
  )

  return (
    <Tooltip title={title} placement='top'>
      <span ref={refButton}>
        <IconButton
          color={color}
          size={size}
          onClick={handleClick}
          disabled={disabled}
        >
          <Icon
            Component={IconComponent}
          />
        </IconButton>
      </span>
    </Tooltip>
  )
}
