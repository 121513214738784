import {
  cloneElement,
  useEffect,
  useState,
  useMemo
} from 'react'
import get from 'lodash/get'
import useBaseTable from '../hooks/useBaseTable'
import { difference } from 'lodash'
import { useTranslation } from 'react-i18next'
import TableView from '../TableView'
import { searchByValue } from '../helpers'
import usePrevious from '../../../tools/hooks/usePrevious'
import { anyFirstLvlIsEqual } from '../../../tools/utils/helpers'

function PagedSearchTable ({
  multipleSelection,
  searchValue,
  selectOnClick,
  columns,
  data = [],
  onClick,
  onSelect,
  onSelectAll,
  onFoundChange,
  highlighted,
  topButton,
  level,
  paginationProps: outerPaginationProps,
  selectedIds: outerSelectedIds,
  rendererProps,
  doNotResetSelectIfMutateData,
  className,
  children,
  selectable: outerSelectable,
  ...tableProps
}) {
  level = level || 0

  const { t } = useTranslation()

  const [
    selectable,
    selectedIds,
    setSelected,
    handleSelect,
    handleSelectAllClick,
    clickHandler
  ] = useBaseTable(
    data,
    outerSelectedIds,
    onClick,
    outerSelectable,
    multipleSelection,
    selectOnClick,
    onSelect,
    onSelectAll
  )

  const previousData = usePrevious(data)

  const [page, setPage] = useState(get(outerPaginationProps, ['page'], 0))
  const [rowsPerPage, setRowsPerPage] = useState(
    get(outerPaginationProps, ['rowsPerPage'], 0)
  )

  const searchedData = useMemo(
    () => searchByValue(searchValue, data, columns),
    [searchValue, columns, data]
  )

  const oldSearchedDataLength = usePrevious(searchedData.length)

  const paginationProps = useMemo(
    () =>
      outerPaginationProps
        ? {
          ...outerPaginationProps,
          page: get(outerPaginationProps, ['page']) ?? page,
          rowsPerPage,
          count: searchedData.length,
          end: Math.ceil(searchedData.length / rowsPerPage),
          labelRowsPerPage:
              outerPaginationProps.labelRowsPerPage ||
              t('Table.paginated.labelRowsPerPage'),
          labelDisplayedRows:
              outerPaginationProps.labelDisplayedRows ||
              (({ from, to, count }) =>
                t('Table.paginated.labelDisplayedRows', { from, to, count })),
          onPageChange: (e, val) => {
            setPage(val)
            outerPaginationProps.onPageChange &&
                outerPaginationProps.onPageChange(val)
          },
          onRowsPerPageChange: ({ target }) => {
            setRowsPerPage(target.value)
            setPage(0)
            outerPaginationProps.onRowsPerPageChange &&
                outerPaginationProps.onRowsPerPageChange(target.value)
          }
        }
        : null,
    [
      // eslint-disable-next-line 
      get(outerPaginationProps, ['page']),
      page, 
      rowsPerPage, 
      t, 
      searchedData, 
      outerPaginationProps
    ]
  )

  const paginatedData = useMemo(() => {
    if (paginationProps) {
      const { page, rowsPerPage } = paginationProps
      const start = page * rowsPerPage
      const end = (page + 1) * rowsPerPage
      return searchedData.slice(start, end)
    }

    return searchedData
  }, [paginationProps, searchedData])

  useEffect(() => {
    if (!oldSearchedDataLength || oldSearchedDataLength === searchedData.length) {
      return
    }

    setPage(0)
    onFoundChange && onFoundChange(searchedData.length)
  }, [oldSearchedDataLength, searchedData.length, onFoundChange])

  useEffect(
    () => {
      if (
        !selectable ||
          !previousData ||
          !data ||
          data.length === 0 ||
          anyFirstLvlIsEqual(data, previousData)
      ) {
        return
      }

      let newSelected = []

      const oldIds = previousData.map(({ id }) => id)
      const newIds = data.map(({ id }) => id)
      const deletedIds = difference(oldIds, newIds)

      newSelected = difference(selectedIds, deletedIds)

      setSelected(newSelected)
    },
    [data, previousData] // eslint-disable-line
  )

  return (
    <TableView
      {...tableProps}
      columns={columns}
      data={paginatedData}
      selectedIds={selectedIds}
      rendererProps={rendererProps}
      highlighted={highlighted}
      level={level}
      className={className}
      onSelect={handleSelect}
      onSelectAll={handleSelectAllClick}
      selectable={selectable}
      multipleSelection={multipleSelection}
      onClick={clickHandler}
      paginationProps={paginationProps}
      hidePaginationControls={searchedData.length <= paginationProps?.rowsPerPageOptions?.[0]}
    >
      {topButton != null &&
        cloneElement(topButton, { colSpan: columns.length })}
      {children}
    </TableView>
  )
}

export default PagedSearchTable
