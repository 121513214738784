import { logout } from '../app/UserProvider'
import { config } from '../config'
import { DstSnRcHttp, EndpointObserver, EndpointsTree, IConfigDstSnRcHttp, IDstSnRcHttpResponse } from '../tools/libs/endpoints'
import { ApiErrorsListnerEvent } from '../ui/widgets/ApiErrorsListner'
import { AuthTokenRefreshEndpoint } from './AuthTokenRefreshEndpoint'
import { AuthUserEndpoint } from './AuthUserEndpoint'
import { CurentUserEndpoint } from './CurentUserEndpoint'
import { ReserveCreateEndpoint } from './ReserveCreateEndpoint'
import { ReserveDeleteEndpoint } from './ReserveDeleteEndpoint'
import { ReserveEditEndpoint } from './ReserveEditEndpoint'
import { ReserveEndpoint } from './ReserveEndpoint'
import { ReservesEndpoint } from './ReservesEndpoint'
import { TourArchiveEndpoint } from './TourArchiveEndpoint'
import { TourCreateEndpoint } from './TourCreateEndpoint'
import { TourDeleteEndpoint } from './TourDeleteEndpoint'
import { TourEditEndpoint } from './TourEditEndpoint'
import { TourEndpoint } from './TourEndpoint'
import { TourExcelEndpoint } from './TourExcelEndpoint'
import { TouristArchiveEndpoint } from './TouristArchiveEndpoint'
import { TouristCreateEndpoint } from './TouristCreateEndpoint'
import { TouristDeleteEndpoint } from './TouristDeleteEndpoint'
import { TouristEditEndpoint } from './TouristEditEndpoint'
import { TouristEndpoint } from './TouristEndpoint'
import { TouristToursEndpoint } from './TouristToursEndpoint'
import { TouristsEndpoint } from './TouristsEndpoint'
import { ToursEndpoint } from './ToursEndpoint'
import { UsersEndpoint } from './UsersEndpoint'

export const tokenIsExpired = ({ status }: IDstSnRcHttpResponse<any, any>) => 
  status === 426
  
export const tokenIsNovalid = ({ status }: IDstSnRcHttpResponse<any, any>) => 
  status === 401

export const getApiConfigAccessToken = () => localStorage.getItem('accessToken') 
  ? `Bearer ${localStorage.getItem('accessToken')}` 
  : undefined

if(config.isDevelopment) {
  require('./mockers')
}

let refreshTokenObserver: EndpointObserver<Response> | null = null

const refreshTokenChecker = async <
  TParams, 
  TData, 
  TError, 
  TConfig extends IConfigDstSnRcHttp<TParams, TData, TError, any, HeadersInit, IDstSnRcHttpResponse<any, any>>
>(
  response: IDstSnRcHttpResponse<TData, TError>, 
  params: TParams, 
  endpoint: DstSnRcHttp,
  localConfig?: TConfig
) => {
  if(tokenIsNovalid(response)) {
    logout()
    return response
  }

  if(
    // Если токен просрочился
    tokenIsExpired(response) &&
    // обрабатываем только если эндпоинт не тот же самый который должен их обрабатывать
    endpoint !== api.tree.auth.refreshToken
  ) {
    // Если какой-то другой запрос ранее не сделал запрос на обновление токена
    if(!refreshTokenObserver) {
      //@ts-ignore
      // параметры в этот эндпоинт не требуются, но чтобы обзервер релоадился, ибо он кэшится - добавил
      refreshTokenObserver = api.tree.auth.refreshToken.observe(localStorage.getItem('refreshToken'))
    }

    const [refreshTokenResponse] = (await refreshTokenObserver!.onUpdate.wait()) as [IDstSnRcHttpResponse<{ access: string, refresh: string }, TError>]
    const { data } = refreshTokenResponse

    refreshTokenObserver = null

    if(data) {
      localStorage.setItem('accessToken', data.access)
      localStorage.setItem('refreshToken', data.refresh)
      
      api.setConfig('DstSnRcHttp.headers.Authorization', getApiConfigAccessToken())
    }
    else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      
      api.setConfig('DstSnRcHttp.headers.Authorization', getApiConfigAccessToken())

      logout()
      return refreshTokenResponse
    }

    return await endpoint.request(params, localConfig)
  }
  else {
    return response
  }
}

const api = new EndpointsTree(
  {
    DstSnRcHttp: {
      baseUrl: '/backend/api/v0',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getApiConfigAccessToken(),
      },
      responsePostprocess: refreshTokenChecker
    }
  },
  {
    auth: {
      user: CurentUserEndpoint,
      login: AuthUserEndpoint,
      refreshToken: AuthTokenRefreshEndpoint
    },
    tourists: {
      list: TouristsEndpoint,
      create: TouristCreateEndpoint,
      edit: TouristEditEndpoint,
      one: TouristEndpoint,
      tours: TouristToursEndpoint,
      archive: TouristArchiveEndpoint,
      delete: TouristDeleteEndpoint
    },
    tours: {
      list: ToursEndpoint,
      create: TourCreateEndpoint,
      edit: TourEditEndpoint,
      one: TourEndpoint,
      excel: TourExcelEndpoint,
      archive: TourArchiveEndpoint,
      delete: TourDeleteEndpoint
    },
    reserves: {
      list: ReservesEndpoint,
      create: ReserveCreateEndpoint,
      edit: ReserveEditEndpoint,
      one: ReserveEndpoint,
      delete: ReserveDeleteEndpoint
    },
    users: {
      list: UsersEndpoint
    }
  }
)

// обработка ошибок и ситуации с обновлением токена авторизации
api.onReceive.subscribe(
  (endpoint: DstSnRcHttp, response) => {
    if(!!response.error && !tokenIsExpired(response) && !tokenIsNovalid(response)) {
      ApiErrorsListnerEvent.invoke(response)
    }
  }
)

// @ts-ignore
window.api = api

export default api
