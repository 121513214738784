import { Typography, AppBar, Toolbar, IconButton, useTheme } from '@mui/material';
import Linely from '../../tools/Components/atoms/Linely';
import Box from '../../tools/Components/atoms/Box';
import Icon from '../../ui/widgets/Icon';
import MenuIcon from '@mui/icons-material/Menu'
import LeftRight from '../../tools/Components/atoms/LeftRight';
import Time from './Time';
import UserMenu from './UserMenu';
import PathSwitch from '../../tools/Components/paths/PathSwitch';
import PathMatch from '../../tools/Components/paths/PathMatch';
import router from '../../paths';
import { useTranslation } from 'react-i18next';

function TopToolbar({ openDrawer }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <AppBar
      sx={{
        bgcolor: theme.palette.background.paper,
        outline: `${theme.palette.divider} 1px solid`
      }}
      elevation={0}
    >
      <Toolbar>
        <LeftRight>
          <Linely gap='i1'>
            <Box mx={-1}>
              <IconButton onClick={openDrawer}>
                <Icon
                  big
                  Component={MenuIcon}
                />
              </IconButton>
            </Box>
            <Typography variant="h6">
              <PathSwitch>
                <PathMatch path={router.root.c.tourists}>{t('Tourist.title')}</PathMatch>
                <PathMatch path={router.root.c.tours}>{t('Tour.title')}</PathMatch>
                <PathMatch path={router.root.c.service}>Не влезай! Убьёт!</PathMatch>
              </PathSwitch>
            </Typography>
          </Linely>
          <Linely gap='i1'>
            <Time />
            <UserMenu />
          </Linely>
        </LeftRight>
      </Toolbar>
    </AppBar>
  );
}

export default TopToolbar;
