import { autobind } from 'core-decorators'
import { TArrayable, transformArryableVar } from '../../utils/array'
import { Event as BaseEvent } from './Event'

export type TBrowserEventFilter<T> = (e: Event, thisObj: T) => boolean

@autobind
export class BrowserEvent extends BaseEvent<[Event]> {
  protected filters: TBrowserEventFilter<BrowserEvent>[]

  public constructor(
    element: HTMLElement | Window, 
    browserEventName: HTMLElementEventMap | string,
    filters?: TArrayable<TBrowserEventFilter<BrowserEvent>>
  ) {
    super()

    this.filters = transformArryableVar(filters || [])

    if (!element || !browserEventName) {
      return
    }

    // @ts-ignore
    element.addEventListener(browserEventName, this.onEvent) 
  }

  protected onEvent(e: Event) {
    if(this.filters.some(filter => !filter(e, this))) {
      return
    }

    this.invoke(e)
  }
}

export const browserEvent = (
  element: HTMLElement | Window, 
  browserEventName: HTMLElementEventMap | string,
  filters?: TArrayable<TBrowserEventFilter<BrowserEvent>>
) => new BrowserEvent(element, browserEventName, filters)
