import { forwardRef } from 'react'
import Box from './Box'

function Centered (props, ref) {
  return (
    <Box
      {...props}
      ref={ref}
      display='flex'
      justifyContent='center'
      alignItems='center'
    />
  )
}

export default forwardRef(Centered)
