import { cloneElement } from 'react'

const Childer = ({
  need,
  children,
  parrent
}) => need
  ? typeof parrent === 'function' ? parrent(children) : cloneElement(parrent, {}, [children])
  : children

export default Childer
