import { Dialog as MuiDialog, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getSmartLabel } from '../../tools/utils/locale';
import Linely from '../../tools/Components/atoms/Linely';
import Box from '../../tools/Components/atoms/Box';
import logoBackground from '../../static/images/logo-background.svg'
import FixedYBackground from './FixedYBackground';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import useOnEvent from '../../tools/hooks/event/useOnEvent';
import { browserResizeEvent } from '../../tools/libs/event';

const Dialog = ({ 
  opened,
  onClose,
  noClose,
  title,
  lTitle,
  titleEnd,
  message,
  lMessage,
  children,
  onSubmit,
  lCloseLable = 'Common.close',
  closeLable,
  lSubmitLable = 'Common.ok',
  submitLable,
  withoutBtns,
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [contentHeight, setContentHeight] = useState(0)

  const refContent = useRef()
  
  useOnEvent(
    // eslint-disable-next-line
    useMemo(() => browserResizeEvent(refContent.current), [refContent.current]),
    useCallback(
      () => {
        refContent.current && setContentHeight(refContent.current.offsetHeight)
      },
      []
    )
  )

  useEffect(
    () => {
      refContent.current && setContentHeight(refContent.current.offsetHeight)
    },
    [setContentHeight]
  )

  return (
    <MuiDialog
      scroll='body'
      {...props}
      onClose={onClose}
      open={opened}
      disableBackdropClick={!!noClose}
      disableEscapeKeyDown={!!noClose}
      PaperProps={{
        sx: { 
          width: theme.modalWidth, 
          maxWidth: theme.modalWidth,
          position: 'relative'
        }
      }}
    >
      <FixedYBackground
        position='absolute'
        top={64}
        right={0}
        bottom={0}
        left={0}
        zIndex={1}
        img={logoBackground}
        opacity={0.03}
        width={700}
        height={700}
        scale={2.374}
        rotate='5.9deg'
        translateX='0px'
        translateY={`${contentHeight/3 - 250}px`}
      />
      <Linely
        ref={refContent}
        fullLine
        vertical
        gap='i1'
        py='i1'
        position='relative'
        zIndex={2}
      >
        {(title || lTitle) && (
          <Box px='i1'>
            <Typography variant='h6'>{getSmartLabel(title, lTitle, t)}</Typography>
          </Box>
        )}
        {(message || lMessage) && (
          <Box px='i1'>
            <Typography variant='body1'>{getSmartLabel(message, lMessage, t)}</Typography>
          </Box>
        )}
        {children}
        {!withoutBtns && (
          <Linely
            end
            gap='i2'
            px='i1'
          >
            {!noClose && (
              <Button
                variant="contained"
                size='large'
                color='inherit'
                onClick={onClose}
              >
                {getSmartLabel(closeLable, lCloseLable, t)}
              </Button>
            )}
            <Button
              variant="contained"
              size='large'
              onClick={onSubmit}
            >
              {getSmartLabel(submitLable, lSubmitLable, t)}
            </Button>
          </Linely>
        )}
      </Linely>
    </MuiDialog>
  )
}

export default Dialog
