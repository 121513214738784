import Childer from "../../tools/Components/Childer";
import Centered from "../../tools/Components/atoms/Centered"
import CircularProgress from '@mui/material/CircularProgress';

const Loadable = ({ loading, noCentered, children, empty, emptyElement = null }) => loading 
  ? (
    <Childer
      need={!noCentered}
      parrent={children => (
        <Centered>
          {children}
        </Centered>
      )}
    >
      <CircularProgress
        size={30}
      />
    </Childer>
  )
  : empty
    ? emptyElement
    : (typeof children === 'function' ? children() : children || null)

export default Loadable
