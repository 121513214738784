import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import { Typography } from '@mui/material'
import Linely from '../../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { SortableTable } from '../../../ui/tables';
import { useMemo, useState, useCallback } from 'react'
import Box from '../../../tools/Components/atoms/Box';
import FilledSearch from '../../../ui/widgets/FilledSearch';
import api from '../../../endpoints';
import usePathRouter from '../../../tools/hooks/paths/usePathRouter';
import LeftRight from '../../../tools/Components/atoms/LeftRight';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { TouristPhoneRenderer, touristPhoneSearcher } from '../../../ui/tableRenderers/TouristPhoneRenderer';
import { TouristSexRenderer } from '../../../ui/tableRenderers/TouristSexRenderer';
import { TouristSexFilter } from '../../../ui/TableFilters/TouristSexFilter';
import { TouristBirthdayRenderer } from '../../../ui/tableRenderers/TouristBirthdayRenderer';
import { TouristFioRenderer, touristFioSearcher } from '../../../ui/tableRenderers/TouristFioRenderer';
import { TouristStatusFilter } from '../../../ui/TableFilters/TouristStatusFilter';
import { local, remove } from '../../../tools/libs/paths/helpers';
import Icon from '../../../ui/widgets/Icon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Options from '../../../ui/widgets/Options';
import PersonIcon from '@mui/icons-material/Person';
import ActionRenderer, { optionsRendererColumn } from '../../../ui/tableRenderers/ActionRenderer';
import { useModal } from '../../../hooks/useModal';
import { usePercentWidth } from '../../../hooks/usePercentWidth';
import Loadable from '../../../ui/widgets/Loadable';
import SortableTableCleareAll from '../../../ui/tables/SortableTable/SortableTableCleareAll';
import { TourCommentRenderer } from '../../../ui/tableRenderers/TourCommentRenderer';
import {
  sortedIsAsc, 
  userToFullFio 
} from '../../../utils/helpers';
import { tsToMomentMoscow } from '../../../utils/moment';
import { merge } from 'lodash';
import ChairIcon from '@mui/icons-material/Chair';
import CancelToReserveModal from './Modals/CancelToReserveModal';
import DeleteCancel from './Modals/DeleteCancel';
import { CancelStatusRenderer } from '../../../ui/tableRenderers/CancelStatusRenderer';
import moment from 'moment';


const CancelsTablePage = () => {
  const { t } = useTranslation()
  
  const [optionsElement, setOptionsElement] = useState()

  const [router] = usePathRouter()

  const [{ 
    tourId 
  }] = usePathParams(router.root.c.tours.c.one)
  const [{ touristId }] = usePathParams(router.root.c.tours.c.one.c.cancels.c.one)

  const [{ cSearch: { value: cSearch } }] = merge(usePathParams(router.root.c.tours.c.one.c.cancels.c.cSearch.c.value), [{ cSearch: {} }])
  const [{ cSorted: { value: cSorted } }] = merge(usePathParams(router.root.c.tours.c.one.c.cancels.c.cSorted.c.value), [{ cSorted: {} }])
  const [{ cSex: { value: filtecSex } }] = merge(usePathParams(router.root.c.tours.c.one.c.cancels.c.cSex.c.value), [{ cSex: {} }])
  const [{ cTouristStatus: { value: cTouristStatus } }] = merge(usePathParams(router.root.c.tours.c.one.c.cancels.c.cTouristStatus.c.value), [{ cTouristStatus: {} }])
  const [{ cPage: { value: cPage } }] = merge(usePathParams(router.root.c.tours.c.one.c.cancels.c.cPage.c.value), [{ cPage: {} }])

  const searchValue = (cSearch ?? '').toString()

  const pageWidth = usePercentWidth()
  
  const [moveToReserveModal, moveToReserveModalOpen] = useModal(CancelToReserveModal)
  const [deletedCancelModal, deletedCancelOpen] = useModal(DeleteCancel)

  const [{ data: tour, isLoading }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: tourTourists }] = useEnObserve(api.tree.reserves.list, tourId)

  const tourCancels = useMemo(
    () => (tourTourists || [])
      .filter(({ status_payment }) => ['cancellation', 'transfer'].indexOf(status_payment) > -1)
      .sort((a, b) => a.booking_date < b.booking_date ? 1 : -1)
      .map((item, index) => ({ ...item, tourist: { ...item.tourist, index: index + 1 } })),
    [tourTourists]
  )

  const sorter = useMemo(
    () => {
      const [name, ascDesc] = cSorted?.split(',') || []

      return {
        name,
        asc: ascDesc === 'asc'
      }
    },
    [cSorted]
  )

  const filtecTouristStatus = useMemo(
    () => (cTouristStatus && typeof cTouristStatus !== 'boolean') ? cTouristStatus.toString().split(',') : [],
    [cTouristStatus]
  )
  
  const filteredCancels = useMemo(
    () => (tourCancels || []).filter(
      (cancel) => 
        (
          (!filtecTouristStatus || filtecTouristStatus.length === 0) ||
          (filtecTouristStatus.indexOf('normal') > -1 && cancel.tourist.status === 'normal') || 
          (filtecTouristStatus.indexOf('dangerZone') > -1 && cancel.tourist.status === 'danger_zone') || 
          (filtecTouristStatus.indexOf('blocked') > -1 && cancel.tourist.status === 'blocked')
        ) &&
        (
          filtecSex == null ||
          (filtecSex === 'm' && cancel.tourist.sex === 'М') ||
          (filtecSex !== 'm' && cancel.tourist.sex !== 'М')
        )
    ),
    [
      tourCancels, 
      filtecSex, 
      filtecTouristStatus, 
    ]
  )
  
  const sortedCancels = useMemo(
    () => [
      ...filteredCancels.sort((a, b) => {
        if(sorter.name === 'fio') {
          return sortedIsAsc(sorter.asc, userToFullFio(a.tourist) > userToFullFio(b.tourist))
        }
        else if(sorter.name === 'birthday') {
          const nowTs = moment().unix()
  
          return sortedIsAsc(sorter.asc, (a.tourist.birthday || nowTs) < (b.tourist.birthday || nowTs))
        }

        return tsToMomentMoscow(a.booking_date).isBefore(tsToMomentMoscow(b.booking_date)) ? 1 : -1
      })
    ],
    [filteredCancels, sorter]
  )

  const onSetSearchValue = useCallback(
    (search) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.cancels.c.cSearch.c.value, search ? local({ value: search }) : remove()],
      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetSorter = useCallback(
    ({ name, asc }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.cancels.c.cSorted.c.value, local({ value: `${name},${asc ? 'asc' : 'desc'}` })],
      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFiltecSex = useCallback(
    (sex) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.cancels.c.cSex.c.value, sex ? local({ value: sex }) : remove()],
      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, remove()],
    ])),
    [router]
  )


  const onSetFiltecTouristStatus = useCallback(
    (touristStatus) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.cancels.c.cTouristStatus.c.value, touristStatus?.length > 0 ? local({ value: touristStatus.join(',') }) : remove()],
      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, remove()],
    ])),
    [router]
  )

  const cancelOptions = useCallback(
    ({ id_tour, tourist: { id } }) => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Cancel.editModal.title'),
        href: router.getUrl([
          [router.root.c.editCancel.c.tour.c.tourist, local({ touristId: id, tourId: id_tour })]
        ])
      },
      {
        icon: (<Icon Component={PersonIcon} />),
        text: t('Tourist.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: id })]
        ])
      },
      {
        icon: (<Icon Component={ChairIcon} color='error' />),
        text: t('Cancel.moveToReserve'),
        color: 'error',
        onClick: () => moveToReserveModalOpen({ tourId: id_tour, touristId: id })
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Cancel.delete'),
        color: 'error',
        onClick: () => deletedCancelOpen({ tourId: id_tour, touristId: id })
      },
    ],
    [t, router, moveToReserveModalOpen, deletedCancelOpen]
  )
  
  const onClearAllFilters = useCallback(
    () => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.one.c.cancels.c.cSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.cancels.c.cSex.c.value, remove()],

      [router.root.c.tours.c.one.c.cancels.c.cSorted.c.value, remove()],

      [router.root.c.tours.c.one.c.cancels.c.cAgeStart.c.value, remove()],
      [router.root.c.tours.c.one.c.cancels.c.cAgeEnd.c.value, remove()],

      [router.root.c.tours.c.one.c.cancels.c.cTouristStatus.c.value, remove()],

      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, remove()],
    ])),
    [router]
  )
  
  const columns = useMemo(
    () => [
      {
        id: 'tourist',
        sorter: 'fio',
        label: t('Common.fio'),
        filter: 'status',
        index: true,
        filterComponent: TouristStatusFilter,
        filterIsActive: filtecTouristStatus?.length > 0,
        filterComponentProps: {
          filterState: filtecTouristStatus,
          onChange: onSetFiltecTouristStatus,
        },
        search: touristFioSearcher,
        renderer: TouristFioRenderer,
        maxWidth: pageWidth * 0.166,
      },
      {
        id: 'tourist',
        sorter: 'birthday',
        label: t('Common.birthday'),
        renderer: TouristBirthdayRenderer
      },
      {
        id: 'tourist',
        filter: 'sex',
        filterComponent: TouristSexFilter,
        filterIsActive: filtecSex != null,
        filterComponentProps: {
          filterState: filtecSex,
          onChange: onSetFiltecSex,
        },
        label: t('Common.sex'),
        renderer: TouristSexRenderer
      },
      {
        id: 'tourist',
        label: t('Common.phone'),
        search: touristPhoneSearcher,
        renderer: TouristPhoneRenderer
      },
      {
        id: '',
        label: t('Cancel.status.label'),
        renderer: CancelStatusRenderer
      },
      {
        id: '',
        label: t('Tour.tourists.cancel.comment'),
        tourPrice: tour?.price,
        renderer: TourCommentRenderer
      },
      {
        id: '',
        noClick: true,
        columnView: () => (
          <SortableTableCleareAll
            canClear={
              filtecTouristStatus?.length > 0 ||
              filtecSex != null ||
              !!cSorted
            }
            onClear={onClearAllFilters}
          />
        ),
        action: { 
          ...optionsRendererColumn, 
          onClick: (tourist, e) => {
            setOptionsElement(
              <Options
                opened
                onClose={() => setOptionsElement(null)}
                options={cancelOptions(tourist)}
                anchorEl={e.target}
              />
            )
          }
        },
        renderer: ActionRenderer
      },
    ],
    [
      t, 
      onSetFiltecSex,
      onSetFiltecTouristStatus,
      onClearAllFilters,
      pageWidth, 
      tour, 
      cancelOptions,
      filtecSex,
      filtecTouristStatus,
      cSorted
    ]
  )

  const href = useCallback(
    ({ tourist: { id } }) => router.root.c.tours.c.one.c.cancels.c.one.getUrl(
      {
        touristId: id
      }
    ),
    [
      router,
    ]
  )

  const checkIsHighlighted = useCallback(
    ({ tourist: { id } }) => id === touristId,
    [touristId]
  )

  return (
    <>
      {moveToReserveModal}
      {deletedCancelModal}
      {optionsElement}
      <Loadable
        loading={!tourTourists && isLoading}
        empty={!tour || !tourTourists}
      >
        {() => (
          <Linely
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely
                gap={0}
                vertical
              >
                <Typography variant='h6'>
                  {t('Tour.tourists.cancels.title')}
                </Typography>
                <Typography color='textSecondary'>
                  <Trans
                    i18nKey='Tour.tourists.cancellation'
                    values={{ count: tourCancels.length }}
                    components={[
                      <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                    ]}
                  />
                </Typography>
              </Linely>
            </LeftRight>
            <FilledSearch
              value={searchValue}
              onChange={onSetSearchValue}
            />
            <Box mx='-i1'>
              <SortableTable
                highlighted={checkIsHighlighted}
                searchValue={searchValue}
                columns={columns}
                data={sortedCancels}
                href={href}
                sorter={sorter}
                onSetSorter={onSetSorter}
                paginationProps={{
                  rowsPerPage: 20,
                  page: cPage ? cPage -1 : 0,
                  onPageChange: (newPage) => 
                    router.pushUrl(router.getUrl([
                      [router.root.c.tours.c.one.c.cancels.c.cPage.c.value, local({ value: newPage + 1 })],
                    ]))
                }}
              />
            </Box>
          </Linely>
        )}
      </Loadable>
    </>
  )
}

export default CancelsTablePage
