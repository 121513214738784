import { Typography } from '@mui/material'
import { 
  priceToStr, 
} from '../../utils/helpers';

export const TourPriceRenderer = ({ value: tour }) => (
  <Typography variant='body1'>
    {priceToStr(tour.price)}
  </Typography>
)
