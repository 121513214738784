import { Typography } from '@mui/material';
import Linely from "../../tools/Components/atoms/Linely"
import LeftRight from "../../tools/Components/atoms/LeftRight";
import Box from "../../tools/Components/atoms/Box";
import Icon from "./Icon";
import { DASH_LINE } from "../../tools/utils/string";
import { useTranslation } from "react-i18next";
import { getPersonYears, tsToMomentMoscow } from "../../utils/moment";
import { getDateFormat1 } from "../../tools/utils/moment";
import { getTouristStatusLocaleKey, parsePhone } from "../../utils/helpers";
import TouristStatus from "./TouristStatus";
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import { countries_map } from "../../utils/countries";

export const TouristInfo = ({ tourist }) => {
  const { t } = useTranslation()
  
  const countryCitizenship = countries_map[tourist.citizenship]

  return (
    <Linely
      vertical
      gap='i4'
    >
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.surname')}</Typography>
        <Typography>{tourist.surname || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.firstName')}</Typography>
        <Typography>{tourist.first_name || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.secondName')}</Typography>
        <Typography>{tourist.second_name || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.status')}</Typography>
        <Linely>
          <TouristStatus status={tourist.status} />
          <Typography>{t(getTouristStatusLocaleKey(tourist.status))}</Typography>
        </Linely>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.birthday')}</Typography>
        <Typography>{
          tourist.birthday
            ? `${getDateFormat1(tsToMomentMoscow(tourist.birthday))} (${t('Common.personAge', { count: getPersonYears(tsToMomentMoscow(tourist.birthday)) })})`
            : DASH_LINE
        }</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.sex')}</Typography>
        {tourist.sex
          ? (
            <Linely>
              <Icon
                small
                Component={tourist.sex === 'М' ? FaceIcon : Face2Icon}
              />
              <Typography>
                {t(tourist.sex === 'М' ? 'Common.sex.man' : 'Common.sex.women')}
              </Typography>
            </Linely>
          )
          : (
            <Typography>
              {DASH_LINE}
            </Typography>
          )
        }
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.phone')}</Typography>
        <Typography>{parsePhone(tourist.phone)}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.info')}</Typography>
        <Typography
          align='right'
          style={{
            maxWidth: '70%',
          }}
        >
          {tourist.comment || DASH_LINE}
        </Typography>
      </LeftRight>
      <Box
        py='i3'
      >
        <Typography
          variant='subtitle1'
        >
          {t('Tourist.contacts')}
        </Typography>
      </Box>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.telegram')}</Typography>
        <Typography>{tourist.telegram || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.instagram')}</Typography>
        <Typography>{tourist.instagram || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.email')}</Typography>
        <Typography>{tourist.email || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Common.vk')}</Typography>
        <Typography>{tourist.vk || DASH_LINE}</Typography>
      </LeftRight>
      <Box
        py='i3'
      >
        <Typography
          variant='subtitle1'
        >
          {t('Tourist.passport.title')}
        </Typography>
      </Box>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.citizenship.label')}</Typography>
        {countryCitizenship
          ? (
            <Linely>
              <img alt={countryCitizenship.code} src={countryCitizenship.icon} />
              <Typography>
                {countryCitizenship.name}
              </Typography>
            </Linely>
          )
          : (
            <Typography>{tourist.citizenship || DASH_LINE}</Typography>
          )
        }
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.passport.label')}</Typography>
        <Typography>{tourist.passport || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.passport_international.label')}</Typography>
        <Typography>{tourist.passport_international || DASH_LINE}</Typography>
      </LeftRight>
      <LeftRight>
        <Typography color='textSecondary'>{t('Tourist.passport_info.label')}</Typography>
        <Typography
          align='right'
          style={{
            maxWidth: '70%',
          }}
        >
          {tourist.passport_info || DASH_LINE}
        </Typography>
      </LeftRight>
    </Linely>
  )
}
