import { useInsertionEffect } from 'react'
import { CrossPageEvent, Event, TEventCb } from '../../libs/event'

function useOnEventBefore<Args extends Array<any>>(
  event: Event<Args>,
  cb: TEventCb<Args>
): void

function useOnEventBefore<Args extends Array<any>>(
  event: CrossPageEvent<Args>,
  cb: TEventCb<[Args, boolean]>
): void

function useOnEventBefore<Args extends Array<any>>(
  event?: Event<Args>,
  cb?: TEventCb<Args>
) {
  useInsertionEffect(
    () => {
      event && cb && event.subscribe(cb)
      return () => { event && cb && event.unsubscribe(cb) }
    }, 
    [event, cb]
  )
}

export default useOnEventBefore
