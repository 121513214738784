import { useContext } from "react"
import { PathCaseContext } from "../../Components/paths/PathCase"
import { PathNode } from "../../libs/paths"
import { assert } from "../../utils/assert"
import { PathRouterComponentPathTypeError } from "./errors"
import usePathRouter from "./usePathRouter"
import { parseQueryParamValue } from "../../libs/paths/helpers"

const usePathParams = (path?: PathNode<any>, isLocal?: boolean) => {
  usePathRouter(path?.router, 'usePathParams')

  const { currentPath } = useContext(PathCaseContext)

  const urlParams = (() => {
    const urlParamsObj = new URLSearchParams(window.location.search)

    const urlParams = {}

    //@ts-ignore
    for (const [key, val] of urlParamsObj.entries()) {
      //@ts-ignore
      urlParams[key] = parseQueryParamValue(val)
    }

    return urlParams
  })()
  
  const hash = window.location.hash.slice(1)

  assert(
    (path || currentPath) instanceof PathNode, 
    new PathRouterComponentPathTypeError('usePathParams', typeof (path || currentPath))
  )

  return [
    ((path || currentPath) as PathNode<any>)[isLocal ? 'localParams' : 'params'],
    urlParams,
    hash,
  ]
}

export default usePathParams
