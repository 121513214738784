import { useCallback } from "react"
import { PathNode, PathRouter } from "../../libs/paths"
import { IHashMap } from "../../utils/types"
import usePathRouter from "./usePathRouter"

const usePathLink = <T extends IHashMap<PathNode<any>>>(
    href: string, 
    onClick?: (e: Event) => boolean | void, 
    target?: string,
    outRouter?: PathRouter<T>
) => {
  const [router] = usePathRouter(outRouter, 'usePathMatch')

  usePathRouter()

    return useCallback(
      (e: Event) => {
        if(typeof onClick === 'function') {
          onClick(e)
        }
  
        if (typeof href !== 'string' || target === '_blank' || target === 'blank') {
          return
        }
  
        router.pushUrl(href)
  
        e.stopPropagation()
        e.preventDefault()
        return false
      },
      [href, router, onClick, target]
    )
}

export default usePathLink
