import { useTranslation } from 'react-i18next';
import { getTourStatus, getTourStatusColor, getTourStatusLocaleKey } from '../../utils/helpers';
import StatusSmallIcon from './StatusSmallIcon';
import { Tooltip } from '@mui/material'

const TourStatus = ({ tour, noTooltip, ...props }) => {
  const { t } = useTranslation()
  const tourStatus = getTourStatus(tour)

  return (
    <Tooltip
      title={noTooltip ? '' : t(getTourStatusLocaleKey(tourStatus))}
    >
      <StatusSmallIcon
        {...props}
        color={getTourStatusColor(tourStatus)}
      />
    </Tooltip>
  )
}

export default TourStatus
