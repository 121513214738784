import { List, ListItemButton, Popover, Radio, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react'

export const TourTotalFilter = ({
  onClose,
  anchorEl,
  filterState,
  onChange,
  opened,
}) => {
  const { t } = useTranslation()

  const setFilterValue = useCallback(
    newValue => () => onChange(filterState === newValue ? null : newValue),
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItemButton
          onClick={setFilterValue('full')}
        >
          <Radio
            color='primary'
            checked={filterState === 'full'}
          />
          <Typography>
            {t('Tour.totalFilter.full')}
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={setFilterValue('persent60')}
        >
          <Radio
            color='primary'
            checked={filterState === 'persent60'}
          />
          <Typography>
            {t('Tour.totalFilter.persent60')}
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={setFilterValue('persent50')}
        >
          <Radio
            color='primary'
            checked={filterState === 'persent50'}
          />
          <Typography>
            {t('Tour.totalFilter.persent50')}
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={setFilterValue('persent40')}
        >
          <Radio
            color='primary'
            checked={filterState === 'persent40'}
          />
          <Typography>
            {t('Tour.totalFilter.persent40')}
          </Typography>
        </ListItemButton>
      </List>
    </Popover>
  )
}
