import { Typography, Tooltip } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import {
  getCancelStatusIsValid,
  getCancelStatusLocaleKey,
} from '../../utils/helpers';
import { DASH_LINE } from '../../tools/utils/string';
import { usePercentWidth } from '../../hooks/usePercentWidth';
import { getScreenWidthValue } from '../widgets/ThemeProvider';
import CancelStatus from '../widgets/CancelStatus';

export const CancelStatusRenderer = ({ value: cancel }) => {
  const { t } = useTranslation()

  const isXl = getScreenWidthValue(usePercentWidth())


  return !getCancelStatusIsValid(cancel.status_payment)
    ? (
      <Typography>
        {DASH_LINE}
      </Typography>
    )
    : (
      <Linely centered>
        <Tooltip
          title={t(getCancelStatusLocaleKey(cancel.status_payment))}
        >
          <CancelStatus
            status={cancel.status_payment}
          />
        </Tooltip>
        {isXl && (
          <Typography>
            {t(getCancelStatusLocaleKey(cancel.status_payment))}
          </Typography>
        )}
      </Linely>
    )
}
