import usePathLink from "../../hooks/paths/usePathLink";
import { mbFunctionalCloneElement } from "../../utils/react";

export interface IPathLink {
  href: string,
  children: any,
  target?: string,
  onClick?: (event: Event) => boolean | void
}

function PathLink({ 
  href, 
  target, 
  onClick,
  children
}: IPathLink) {
  return mbFunctionalCloneElement(
    children, 
    { 
      href, 
      target, 
      onClick: usePathLink(href, onClick, target) 
    }
  )
}

export default PathLink
