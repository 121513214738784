import CenterPanel from '../../ui/widgets/CenterPanel';
import useEnObserve from '../../tools/hooks/endpoints/useEnObserve';
import { Typography, Badge } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { SortableTable } from '../../ui/tables';
import { useMemo, useState, useCallback, useDeferredValue } from 'react'
import FaceIcon from '@mui/icons-material/Face';
import Face2Icon from '@mui/icons-material/Face2';
import Box from '../../tools/Components/atoms/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilledSearch from '../../ui/widgets/FilledSearch';
import api from '../../endpoints';
import usePathRouter from '../../tools/hooks/paths/usePathRouter';
import { getPersonYears, tsToMomentMoscow } from '../../utils/moment';
import AddIcon from '@mui/icons-material/Add'
import { getDateFormat1 } from '../../tools/utils/moment';
import LeftRight from '../../tools/Components/atoms/LeftRight';
import CircleButton from '../../ui/widgets/CircleButton';
import PathLink from '../../tools/Components/paths/PathLink';
import {
  connectToElementsPhrasesByRussian,
  sortedIsAsc,
  userToFullFio
} from '../../utils/helpers';
import ActionRenderer, { optionsRendererColumn } from '../../ui/tableRenderers/ActionRenderer';
import Options from '../../ui/widgets/Options';
import Icon from '../../ui/widgets/Icon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import { local, remove } from '../../tools/libs/paths/helpers';
import usePathParams from '../../tools/hooks/paths/usePathParams';
import ArchiveTourist from './Modals/ArchiveTourist';
import DeleteTourist from './Modals/DeleteTourist';
import { useModal } from '../../hooks/useModal';
import { usePercentWidth } from '../../hooks/usePercentWidth';
import moment from 'moment';
import ShowOnEvent from '../../tools/Components/event/ShowOnEvent';
import Loadable from '../../ui/widgets/Loadable';
import { merge } from 'lodash';
import SortableTableCleareAll from '../../ui/tables/SortableTable/SortableTableCleareAll';
import { TouristCommentRenderer } from '../../ui/tableRenderers/TouristCommentRenderer';
import { TouristSocialNetworksRenderer, touristSocialNetworksSearcher } from '../../ui/tableRenderers/TouristSocialNetworksRenderer';
import { TouristPhoneRenderer, touristPhoneSearcher } from '../../ui/tableRenderers/TouristPhoneRenderer';
import { TouristSexRenderer } from '../../ui/tableRenderers/TouristSexRenderer';
import { TouristSexFilter } from '../../ui/TableFilters/TouristSexFilter';
import { TouristBirthdayRenderer, touristBirthdaySearcher } from '../../ui/tableRenderers/TouristBirthdayRenderer';
import { TouristBirthdayFilter } from '../../ui/TableFilters/TouristBirthdayFilter';
import { TouristFioRenderer, touristFioSearcher } from '../../ui/tableRenderers/TouristFioRenderer';
import { TouristStatusFilter } from '../../ui/TableFilters/TouristStatusFilter';
import { TouristArchiveFilter } from '../../ui/TableFilters/TouristArchiveFilter';

const TouristsList = () => {
  const { t } = useTranslation()

  const [router] = usePathRouter()
  const [{ 
    touristId 
  }] = usePathParams(router.root.c.tourists.c.one)
  
  const [{ tsSearch: { value: tsSearch } }] = merge(usePathParams(router.root.c.tourists.c.tsSearch.c.value), [{ tsSearch: {} }])
  const [{ tsSorted: { value: tsSorted } }] = merge(usePathParams(router.root.c.tourists.c.tsSorted.c.value), [{ tsSorted: {} }])
  const [{ tsSex: { value: filterSex } }] = merge(usePathParams(router.root.c.tourists.c.tsSex.c.value), [{ tsSex: {} }])
  const [{ tsBirthdayBy: { value: tsBirthdayBy } }] = merge(usePathParams(router.root.c.tourists.c.tsBirthdayBy.c.value), [{ tsBirthdayBy: {} }])
  const [{ tsAgeStart: { value: tsAgeStart } }] = merge(usePathParams(router.root.c.tourists.c.tsAgeStart.c.value), [{ tsAgeStart: {} }])
  const [{ tsAgeEnd: { value: tsAgeEnd } }] = merge(usePathParams(router.root.c.tourists.c.tsAgeEnd.c.value), [{ tsAgeEnd: {} }])
  const [{ tsBirthdayStart: { value: tsBirthdayStart } }] = merge(usePathParams(router.root.c.tourists.c.tsBirthdayStart.c.value), [{ tsBirthdayStart: {} }])
  const [{ tsBirthdayEnd: { value: tsBirthdayEnd } }] = merge(usePathParams(router.root.c.tourists.c.tsBirthdayEnd.c.value), [{ tsBirthdayEnd: {} }])
  const [{ tsBirthdayMonthStart: { value: tsBirthdayMonthStart } }] = merge(usePathParams(router.root.c.tourists.c.tsBirthdayMonthStart.c.value), [{ tsBirthdayMonthStart: {} }])
  const [{ tsBirthdayMonthEnd: { value: tsBirthdayMonthEnd } }] = merge(usePathParams(router.root.c.tourists.c.tsBirthdayMonthEnd.c.value), [{ tsBirthdayMonthEnd: {} }])
  const [{ tsTouristStatus: { value: tsTouristStatus } }] = merge(usePathParams(router.root.c.tourists.c.tsTouristStatus.c.value), [{ tsTouristStatus: {} }])
  const [{ tsArchive: { value: filterArchive } }] = merge(usePathParams(router.root.c.tourists.c.tsArchive.c.value), [{ tsArchive: {} }])
  const [{ tsPage: { value: tsPage } }] = merge(usePathParams(router.root.c.tourists.c.tsPage.c.value), [{ tsPage: {} }])

  const searchValue = (tsSearch ?? '').toString()

  const deferredSearchValue = useDeferredValue(searchValue)

  const [optionsElement, setOptionsElement] = useState()
  
  const [deleteTouristModal, deleteTouristOpen] = useModal(DeleteTourist)
  const [archiveTouristModal, archiveTouristOpen] = useModal(ArchiveTourist)

  const pageWidth = usePercentWidth()
  
  const [{ data, isLoading }] = useEnObserve(api.tree.tourists.list)

  const tourists = useMemo(
    () => (data || [])
      .map(tourist => {
        const birthdayMoment = tourist.birthday && tsToMomentMoscow(tourist.birthday)

        // чтоб поиск, фильтры и сортировки не лагали
        return {
          ...tourist,
          birthdayMoment,
          birthdayMomentFormated: tourist.birthday && 
            `${getDateFormat1(tsToMomentMoscow(tourist.birthday))} (${t('Common.personAge', { count: getPersonYears(tsToMomentMoscow(tourist.birthday)) })})`,
        }
      }),
    [t, data]
  )

  const totalTourists = (tourists || []).length

  const blackListTourists = useMemo(
    () => (tourists || [])
      .filter(({ status }) => status === 'blocked')
      .length,
    [tourists]
  )

  const dangerZoneTourists = useMemo(
    () => (tourists || [])
      .filter(({ status }) => status === 'danger_zone')
      .length,
    [tourists]
  )

  const archiveTourists = useMemo(
    () => (tourists || [])
      .filter(({ archived }) => archived)
      .length,
    [tourists]
  )

  const mansTourists = useMemo(
    () => (tourists || [])
      .filter(({ sex }) => sex === 'М')
      .length,
    [tourists]
  )

  const womansReserves = useMemo(
    () => (tourists || [])
    .filter(({ sex }) => sex !== 'М')
      .length,
    [tourists]
  )

  const sorter = useMemo(
    () => {
      const [name, ascDesc] = tsSorted?.split(',') || []

      return {
        name,
        asc: ascDesc === 'asc'
      }
    },
    [tsSorted]
  )

  const filterBirthday = useMemo(
    () => ({
      by: tsBirthdayBy,
      ageStart: tsAgeStart,
      ageEnd: tsAgeEnd,
      birthdayStart: tsBirthdayStart ? moment.unix(tsBirthdayStart) : null,
      birthdayEnd: tsBirthdayEnd ? moment.unix(tsBirthdayEnd) : null,
      birthdayMonthStart: tsBirthdayMonthStart ? moment.unix(tsBirthdayMonthStart) : null,
      birthdayMonthEnd: tsBirthdayMonthEnd ? moment.unix(tsBirthdayMonthEnd) : null,
    }),
    [
      tsBirthdayBy,
      tsAgeStart,
      tsAgeEnd,
      tsBirthdayStart,
      tsBirthdayEnd,
      tsBirthdayMonthStart,
      tsBirthdayMonthEnd,
    ]
  )

  const filterTouristStatus = useMemo(
    () => (tsTouristStatus && typeof tsTouristStatus !== 'boolean') ? tsTouristStatus.toString().split(',') : [],
    [tsTouristStatus]
  )

  const filteredTourists = useMemo(
    () => tourists.filter(
      tourist => 
        (
          (!!filterArchive === tourist.archived)
        ) &&
        (
          (!filterTouristStatus || filterTouristStatus.length === 0) ||
          (filterTouristStatus.indexOf('normal') > -1 && tourist.status === 'normal') || 
          (filterTouristStatus.indexOf('dangerZone') > -1 && tourist.status === 'danger_zone') || 
          (filterTouristStatus.indexOf('blocked') > -1 && tourist.status === 'blocked')
        ) &&
        (
          // Фильтр должен быть выбран
          !filterBirthday.by || 
          (
            filterBirthday.by === 'age' && 
            (!filterBirthday.ageStart || (tourist.birthday && filterBirthday.ageStart <= getPersonYears(tsToMomentMoscow(tourist.birthday)))) &&
            (!filterBirthday.ageEnd || (tourist.birthday && filterBirthday.ageEnd >= getPersonYears(tsToMomentMoscow(tourist.birthday))))
          ) ||
          (
            filterBirthday.by === 'birthday' && 
            (!filterBirthday.birthdayStart || (tourist.birthday && filterBirthday.birthdayStart.unix() <= tourist.birthday)) &&
            (!filterBirthday.birthdayEnd || (tourist.birthday && filterBirthday.birthdayEnd.unix() >= tourist.birthday))
          ) ||
          (
            filterBirthday.by === 'birthdayMonth' && 
            (!filterBirthday.birthdayMonthStart || (tourist.birthday && filterBirthday.birthdayMonthStart.month() <= tsToMomentMoscow(tourist.birthday).month())) &&
            (!filterBirthday.birthdayMonthEnd || (tourist.birthday && filterBirthday.birthdayMonthEnd.month() >= tsToMomentMoscow(tourist.birthday).month()))
          )
        ) &&
        (
          filterSex == null ||
          (filterSex === 'm' && tourist.sex === 'М') ||
          (filterSex !== 'm' && tourist.sex !== 'М')
        )
    ),
    [
      tourists, 
      filterSex, 
      filterTouristStatus, 
      filterBirthday,
      filterArchive
    ]
  )

  const sortedTourists = useMemo(
    () => [
      ...filteredTourists.sort((a, b) => {
        if(sorter.name === 'fio') {
          return sortedIsAsc(sorter.asc, userToFullFio(a) > userToFullFio(b))
        }
        else if(sorter.name === 'birthday') {
          const nowTs = moment().unix()
  
          return sortedIsAsc(sorter.asc, (a.birthday || nowTs) < (b.birthday || nowTs))
        }
  
        return userToFullFio(a) > userToFullFio(b) ? 1 : -1
      })
    ],
    [filteredTourists, sorter]
  )

  const onSetSearchValue = useCallback(
    (search) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsSearch.c.value, search ? local({ value: search }) : remove()],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetSorter = useCallback(
    ({ name, asc }) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsSorted.c.value, local({ value: `${name},${asc ? 'asc' : 'desc'}` })],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterSex = useCallback(
    (sex) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsSex.c.value, sex ? local({ value: sex }) : remove()],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterBirthday = useCallback(
    ({
      by,
      ageStart,
      ageEnd,
      birthdayStart,
      birthdayEnd,
      birthdayMonthStart,
      birthdayMonthEnd,
    }) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsBirthdayBy.c.value, by ? local({ value: by }) : remove()],
      [router.root.c.tourists.c.tsAgeStart.c.value, ageStart ? local({ value: ageStart }) : remove()],
      [router.root.c.tourists.c.tsAgeEnd.c.value, ageEnd ? local({ value: ageEnd }) : remove()],
      [router.root.c.tourists.c.tsBirthdayStart.c.value, birthdayStart?.isValid() ? local({ value: birthdayStart?.unix() }) : remove()],
      [router.root.c.tourists.c.tsBirthdayEnd.c.value, birthdayEnd?.isValid() ? local({ value: birthdayEnd?.unix() }) : remove()],
      [router.root.c.tourists.c.tsBirthdayMonthStart.c.value, birthdayMonthStart?.isValid() ? local({ value: birthdayMonthStart?.unix() }) : remove()],
      [router.root.c.tourists.c.tsBirthdayMonthEnd.c.value, birthdayMonthEnd?.isValid() ? local({ value: birthdayMonthEnd?.unix() }) : remove()],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterTouristStatus = useCallback(
    (touristStatus) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsTouristStatus.c.value, touristStatus?.length > 0 ? local({ value: touristStatus.join(',') }) : remove()],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterArchive = useCallback(
    (archive) => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsArchive.c.value, archive ? local({ value: archive }) : remove()],
      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )
  
  const onClearAllFilters = useCallback(
    () => router.pushUrl(router.getUrl([
      [router.root.c.tourists.c.tsSorted.c.value, remove()],

      [router.root.c.tourists.c.tsSex.c.value, remove()],

      [router.root.c.tourists.c.tsBirthdayBy.c.value, remove()],
      [router.root.c.tourists.c.tsAgeStart.c.value, remove()],
      [router.root.c.tourists.c.tsAgeEnd.c.value, remove()],
      [router.root.c.tourists.c.tsBirthdayStart.c.value, remove()],
      [router.root.c.tourists.c.tsBirthdayEnd.c.value, remove()],
      [router.root.c.tourists.c.tsBirthdayMonthStart.c.value, remove()],
      [router.root.c.tourists.c.tsBirthdayMonthEnd.c.value, remove()],

      [router.root.c.tourists.c.tsTouristStatus.c.value, remove()],

      [router.root.c.tourists.c.tsArchive.c.value, remove()],

      [router.root.c.tourists.c.tsPage.c.value, remove()],
    ])),
    [router]
  )

  const userOptions = useCallback(
    ({ id }) => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Common.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: id })]
        ])
      },
      {
        icon: (<Icon Component={ArchiveIcon} color='error' />),
        text: t('Common.archive'),
        color: 'error',
        onClick: () => archiveTouristOpen({ id })
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Common.delete'),
        color: 'error',
        onClick: () => deleteTouristOpen({ id })
      }
    ],
    [t, router, archiveTouristOpen, deleteTouristOpen]
  )

  const columns = useMemo(
    () => [
      {
        id: '',
        sorter: 'fio',
        filter: 'status',
        filterComponent: TouristStatusFilter,
        filterIsActive: filterTouristStatus?.length > 0,
        filterComponentProps: {
          filterState: filterTouristStatus,
          onChange: onSetFilterTouristStatus,
        },
        label: t('Common.fio'),
        maxWidth: pageWidth * 0.166,
        search: touristFioSearcher,
        renderer: TouristFioRenderer
      },
      {
        id: '',
        sorter: 'birthday',
        filter: 'birthday',
        filterComponent: TouristBirthdayFilter,
        filterIsActive: !!filterBirthday.by,
        filterComponentProps: {
          filterState: filterBirthday,
          onChange: onSetFilterBirthday,
        },
        label: t('Common.birthday'),
        search: touristBirthdaySearcher,
        renderer: TouristBirthdayRenderer
      },
      {
        id: '',
        filter: 'sex',
        filterComponent: TouristSexFilter,
        filterIsActive: filterSex != null,
        filterComponentProps: {
          filterState: filterSex,
          onChange: onSetFilterSex,
        },
        label: t('Common.sex'),
        renderer: TouristSexRenderer
      },
      {
        id: '',
        label: t('Common.phone'),
        search: touristPhoneSearcher,
        renderer: TouristPhoneRenderer
      },
      {
        id: '',
        label: t('Tourist.contacts'),
        search: touristSocialNetworksSearcher,
        renderer: TouristSocialNetworksRenderer
      },
      {
        id: '',
        label: t('Tourist.info'),
        width: pageWidth * 0.2,
        renderer: TouristCommentRenderer
      },
      {
        id: '',
        noClick: true,
        columnView: () => (
          <SortableTableCleareAll
            canClear={
              filterTouristStatus?.length > 0 ||
              !!filterBirthday.by ||
              filterSex != null ||
              !!tsSorted
            }
            onClear={onClearAllFilters}
          />
        ),
        action: { 
          ...optionsRendererColumn, 
          onClick: (tourist, e) => {
            setOptionsElement(
              <Options
                opened
                onClose={() => setOptionsElement(null)}
                options={userOptions(tourist)}
                anchorEl={e.target}
              />
            )
          }
        },
        renderer: ActionRenderer
      },
    ],
    [
      t, 
      onSetFilterSex,
      onSetFilterBirthday,
      onSetFilterTouristStatus,
      onClearAllFilters,
      pageWidth, 
      userOptions,
      filterSex,
      filterTouristStatus,
      filterBirthday,
      tsSorted,
    ]
  )

  const href = useCallback(
    ({ id }) => router.root.c.tourists.c.one.getUrl(
      {
        touristId: id
      }
    ),
    [
      router
    ]
  )

  return (
    <CenterPanel>
      {archiveTouristModal}
      {deleteTouristModal}
      {optionsElement}
      <Loadable
        loading={isLoading}
        empty={!data}
      >
        {() => (
          <Linely
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely
                gap={0}
                vertical
              >
                <Typography variant='h6'>
                  {t('Tourist.subtitle', { count: totalTourists })}
                </Typography>
                <Linely gap='i3'>
                  {!!(blackListTourists || dangerZoneTourists || archiveTourists) && (
                    <Typography color='textSecondary'>
                      {connectToElementsPhrasesByRussian(
                        [
                          !!blackListTourists && (
                            <Trans
                              i18nKey='Tourist.subtitle2.blackList'
                              values={{ count: blackListTourists }}
                              components={[
                                <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                              ]}
                            />
                          ),
                          !!dangerZoneTourists && (
                            <Trans
                              i18nKey='Tourist.subtitle2.dangerZone'
                              values={{ count: dangerZoneTourists }}
                              components={[
                                <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                              ]}
                            />
                          ),
                          !!archiveTourists && (
                            <Trans
                              i18nKey='Tourist.subtitle2.archive'
                              values={{ count: archiveTourists }}
                              components={[
                                <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                              ]}
                            />
                          )
                        ]
                          .filter(el => !!el)
                      )}
                    </Typography>
                  )}
                  <Linely gap='i4'>
                    <Icon
                      Component={FaceIcon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {mansTourists}
                    </Typography>
                  </Linely>
                  <Linely gap='i4'>
                    <Icon
                      Component={Face2Icon}
                      color='action'
                    />
                    <Typography variant='subtitle1'>
                      {womansReserves}
                    </Typography>
                  </Linely>
                </Linely>
              </Linely>
              <PathLink
                href={router.getUrl([
                  [router.root.c.createTourist, {}]
                ])}
              >
                <CircleButton
                  big
                  variant='contained'
                >
                  <Icon
                    big
                    Component={AddIcon}
                  />
                </CircleButton>
              </PathLink>
            </LeftRight>
            <Linely
              gap='i1'
            >
              <FilledSearch
                value={searchValue}
                onChange={onSetSearchValue}
              />
              <ShowOnEvent
                event='click'
                onChange={onSetFilterArchive}
                filterState={filterArchive}
                Component={TouristArchiveFilter}
              >
                <CircleButton
                  big
                  color='inherit'
                >
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!filterArchive}
                  >
                    <Icon
                      big
                      Component={FilterListIcon}
                    />
                  </Badge>
                </CircleButton>
              </ShowOnEvent>
            </Linely>
            <Box mx='-i1'>
              <SortableTable
                highlighted={touristId}
                searchValue={deferredSearchValue}
                columns={columns}
                data={sortedTourists}
                href={href}
                sorter={sorter}
                onSetSorter={onSetSorter}
                paginationProps={{
                  rowsPerPage: 20,
                  page: tsPage ? tsPage -1 : 0,
                  onPageChange: (newPage) => 
                    router.pushUrl(router.getUrl([
                      [router.root.c.tourists.c.tsPage.c.value, local({ value: newPage + 1 })],
                    ]))
                }}
              />
            </Box>
          </Linely>
        )}
      </Loadable>
    </CenterPanel>
  )
}

export default TouristsList
