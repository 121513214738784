import Centered from '../../tools/Components/atoms/Centered';
import { Typography } from '@mui/material';
import { paginationPages } from '../../utils/helpers';
import Linely from '../../tools/Components/atoms/Linely';
import CircleButton from './CircleButton';
import { useCallback } from 'react';
import Box from '../../tools/Components/atoms/Box';

const TablePagination = ({ end, page, onPageChange }) => {
  const values = paginationPages(1, end, page + 1)

  const handleClick = useCallback(
    value => (e) => onPageChange(e, value - 1),
    [onPageChange]
  )

  return (
    <Box
      fullWidth
      p='i1'
    >
      <Centered>
        <Linely gap='i1'>
          {values.map(value => value === '...'
            ? (
              <Typography>
                ...
              </Typography>
            )
            : (page + 1) === value
              ? (
                  <CircleButton
                    color='primary'
                    sx={{ pointerEvents: 'none' }}
                  >
                    {value}
                  </CircleButton>
                )
              : (
                <CircleButton
                  color='inherit'
                  onClick={handleClick(value)}
                >
                  {value}
                </CircleButton>
              ))
          }
          </Linely>
      </Centered>
    </Box>
  )
}

export default TablePagination
