import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { IReserve, ReserveEndpoint } from "./ReserveEndpoint";
import { ReservesEndpoint } from "./ReservesEndpoint";
import { TourEndpoint } from "./TourEndpoint";
import { TouristToursEndpoint } from "./TouristToursEndpoint";
import { ToursEndpoint } from "./ToursEndpoint";

export const ReserveEditEndpoint = new DstSnRcHttp<
  [number, number, IReserve],
  IReserve
>({
  method: HTTP_METHODS.patch,
  url: ([tourId, touristId]) => `/tours/${tourId}/tourists/${touristId}`,
  body: ([tourId, touristId, reserve]: [number, number, IReserve]) => reserve
})

ReserveEditEndpoint.transformUpdateFor(
  ReserveEndpoint,
  (oldData, newReserve) => oldData.id_tour === newReserve.id_tour && oldData.tourist.id === newReserve.tourist.id ? newReserve : oldData
)

ReserveEditEndpoint.reloadUpdateFor(
  ReservesEndpoint,
  () => true
)

ReserveEditEndpoint.reloadUpdateFor(
  TouristToursEndpoint,
  (__, _, [, touristId], olDataTouristId) => touristId === olDataTouristId
)

ReserveEditEndpoint.reloadUpdateFor(
  TourEndpoint,
  (oldData, _, [tourId]) => tourId === oldData.id
)

ReserveEditEndpoint.reloadUpdateFor(
  ToursEndpoint,
  () => true
)
