import { Checkbox, Divider, List, ListItem, ListItemButton, Popover, TextField, Typography } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react'
import { 
  createSeasonsForDateFilters, 
  seasonToDates, 
} from '../../utils/helpers';
import { tsToMomentMoscow } from '../../utils/moment';
import { getIn, setIn } from 'formik';
import DatePicker from '../widgets/DatePicker';

export const TourSeasonsFilter = ({
  onClose,
  anchorEl,
  filterState,
  onChange,
  opened,
  tours
}) => {
  const { t } = useTranslation()

  const seasons = useMemo(
    () => createSeasonsForDateFilters()
      .map(({ year, season, isFuture }) => {
        const [startDate, endDate] = seasonToDates(year, season)

        const countTours = tours
          .filter(tour => {
            const momentStartDate = tsToMomentMoscow(tour.start_date)
            const momentEndDate = tsToMomentMoscow(tour.end_date)

            return momentEndDate.isSameOrAfter(startDate) &&
            endDate.isSameOrAfter(momentStartDate)
          })
          .length

        return { year, season, countTours, isFuture }
      })
      .filter(({ countTours, isFuture }) => countTours > 0 || !isFuture),
    [tours]
  )

  const clickCurrent = useCallback(
    () => onChange({ ...filterState, current: !filterState.current }),
    [filterState, onChange]
  )

  const clickComing = useCallback(
    () => onChange({ ...filterState, coming: !filterState.coming }),
    [filterState, onChange]
  )

  const clickOver = useCallback(
    () => onChange({ ...filterState, over: !filterState.over }),
    [filterState, onChange]
  )

  const clickCustomDates = useCallback(
    () => onChange({ ...filterState, customDates: !filterState.customDates }),
    [filterState, onChange]
  )

  const changeCustomDatesStart = useCallback(
    moment => onChange({ ...filterState, customDates: true, customDatesStart: moment }),
    [filterState, onChange]
  )

  const changeCustomDatesEnd = useCallback(
    moment => onChange({ ...filterState, customDates: true, customDatesEnd: moment }),
    [filterState, onChange]
  )

  const clickSeason = useCallback(
    (year, season) => () => {
      const path = `seasons.${year}_${season}`
      const retState = setIn(filterState, path, !getIn(filterState, path))
      onChange(retState)
    },
    [filterState, onChange]
  )

  return (
    <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <List
      >
        <ListItemButton
          onClick={clickCurrent}
        >
          <Checkbox
            color='primary'
            key={filterState.current}
            checked={filterState.current}
          />
          <Typography>
            {t('Tour.status.multiple.current')}
          </Typography>
        </ListItemButton>
        <ListItemButton
          onClick={clickComing}
        >
          <Checkbox
            color='primary'
            key={filterState.coming}
            checked={filterState.coming}
          />
          <Typography>
            {t('Tour.status.multiple.coming')}
          </Typography>
        </ListItemButton>
        <Divider />
        {seasons.map(({ year, season, countTours }) => (
          <ListItemButton
            onClick={clickSeason(year, season)}
          >
            <Checkbox
              color='primary'
              key={getIn(filterState, `seasons.${year}_${season}`)}
              checked={getIn(filterState, `seasons.${year}_${season}`)}
            />
            <Typography>
              {t(season === 0
                  ? 'Tour.filters.dates.seasons.winter'
                  : season === 1
                    ? 'Tour.filters.dates.seasons.spring'
                    : season === 2
                      ? 'Tour.filters.dates.seasons.summer'
                      : 'Tour.filters.dates.seasons.autumn'
              )}
              {' '}
              {season === 0 ? `${year-1} - ${year}` : year}
              {' '}
              ({countTours})
            </Typography>
          </ListItemButton>
        ))}
        <Divider />
        <ListItem>
          <Checkbox
            color='primary'
            key={filterState.customDates}
            checked={filterState.customDates}
            onChange={clickCustomDates}
          />
          <Linely vertical>
            <DatePicker
              label={t('Common.from.label')}
              value={filterState.customDatesStart}
              onChange={changeCustomDatesStart}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
            <DatePicker
              label={t('Common.to.label')}
              value={filterState.customDatesEnd}
              onChange={changeCustomDatesEnd}
              slotProps={{ field: { clearable: true } }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 170
                    }}
                    size="small"
                  />
                )
              }}
            />
          </Linely>
        </ListItem>
        <Divider />
        <ListItemButton
          onClick={clickOver}
        >
          <Checkbox
            color='primary'
            key={filterState.over}
            checked={filterState.over}
          />
          <Typography>
            {t('Tour.status.multiple.over')}
          </Typography>
        </ListItemButton>
      </List>
    </Popover>
  )
}
