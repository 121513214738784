import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { debounce } from 'lodash';
import { useMemo } from 'react'

const MonthPicker = ({ onChange, ...props }) => {
  const handleChange = useMemo(
    () => debounce(
      moment => onChange(moment),
      600
    ),
    [onChange]
  )

  return (
    <MuiDatePicker
      {...props}
      views={['month']}
      onChange={handleChange}
    />
  )
}

export default MonthPicker
