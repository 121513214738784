import { Select, FormControl, InputLabel } from '@mui/material'
import { useState } from 'react'
import RequiredableLable from './ui/RequiredableLable'

let globalHtmlId = 0

const SelectField = ({ label, required, ...props }) => {
  const [htmlId] = useState(
    (() => globalHtmlId++)()
  )
  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id={`demo-simple-select-filled-label2${htmlId}`}>
        <RequiredableLable
          label={label}
          required={required}
        />
      </InputLabel>
      <Select
        {...props}
        labelId={`demo-simple-select-filled-label2${htmlId}`}
      />
    </FormControl>
  )
}

export default SelectField
