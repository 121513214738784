import prodConfig from './config.prod'

let devConfig = prodConfig
try {
  devConfig = {
    ...prodConfig,
    ...require('./config.dev').default
  }
} catch (e) {}

export const config = process.env.NODE_ENV === 'development' ? devConfig : prodConfig
