// import en from './en.json'
import ru from './ru.json'

const locales = {
  // en: {
  //   translation: en
  // },
  ru: {
    translation: ru
  }
}

export default locales
