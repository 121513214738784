import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";

export interface IAuthUserEndpointParams {
  username: string,
  password: string
}

export interface IAuthUserEndpointData {
  access: string,
  refresh: string
}

export const AuthUserEndpoint = new DstSnRcHttp<
  IAuthUserEndpointParams,
  IAuthUserEndpointData,
  null,
  any
>({
  method: HTTP_METHODS.post,
  url: '/auth/login',
  body: ({ username, password }: IAuthUserEndpointParams) => ({ username, password }),
  bodyTransform: (body: IAuthUserEndpointParams) => {
    const formData = new FormData();
    
    for(const [key, value] of Object.entries(body)) {
      formData.append(key, value.toString())
    }

    return formData
  },
  headers: {
    'Accept': '*/*'
  }
})
