import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

export const TourPlacesRenderer = ({ value: tour }) => {
  const { t } = useTranslation()

  return (
    <Typography variant='body1'>
      {t('Common.countOf', { count: tour.busy_places, total: tour.total_places })}
    </Typography>
  )
}
