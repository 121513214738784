import { IHashMap } from "../../utils/types";
import { 
    PathNode,
    IPathNodeOptions
} from "./";
import { autobind } from "core-decorators";
import { cloneNode } from "./helpers";

@autobind
export class PathMultipleNode<TChilds extends IHashMap<PathNode<any>>> {
    private _root: PathNode<TChilds>
    public get root() {
        return this._root
    }

    private _realNodes: PathNode<any>[] = []
    public get realNodes() {
        return this._realNodes
    }

    constructor(options?: IPathNodeOptions, childNodes?: TChilds) {    
        this._root = new PathNode({ ...options, _multipleRoot: this }, childNodes)
        this._root.__compileNode()
    }

    public apply() {
        const clone = cloneNode(this.root)
        this._realNodes.push(clone)
        return clone
    }
}
