import Box from '../../tools/Components/atoms/Box';
import { forwardRef } from 'react'

const StatusSmallIcon = forwardRef(({ color, ...props }, ref) => {
  
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        width: 12,
        minWidth: 12,
        height: 12,
        minHeight: 12,
        borderRadius: '100%',
        bgcolor: color
      }}
    />
  )
})

export default StatusSmallIcon
