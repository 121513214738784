import Linely from './Linely'

function LeftRight ({ ...props }) {
  return (
    <Linely
      {...props}
      fullLine
      spaced
    />
  )
}

export default LeftRight
