import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { ToursEndpoint } from "./ToursEndpoint";

export const TourDeleteEndpoint = new DstSnRcHttp<
  number
>({
  method: HTTP_METHODS.delete,
  url: id => `/tours/${id}`,
})

TourDeleteEndpoint.transformUpdateFor(
  ToursEndpoint,
  (oldData, _, id) => oldData.filter(t => t.id !== id)
)
