import { autobind } from 'core-decorators';

export type TEventInvoke<Args extends Array<any>> =
  (...args: Args) => void

// разница лишь в том что TEventInvoke при одном вызове вызывает все TEventCb
export type TEventCb<Args extends Array<any>> = TEventInvoke<Args>

@autobind
export class Event<Args extends Array<any>> {
  protected subscribers: Set<TEventCb<Args>> = new Set()

  public get count() {
    return this.subscribers.size
  }

  public invoke (...args: Args) {
    for(const cb of this.subscribers.values()) {
      cb(...args)
    }
  }

  public subscribe (cb: TEventCb<Args>) {
    this.subscribers.add(cb)
  }

  public subs (cb: TEventCb<Args>) {
    this.subscribe(cb)
  }

  public subscribeOnce (cb: TEventCb<Args>) {
    const onceCb = (...args: Args) => {
      cb(...args)
      this.unsubscribe(onceCb)
    }

    this.subscribe(onceCb)
  }

  public subsOnce (cb: TEventCb<Args>) {
    this.subscribeOnce(cb)
  }

  public unsubscribe (deleteCb: TEventCb<Args>) {
    this.subscribers.delete(deleteCb)
  }

  public unsub (deleteCb: TEventCb<Args>) {
    this.unsubscribe(deleteCb)
  }

  public wait () {
    return new Promise((resolve) => this.subscribeOnce((...args) => resolve(args)))
  }
}

export const event = <Args extends Array<any>>() => new Event<Args>()
