import { useTheme } from '@mui/material/styles';
import logoBackground from '../../static/images/logo-background.svg'
import Stickly from '../../tools/Components/Stickly';
import Box from '../../tools/Components/atoms/Box';
import FixedYBackground from './FixedYBackground';

const CenterPanel = ({ children }) => {
  const theme = useTheme()

  return (
    <Box
      w='70%'
      height='calc(100vh - 64px)'
      position='relative'
      bgcolor={theme.palette.background.paper}
    >
      <FixedYBackground
        position='fixed'
        left={0}
        bottom={0}
        style={{
          width: 'inherit'
        }}
        zIndex={1}
        img={logoBackground}
        opacity={0.03}
        height={700}
        scale={2.374}
        rotate='5.9deg'
        translateX='300px'
        translateY='43px'
      />
      <Stickly
        position='relative'
        zIndex={2}
        minTop={64}
        fullWidth
        fullHeight
        StickProps={{
          p: 'i1',
        }}
        style={{
          overflowY: 'auto'
        }}
      >
        {children}
      </Stickly>
    </Box>
  )
}

export default CenterPanel
