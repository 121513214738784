import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '../ui/widgets/ThemeProvider';
import AppRoutes from './AppRoutes';
import 'moment/locale/ru'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import GlobalError from '../ui/widgets/GlobalError'
import { useEffect } from 'react';
import PathRouterProvider from '../tools/Components/paths/PathRouterProvider';
import paths from '../paths'
import UserProvider from './UserProvider';
import ApiErrorsListner from '../ui/widgets/ApiErrorsListner';
import { ErrorBoundary } from "react-error-boundary";
import LocalError from '../ui/widgets/LocalError';

function App() {
  useEffect(() => {
    moment.locale('ru')
  }, [])

  return (
    <ThemeProvider>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={GlobalError}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale='ru'
        >
          <PathRouterProvider
            router={paths}
            ErrorViewComponent={LocalError}
          >
            <UserProvider>
              <ApiErrorsListner />
              <AppRoutes />
            </UserProvider>
          </PathRouterProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
