import { Typography } from '@mui/material'

const RequiredableLable = ({ label, required }) => typeof label !== 'string'
  ? label
  : (
    <Typography>
      {label}
      {required && (
        <Typography display='inline' color='error' sx={{ color: '#E73E16 !important' }}>{' '}*</Typography>
      )}
    </Typography>
  )

export default RequiredableLable
