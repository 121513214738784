import { autobind } from "core-decorators"
import { IHashMap } from "../../../utils/types"
import { Event } from "../../event"
import { EndpointBase, IEndpointBaseConfig, IEndpointBaseResponse } from "../baseEndpoints/EndpointBase"
import { setIn } from "../../../utils/object"

export type TTreeBase = { [key: string]: EndpointBase<any, any, any, any> | EndpointsTree<TTreeBase, any> | TTreeBase }

@autobind
export class EndpointsTree<
  TTree extends TTreeBase, 
  TConfig extends IHashMap<IHashMap<any>>
> {
  private _config: TConfig
  public get config () { return this._config }

  private _tree: TTree
  public get tree() {
    return this._tree
  }

  private _parent: EndpointsTree<any, any> | undefined
  public get parent() {
    return this._parent
  }

  private _name: string = ''
  public get name() {
    return this._name
  }

  //@ts-ignore
  private _onSend: Event<[EndpointBase<any, any, TConfig>, any]>
  public get onSend() {
    return this._onSend
  }

  //@ts-ignore
  private _onReceive: Event<[EndpointBase<any, any, TConfig>, IDstSnRcResponse<any, any>, any]>
  public get onReceive() {
    return this._onReceive
  }

  public constructor(
    config: TConfig,
    tree: TTree,
  ) {
    this._tree = tree
    this._config = config

    this._onSend = new Event()
    this._onReceive = new Event()

    this.compileTree(this.tree)

    this.devLog()
  }

  private compileTree(tree: TTree, nodeNamePrefix: string = '') {
    for (const [key, node] of Object.entries(tree)) {
      const nodeName = nodeNamePrefix + (nodeNamePrefix.length > 0 ? '.' : '') + key
      
      if (node instanceof EndpointBase) {
        node.___applyTree(this, nodeName)
      }
      else if (node instanceof EndpointsTree) {
        node.compile(this, nodeName)
      }
      else {
        this.compileTree(node as TTree, nodeName)
      }
    }
  }

  public setConfig(path: string, value: any) {
    this._config = setIn(this._config, path, value)
  }

  public getConfigProp (configKey: string, name: string): any | undefined {
    const prop = this.config[configKey]?.[name]

    return prop !== undefined ? prop : this.parent?.getConfigProp(configKey, name)
  }

  public compile(parent?: EndpointsTree<any, any>, name: string = '') {
    this._parent = parent
    this._name = name

    this.compileTree(this.tree, name)
  }

  public onSendInvoke <TConfig extends IEndpointBaseConfig, TParams, TData, TError>(
    endpoint: EndpointBase<TConfig, TParams, TData, TError>, 
    params: TParams
  ) {
    this.onSend.invoke(endpoint, params)
    this.parent?.onSendInvoke(endpoint, params)
  }

  public onReceiveInvoke <TConfig extends IEndpointBaseConfig, TParams, TData, TError>(
    endpoint: EndpointBase<TConfig, TParams, TData, TError>, 
    response: IEndpointBaseResponse<TData, TError>, 
    params: TParams
  ) {
    this.onReceive.invoke(endpoint, response, params)
    this.parent?.onReceiveInvoke(endpoint, response, params)
  }

  private devLog() {
      // @ts-ignore
      if(!window.___EndpointsApi___) {
          // @ts-ignore
          window.___EndpointsApi___ = []
      }

      // @ts-ignore
      window.___EndpointsApi___.push(this)
  }
}
