import { useFormikContext } from "formik"
import { useMemo } from 'react'
import { mbComponentFunctionalCloneElement } from "../../utils/react"

const SubmitButtonWrapper = ({
    allowNotDirty,
    allowInvalid,
    Component,
    children,
    disabled: outDisabled,
    ...props
}) => {
    const { isValid, dirty, submitForm, isSubmitting } = useFormikContext()

    const disabled = useMemo(
        () => outDisabled ||
            (!allowNotDirty && !dirty) ||
            (!allowInvalid && !isValid) ||
            isSubmitting,
        [
            outDisabled, 
            allowNotDirty, 
            allowInvalid,
            dirty,
            isValid,
            isSubmitting
        ]
    )

    return mbComponentFunctionalCloneElement(
        Component,
        children,
        {
            ...props,
            disabled,
            onClick: submitForm
        }
    )
}

export default SubmitButtonWrapper