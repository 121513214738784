import { forwardRef } from 'react'
import Box from './Box'

function Clickly (
  {
    children,
    className,
    href,
    onClick,
    ...props
  },
  ref
) {
  return (!href && !onClick)
    ? children
    : (
      <Box
        {...props}
        onClick={onClick}
        href={href}
        component='a'
        display='contents'
        cursor='pointer'
        color='inherit'
        textDecoration='none'
        ref={ref}
      >
        {children}
      </Box>
      )
}

export default forwardRef(Clickly)
