import parsePhoneNumber from 'libphonenumber-js'
import { DASH_LINE } from '../tools/utils/string'
import { momentToMomentMoscow, tsToMomentMoscow } from './moment'
import moment from 'moment'
import api from '../endpoints'
import { getDateFormat1 } from '../tools/utils/moment'

export const userToFullFio = user => [user.surname, user.first_name, user.second_name].join(' ')

export const PREPAYMENT_COEFF = 0.3

export const getRepayment = price => Math.round(PREPAYMENT_COEFF * price)

export const getPartialPrices = price => {
  const prepayment = getRepayment(price)
  const remainder = price - prepayment
  
  return {
    prepayment,
    remainder
  }
}

export const getTouristStatusLocaleKey = status => 
  status === 'normal'
    ? 'Tourist.status.normal'
    : status === 'danger_zone'
      ? 'Tourist.status.dangerZone'
      : 'Tourist.status.blocked'

export const getTouristStatusColor = status => 
  status === 'normal'
    ? '#66BB6A'
    : status === 'danger_zone'
      ? '#FFA726'
      : '#F44336' // blocked

export const tourDaysInfo = (tour) => {
  const startMoment = tsToMomentMoscow(tour.start_date)
  const endMoment = tsToMomentMoscow(tour.end_date)
  const curentMoment = moment()

  const diffDays = endMoment.diff(startMoment, 'days') + 1

  const daysBefore = startMoment.diff(curentMoment, 'days')

  return { startMoment, endMoment, diffDays, daysBefore }
}

export const getTourStatus = tour => {
  const startMoment = tsToMomentMoscow(tour.start_date)
  const endMoment = tsToMomentMoscow(tour.end_date)
  const curentMoment = moment()

  const isComing = startMoment.diff(curentMoment, 'days') > 0
  const isOver = curentMoment.diff(endMoment, 'days') > 0

  const placesIsDone = tour.busy_places === tour.total_places

  return { isOver, isComing, placesIsDone }
}

export const getTourStatusLocaleKey = ({ isOver, isComing, placesIsDone }) => isOver
  ? 'Tour.status.over'
  : isComing && placesIsDone
    ? 'Tour.status.comingGroupFull'
    : isComing
      ? 'Tour.status.coming'
      : 'Tour.status.current'

export const getTourStatusColor = ({ isOver, isComing, placesIsDone }) => isOver
    ? '#C9C9C9'
    : isComing && placesIsDone
      ? '#66BB6A'
      : isComing
        ? '#DED500'
        : '#FFA726'

export const getReserveStatusIsValid = status =>
  ['full', 'prepayment'].indexOf(status) > -1

export const getReserveStatusLocaleKey = status => 
  status === 'full'
    ? 'Reserve.status.full'
    : 'Reserve.status.prepayment'

export const getReserveStatusColor = status => 
  status === 'full'
    ? '#66BB6A'
    : '#FFA726' // prepayment

export const getCancelStatusIsValid = status =>
  ['cancellation', 'transfer'].indexOf(status) > -1

export const getCancelStatusLocaleKey = status => 
  status === 'cancellation'
    ? 'Cancel.status.cancellation'
    : 'Cancel.status.transfer'

export const parsePhone = phone => phone
  ? parsePhoneNumber(`+${phone}`)?.formatInternational()
  : DASH_LINE

export const priceToStr = price => Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 0
}).format(price)

export const connectToElementsPhrasesByRussian = (phrases) => [
  ...(
    phrases
      .slice(0, -2)
      .map(el => [el, ', '])
      .flat()
    ),
  phrases.at(-2),
  phrases.length > 1 && ' и ',
  phrases.at(-1)
]
  .filter(el => !!el)

export const paginationPages = (first, end, current) => [
  first,
  ...(current - first > 2 ? ['...'] : []),
  ...(current - first > 1 ? [current - 1] : []),
  ...((end - first > 1 && current !== first && current !== end) ? [current] : []),
  ...(end - current > 1 ? [current + 1] : []),
  ...(end - current > 2 ? ['...'] : []),
  ...(end - first > 0 ? [end] : []),
]

export const download = (blob, fileName) => {
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();    
  a.remove();  //afterwards we remove the element again 
}

export const touristIsBirthdayInTour = (tour, tourist) => {
  const startMoment = tsToMomentMoscow(tour.start_date).startOf('day')
  const endMoment = tsToMomentMoscow(tour.end_date).endOf('day')

  const touristBirthdayMoment = tsToMomentMoscow(tourist.birthday)

  // дата начала может быть в одном году, а дата конца в другом году, и тогда надо проверить что обе даты подходят
  // я надеюсь у нас не будет туров которые длятся больше 2 лет)))
  const touristBirthdayInStartDayYear = touristBirthdayMoment.set('year', startMoment.year())
  const touristBirthdayInEndDayYear = touristBirthdayMoment.set('year', endMoment.year())

  return [
    touristBirthdayInStartDayYear,
    touristBirthdayInEndDayYear
  ]
    .some(date => date.isSameOrAfter(startMoment) && endMoment.isSameOrAfter(date))
}

export const sortedIsAsc = (isAsc, isMore) => (isMore ? 1 : -1) * (isAsc ? 1 : -1)

export const createSeasonsForDateFilters = () => {
  const nowMoment = momentToMomentMoscow(moment())
  const curentMonth = nowMoment.month()
  let curentYear = nowMoment.year()

  let curentSeason = curentMonth <= 1
    ? 0 // Конец зимы
    : curentMonth <= 4
      ? 1 // Весна
      : curentMonth <= 7
        ? 2 // Лето
        :  curentMonth <= 10
          ? 3 // Осень
          : 4 // Начало зимы

  if(curentSeason === 4) {
    curentSeason = 0
    curentYear += 1
  }

  return [-1, 0, 1, 2, 3, 4].map(seasonOffset => {
    let season = curentSeason + seasonOffset
    let year = curentYear

    if(season < 0) {
      season = 3
      year -= 1
    }

    if(season > 3) {
      season -= 4
      year += 1
    }

    const isFuture = seasonOffset > 0

    return { season, year, isFuture }
  })
}

export const seasonToDates = (year, season) => [
  moment({
    year: season === 0 ? year - 1 : year,
    month: season === 0 ? 11 : season === 1 ? 2 : season === 2 ? 5 : 8
  }).startOf('month'),
  moment({
    year,
    month: season === 0 ? 1 : season === 1 ? 4 : season === 2 ? 7 : 10,
  }).endOf('month')
]

export const downloadTourExcel = async tour => {
  const { startMoment, endMoment } = tour ? tourDaysInfo(tour) : {}

  const { data } = await api.tree.tours.excel.request(tour.id)

  data && download(
    data,
    `${tour.title} (${getDateFormat1(startMoment)} - ${getDateFormat1(endMoment)}).xlsx`
  )
}

export const filterSeasonsNotSelected = ({ current, coming, customDates, over, seasons }) => 
  (!current && !coming && !customDates && !over && Object.values(seasons || {}).filter(val => !!val).length === 0)
