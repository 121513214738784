import { 
    setIn as formikSetIn,
    getIn as formikGetIn,
} from "formik"

export const isObject = (variable: any): variable is Object => 
    typeof variable === 'object' &&
    variable !== null &&
    !Array.isArray(variable)

export const objFirstLvlIsEqual = (obj1: {}, obj2: {}) => 
    //@ts-ignore
    Object.keys(obj1).length === Object.keys(obj2).length && !Object.entries(obj1).some(([key, val]) => obj2[key] !== val)

export const getIn = <T1, T2>(obj: T1, path: string) => path === '' ? obj : formikGetIn(obj, path) as T2

export const setIn = <T1, T2>(obj: T1, path: string, value: T2) => path === '' ? value : formikSetIn(obj, path, value) as T1
