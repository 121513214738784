import { getReserveStatusColor } from '../../utils/helpers';
import StatusSmallIcon from './StatusSmallIcon';

const ReserveStatus = ({ status, ...props }) => {
  
  return (
    <StatusSmallIcon
      {...props}
      color={getReserveStatusColor(status)}
    />
  )
}

export default ReserveStatus
