import Box from '../../tools/Components/atoms/Box'

const FixedYBackground = ({
    img,
    opacity,
    width,
    height,
    top = 0,
    scale = 1,
    rotate = 0,
    translateX = 0,
    translateY = 0,
    ...props
}) => {
    return (
        <Box
            {...props}
            style={{
                overflow:'clip',
                ...props.style
            }}
        >
            <Box
                position='sticky'
                width={width}
                height={height}
                top={top}
                style={{
                    transform: `scale(${scale}) rotate(${rotate}) translateX(${translateX}) translateY(${translateY})`,
                    opacity,
                    backgroundImage: `url(${img})`
                }}
            />
        </Box>
    )
}

export default FixedYBackground
