import { useCallback, useMemo } from 'react'
import useInOutState from './useInOutState'
import { localEventFn } from '../../../tools/utils/helpers'

function useBaseTable (
  data,
  outerSelectedIds,
  onClick,
  selectable,
  multipleSelection,
  selectOnClick,
  onSelect,
  onSelectAll
) {
  selectable = !!(selectable || onSelect)
  const [selectedIds, , setSelected] = useInOutState(outerSelectedIds, [], true)

  const allowSelectRows = useMemo(
    () => data && selectable
      ? data
          .filter(
            ({ renderComponent, renderElement }) =>
              !renderComponent && !renderElement
          )
          .map(({ id }) => id)
      : [],
    [data, selectable]
  )

  function handleSelectAllClick (checked) {
    let ids = []

    if (checked) {
      ids = [...allowSelectRows]
    }

    setSelected(ids)
    onSelect && onSelect(ids, null)
    onSelectAll && onSelectAll(checked)
  }

  const handleSelect = useMemo(
    () => localEventFn(id => {
      if (allowSelectRows.indexOf(id) === -1) return

      let newSelected = []
      if (multipleSelection) {
        const selectedIndex = selectedIds.indexOf(id)

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedIds, id)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedIds.slice(1))
        } else if (selectedIndex === selectedIds.length - 1) {
          newSelected = newSelected.concat(selectedIds.slice(0, -1))
        } else {
          newSelected = newSelected.concat(
            selectedIds.slice(0, selectedIndex),
            selectedIds.slice(selectedIndex + 1)
          )
        }
      } else {
        newSelected = [id]
      }

      setSelected(newSelected)
      onSelect != null && onSelect(newSelected, id)
    }),
    [allowSelectRows, multipleSelection, onSelect, setSelected, selectedIds]
  )

  const handleClick = useCallback(
    row => {
      onClick && onClick(row.id, row)
      selectOnClick && selectable && handleSelect(row.id)
    },
    [onClick, selectOnClick, selectable, handleSelect]
  )

  return [
    selectable,
    selectedIds,
    setSelected,
    handleSelect,
    handleSelectAllClick,
    !onClick && !(selectOnClick && selectable) ? null : handleClick
  ]
}

export default useBaseTable
