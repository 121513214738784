import { autobind } from 'core-decorators'
import { logger } from '../../utils/logger'
import { Event } from './Event'

@autobind
export class CrossPageEvent<Args extends Array<any>> extends Event<[Args, boolean]> {
  protected lsVarNameOriginal: string
  protected lsVarName: string

  public constructor(lsVarName: string) {
    super()

    this.lsVarName = `___CrossPageEvent___${lsVarName}`
    this.lsVarNameOriginal = lsVarName

    this.listen()
  }

  protected listen() {
    window.addEventListener('storage', e => {
      if(e.key !== this.lsVarName) {
        return
      }

      const jsonArgs = e.newValue

      // @ts-ignore
      let data: Args = []

      try {
        data = JSON.parse(jsonArgs || '')
      }
      catch(e) {
        logger.error(`CrossPageEvent error: ${this.lsVarName} has incorect json value: ${jsonArgs}`)
      }

      this.invokeOnThisPage(data[1], false)
    })
  }

  protected invokeOnThisPage (args: Args, isThisPage: boolean) {
    super.invoke(args, isThisPage)
  }

  // @ts-ignore
  public invoke (...args: Args) {
    localStorage.setItem(this.lsVarName, JSON.stringify([Math.random(), args]))
    this.invokeOnThisPage(args, true)
  }
}
