import { DstSnRcHttp, HTTP_METHODS } from "../tools/libs/endpoints";
import { IReserveSmall } from "./ReservesEndpoint";

export interface IReserve extends IReserveSmall {
}

export const ReserveEndpoint = new DstSnRcHttp<
  [number, number],
  IReserve
>({
  method: HTTP_METHODS.get,
  url: ([tourId, touristId]) => `/tours/${tourId}/tourists/${touristId}`
})
