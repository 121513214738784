import { EndpointModel } from "./"
import { autobind } from "core-decorators"
import { Event } from '../../event'
import { DstRc, DstSnRc } from '../baseEndpoints'

export type TEndpointOriginUpdate<TParams, TData> = 
  DstSnRc<any, any, TParams, TData, any, any> |
  DstRc<any, TParams, TData, any, any>

export type TEndpointDstUpdate<TParams, TData> = DstSnRc<any, any, TParams, TData, any, any>

export type TDstSnRcDataUpdaterReloadUpdate<
  TParamsOrigin,
  TDataOrigin,
  TParamsDst,
  TDataDst,
> = (
  dataDst: TDataDst, 
  dataOrigin: TDataOrigin,
  paramsOrigin: TParamsOrigin,
  paramsDst: TParamsDst,
) => boolean

export type TDstSnRcDataUpdaterTransformUpdate<
  TParamsOrigin,
  TDataOrigin,
  TParamsDst,
  TDataDst,
> = (
  dataDst: TDataDst, 
  dataOrigin: TDataOrigin,
  paramsOrigin: TParamsOrigin,
  paramsDst: TParamsDst,
) => TDataDst

@autobind
export class DstSnRcDataUpdater<
    TParams extends any, 
    TData extends any,
> {
  private _endpoint: DstSnRc<any, any, TParams, TData, any, any>
  public get endpoint() { return this._endpoint }

  private _model?: EndpointModel<any, TData>
  public get model() { return this._model }
  
  private _onUpdateMe: Event<[
    any, // params
    any, // data
    TEndpointOriginUpdate<any, any>
  ]> = new Event()
  
  // сюда подписываются те, кого надо обновить
  private _onReloadUpdate: Event<[
    any, // params
    any, // data
    TDstSnRcDataUpdaterReloadUpdate<any, any, TParams, TData>
  ]> = new Event()
  public get onReloadUpdate() { return this._onReloadUpdate }
  
  // сюда подписываются те, кого надо обновить
  private _onTransformUpdate: Event<[
    any, // params
    any, // data
    TDstSnRcDataUpdaterTransformUpdate<any, any, TParams, TData>
  ]> = new Event()
  public get onTransformUpdate() { return this._onTransformUpdate }
  
  // кто обновляет этот эндпоинт
  private _reloadUpdateOrigins: Map<TEndpointOriginUpdate<any, any>, TDstSnRcDataUpdaterReloadUpdate<any, any, TParams, TData>> = new Map()
  public get reloadUpdateOrigins() { return this._reloadUpdateOrigins }
  
  // кто обновляет этот эндпоинт
  private _transformUpdateOrigins: Map<TEndpointOriginUpdate<any, any>, TDstSnRcDataUpdaterTransformUpdate<any, any, TParams, TData>> = new Map()
  public get transformUpdateOrigins() { return this._transformUpdateOrigins }

  public constructor(endpoint: DstSnRc<any, any, TParams, TData, any, any>, model?: EndpointModel<any, TData>) {
    this._endpoint = endpoint
    this._model = model
  }

  public addReloadUpdateOrigin<TParamsDst, TDataDst>(
    endpoint: TEndpointOriginUpdate<TParamsDst, TDataDst>, 
    filter: TDstSnRcDataUpdaterReloadUpdate<TParamsDst, TDataDst, TParams, TData>
  ) {
    this.removeReloadUpdatOrigin(endpoint)

    this.reloadUpdateOrigins.set(endpoint, filter)
    endpoint.dataUpdater._onUpdateMe.subscribe(this.onReloadUpdateInvoke)
  }

  public addTransformUpdateOrigin<TParamsDst, TDataDst>(
    endpoint: TEndpointOriginUpdate<TParamsDst, TDataDst>, 
    transform: TDstSnRcDataUpdaterTransformUpdate<TParamsDst, TDataDst, TParams, TData>
  ) {
    this.removeTransformUpdateOrigin(endpoint)
    
    this.transformUpdateOrigins.set(endpoint, transform)
    endpoint.dataUpdater._onUpdateMe.subscribe(this.onTransformUpdateInvoke)
  }

  public removeReloadUpdatOrigin<TParamsDst, TDataDst>(
    endpoint: TEndpointOriginUpdate<TParamsDst, TDataDst>, 
  ) {
    this.reloadUpdateOrigins.delete(endpoint)
    endpoint.dataUpdater._onUpdateMe.unsubscribe(this.onReloadUpdateInvoke)
  }

  public removeTransformUpdateOrigin<TParamsDst, TDataDst>(
    endpoint: TEndpointOriginUpdate<TParamsDst, TDataDst>, 
  ) {
    this.transformUpdateOrigins.delete(endpoint)
    endpoint.dataUpdater._onUpdateMe.unsubscribe(this.onTransformUpdateInvoke)
  }

  public update(params: TParams, data: TData) {
    this.model?.update(data, this.endpoint)
    
    this._onUpdateMe.invoke(params, data, this.endpoint)
  }

  private onReloadUpdateInvoke <TParamsOrigin, TDataOrigin>(
    params: TParamsOrigin, 
    data: TDataOrigin, 
    endpoint: TEndpointOriginUpdate<TParamsOrigin, TDataOrigin>
  ) {
    this.onReloadUpdate.invoke(params, data, this.reloadUpdateOrigins.get(endpoint)!)
  }

  private onTransformUpdateInvoke <TParamsOrigin, TDataOrigin>(
    params: TParamsOrigin, 
    data: TDataOrigin, 
    endpoint: TEndpointOriginUpdate<TParamsOrigin, TDataOrigin>
  ) {
    this.onTransformUpdate.invoke(params, data, this.transformUpdateOrigins.get(endpoint)!)
  }
}
