import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import api from '../../../endpoints';
import usePathParams from '../../../tools/hooks/paths/usePathParams';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import usePathRouter from '../../../tools/hooks/paths/usePathRouter';
import Icon from '../../../ui/widgets/Icon';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { local } from '../../../tools/libs/paths/helpers';
import DeleteReserve from '../Reserves/Modals/DeleteReserve';
import useBoolean from '../../../tools/hooks/useBoolean';
import TourTouristView from '../TourTouristView';
import ChairIcon from '@mui/icons-material/Chair';

const CancelPage = () => {
  const { t } = useTranslation()

  const [router] = usePathRouter()
  const [{ tourId, touristId }] = usePathParams()

  const [moveToReserveModalOpened, , , moveToReserveModalOpen, moveToReserveModalClose] = useBoolean()
  const [deleteReserveOpened, , , deleteReserveOpen, deleteReserveClose] = useBoolean()
  
  const [{ data: tour, isLoading: isLoadingTour }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: reserve, isLoading: isLoadingReserve }] = useEnObserve(api.tree.reserves.one, [tourId, touristId])
  
  const reserveOptions = useMemo(
    () => tour && reserve && [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Reserve.editModal.title'),
        href: router.getUrl([
          [router.root.c.editReserve.c.tour.c.tourist, local({ touristId: reserve.tourist.id, tourId: tour.id })]
        ])
      },
      {
        icon: (<Icon Component={PersonIcon} />),
        text: t('Tourist.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId: reserve.tourist.id })]
        ])
      },
      {
        icon: (<Icon Component={ChairIcon} color='error' />),
        text: t('Cancel.moveToReserve'),
        color: 'error',
        onClick: moveToReserveModalOpen
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Reserve.delete'),
        color: 'error',
        onClick: deleteReserveOpen
      },
    ],
    [t, reserve, tour, router, moveToReserveModalOpen, deleteReserveOpen]
  )

  return (
    <>
      <DeleteReserve
        tourId={tourId}
        touristId={touristId}
        opened={moveToReserveModalOpened}
        onClose={moveToReserveModalClose}
      />
      <DeleteReserve
        tourId={tourId}
        touristId={touristId}
        opened={deleteReserveOpened}
        onClose={deleteReserveClose}
      />
      <TourTouristView
        type='cancel'
        tour={tour}
        tourTourist={reserve}
        loading={(!tour && isLoadingTour) || (!reserve && isLoadingReserve)}
        empty={!tour || !reserve}
        options={reserveOptions}
        goBackHref={router.root.c.tours.c.one.getUrl()}
      />
    </>
  )
}

export default CancelPage
