import api from "../../endpoints"
import { Typography, IconButton } from '@mui/material';
import Linely from "../../tools/Components/atoms/Linely"
import useEnObserve from "../../tools/hooks/endpoints/useEnObserve"
import usePathParams from "../../tools/hooks/paths/usePathParams"
import LeftRight from "../../tools/Components/atoms/LeftRight";
import Box from "../../tools/Components/atoms/Box";
import CloseIcon from '@mui/icons-material/Close';
import Icon from "../../ui/widgets/Icon";
import PathLink from "../../tools/Components/paths/PathLink";
import { useTranslation } from "react-i18next";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import { local } from "../../tools/libs/paths/helpers";
import ShowOnEvent from "../../tools/Components/event/ShowOnEvent";
import Options from "../../ui/widgets/Options";
import { useMemo } from 'react'
import { userToFullFio } from "../../utils/helpers";
import usePathRouter from "../../tools/hooks/paths/usePathRouter";
import useBoolean from "../../tools/hooks/useBoolean";
import DeleteTourist from "./Modals/DeleteTourist";
import ArchiveTourist from "./Modals/ArchiveTourist";
import Loadable from "../../ui/widgets/Loadable";
import { TouristInfo } from "../../ui/widgets/TouristInfo";
import { TouristToursInfo } from "../../ui/entities/TouristToursInfo";

const OneTourist = () => {
  const { t } = useTranslation()
  
  const [router] = usePathRouter()
  const [{ touristId }] = usePathParams()
  
  const [{ data, isLoading }] = useEnObserve(api.tree.tourists.one, touristId, null, !!touristId)

  const [archiveTouristOpened, , , archiveTouristOpen, archiveTouristClose] = useBoolean()
  const [deleteTouristOpened, , , deleteTouristOpen, deleteTouristClose] = useBoolean()
  
  const userOptions = useMemo(
    () => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Common.edit'),
        href: router.getUrl([
          [router.root.c.editTourist.c.one, local({ touristId })]
        ])
      },
      {
        icon: (<Icon Component={ArchiveIcon} color='error' />),
        text: t('Common.archive'),
        color: 'error',
        onClick: archiveTouristOpen
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Common.delete'),
        color: 'error',
        onClick: deleteTouristOpen
      }
    ],
    [t, router, touristId, archiveTouristOpen, deleteTouristOpen]
  )

  return (
    <>
      <ArchiveTourist
        id={touristId}
        opened={archiveTouristOpened}
        onClose={archiveTouristClose}
      />
      <DeleteTourist
        id={touristId}
        opened={deleteTouristOpened}
        onClose={deleteTouristClose}
      />
      <Loadable
        loading={!data && isLoading}
        empty={!data}
      >
        {() => (
          <Linely
            fulLine
            vertical
            gap='i1'
          >
            <LeftRight>
              <Typography
                variant='h6'
              >
                {userToFullFio(data)}
              </Typography>
              <Box mx={-1}>
                <PathLink
                  href={router.root.c.tourists.getUrl()}
                >
                  <IconButton>
                    <Icon
                      big
                      Component={CloseIcon}
                    />
                  </IconButton>
                </PathLink>
              </Box>
            </LeftRight>
            <LeftRight>
              <Typography
                variant='subtitle1'
              >
                {t('Tourist.mainInfo')}
              </Typography>
              <Box mx={-1}>
                <ShowOnEvent
                  event='click'
                  Component={Options}
                  options={userOptions}
                >
                  <IconButton>
                    <Icon
                      Component={MoreVertIcon}
                    />
                  </IconButton>
                </ShowOnEvent>
              </Box>
            </LeftRight>
            <TouristInfo
              tourist={data}
            />
            <TouristToursInfo
              tourist={data}
            />
          </Linely>
        )}
      </Loadable>
    </>
  )
}

export default OneTourist
