import { useCallback } from 'react'
import DatePicker from '../widgets/DatePicker'

const DatePickerField = ({ form: { setFieldValue }, field, ...props }) => {
  const onChange = useCallback(
    moment => {
      setFieldValue(
        field.name,
        moment,
        true
      )
    },
    [field.name, setFieldValue]
  )

  return (
    <DatePicker
      {...props}
      {...field}
      onChange={onChange}
    />
  )
}

export default DatePickerField
