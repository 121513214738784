import { useTheme } from '@mui/material'
import { forwardRef } from 'react'
import Box, { transformIndent } from './Box'

function Linely ({
  gap,
  main,
  end,
  vertical,
  spaced,
  fullLine,
  className,
  centered,
  wraped,
  ...props
}, ref) {
  const theme = useTheme()

  return (
    <Box
      {...props}
      ref={ref}
      display='flex'
      flexWrap={wraped ? 'wrap' : ''}
      justifyContent={end ? 'flex-end' : spaced ? 'space-between' : main ? 'center' : 'flex-start'}
      alignItems={(centered || !vertical) ? 'center' : ''}
      fullWidth={fullLine && !vertical}
      fullHeight={fullLine && vertical}
      sx={(vertical
        ? {
            '& > *:not(:first-child)': {
              marginTop: transformIndent(theme, gap ?? 'i4')
            }
          }
        : {
            '& > *:not(:first-child)': {
              marginLeft: transformIndent(theme, gap ?? 'i4')
            }
          })}
      flexDirection={vertical ? 'column' : 'row'}
    />
  )
}

export default forwardRef(Linely)
