import { autobind } from "core-decorators"
import { IHashMap } from "../../utils/types"
import { Event } from "../event"
import {
    compileUrl,
    nodeParamsToUrlParts,
    compileUrlQueryParamsAndHashFromUrl,
    getUrlPartsFromUrl,
    urlPartsRootNodeable,
    growOldUrlPats,
    urlPartsRootNodeles,
    checkNodeCorrect
} from "./helpers"
import { 
    PathNode,
    TQueryParams,
    TNodeParam,
    EURL_STYLE, 
    IUrlParts, 
    EURL_SET_TYPE 
} from "./"
import { EURL_SET_TYPE_EVENT_ONLY, TURL_SET_TYPE_EVENT } from "./types"
import { PathUrlStore } from "./UrlStore/PathUrlStore"
import { PathUrlStoreBrowser } from "./UrlStore/PathUrlStoreBrowser"

export interface IPathRouterOptions {
    urlStyle?: EURL_STYLE,
    autofixUrl?: boolean,
    urlStore?: PathUrlStore
}

@autobind
export class PathNodeRoot<TChilds extends IHashMap<PathNode<any>>> extends PathNode<TChilds> {
    public static get ROOT_NODE_NAME () {
        return '$'
    }

    public __compileGraph(router: PathRouter<any>) {
        this.__compileNode(PathNodeRoot.ROOT_NODE_NAME, undefined, router)
    }
}

@autobind
export class PathRouter<TGraph extends IHashMap<PathNode<any>>> {
    private _onSetUrl: Event<[TURL_SET_TYPE_EVENT]> = new Event()
    public get onSetUrl() {
        return this._onSetUrl
    }

    private _fullMatchedPaths: PathNode<any>[] = []
    public get fullMatchedPaths() {
        return this._fullMatchedPaths
    }

    private _url: string = ''
    public get url() {
        return this._url
    }

    private _urlStore: PathUrlStore = new PathUrlStoreBrowser()
    public get urlStore() {
        return this._urlStore
    }

    private _queryParams: TQueryParams = {}
    public get queryParams() {
        return this._queryParams
    }

    private _hash: string = ''
    public get hash() {
        return this._hash
    }

    private _urlParts?: IUrlParts
    public get __urlParts() {
        return this._urlParts
    }

    private _urlStyle: EURL_STYLE = EURL_STYLE.main
    public get urlStyle() {
        return this._urlStyle
    }

    private _autofixUrl: boolean = true
    public get autofixUrl() {
        return this._autofixUrl
    }
    
    private _root: PathNodeRoot<TGraph>
    public get root() { return this._root }
    
    public get state() { return {
        url: this.url,
        queryParams: this.queryParams,
        hash: this.hash,
    } }

    constructor(options: IPathRouterOptions, childNodes: TGraph) {
        this.initOptions(options)

        this.urlStore.__applyRouter(this)

        this._root = new PathNodeRoot({}, childNodes)
        this._root.__compileGraph(this)

        this.initGraphState()

        this.devLog()

        // TODO: запускать либо каждый раз в деврежиме либо только при билде
        checkNodeCorrect(this.root)
    }

    public setUrl(setType: EURL_SET_TYPE, newUrl: string) {
        if(setType === EURL_SET_TYPE.push) {
            this.urlStore.pushUrl(newUrl)
        }
        else {
            this.urlStore.replaceUrl(newUrl)
        }
        
        const [mainUrl, queryParams, hash] = compileUrlQueryParamsAndHashFromUrl(this.urlStore.url)

        const urlParts = getUrlPartsFromUrl(this.urlStyle, mainUrl, queryParams)

        this._url = mainUrl
        this._queryParams = queryParams
        this._hash = hash

        this.updateGraphState(
            urlParts as IUrlParts, 
            setType
        )
        
        if(this.autofixUrl) {
            this.fixUrl(mainUrl, queryParams)
        }

        return true
    }

    public pushUrl(url: string) {
        return this.setUrl(EURL_SET_TYPE.push, url)
    }

    public replaceUrl(url: string) {
        return this.setUrl(EURL_SET_TYPE.replace, url)
    }

    public setQueryParams(setType: EURL_SET_TYPE, queryParams: TQueryParams) {
        return this.setUrl(setType, this.getUrl([], queryParams))
    }

    public pushQueryParams(queryParams: TQueryParams) {
        return this.setQueryParams(EURL_SET_TYPE.push, queryParams)
    }

    public replaceQueryParams(queryParams: TQueryParams): boolean {
        return this.setQueryParams(EURL_SET_TYPE.replace, queryParams)
    }

    public setHash(setType: EURL_SET_TYPE, hash: string): boolean {
        return this.setUrl(setType, this.getUrl([], {}, hash))
    }

    public pushHash(hash: string) {
        return this.setHash(EURL_SET_TYPE.push, hash)
    }

    public replaceHash(hash: string) {
        return this.setHash(EURL_SET_TYPE.replace, hash)
    }

    public goNext() {
        this.urlStore.goNext()
    }

    public goBack() {
        this.urlStore.goBack()
    }

    public getUrl(nodeParams: TNodeParam[], queryParams?: TQueryParams, hash?: string) {
        return this.getUrlFromUrlParts(
            urlPartsRootNodeles(nodeParamsToUrlParts(this.__urlParts as IUrlParts, nodeParams)), 
            queryParams, 
            hash
        )
    }

    public __addFullMatchPath(pathNode: PathNode<any>) {
        this._fullMatchedPaths.push(pathNode)
    }

    private initOptions(options: IPathRouterOptions) {
        this._urlStyle = options.urlStyle ?? this.urlStyle
        this._autofixUrl = options.autofixUrl ?? this.autofixUrl
        this._urlStore = options.urlStore ?? this._urlStore
    }

    private getUrlFromUrlParts(urlParts: IUrlParts, queryParams?: TQueryParams, hash?: string) {
        // TODO: ЛЮТЫЙ КОСТЫЛЬ!!!! ПЕРЕДЕЛАТЬ!!!!
        // смысл в том что если мы уходим на другой урл - квери и хеш параметры должны сбрасываться, только если не были переданы новые параметры
        const isNewUrl = compileUrl(this.urlStyle, urlParts) !== window.location.pathname

        return compileUrl(
            this.urlStyle, 
            urlParts, 
            { ...(isNewUrl ? {} : this.queryParams), ...queryParams }, 
            hash || !isNewUrl ? this.hash : undefined
        )
    }

    private updateGraph(urlParts: IUrlParts) {
        const rootNodeableUrlParts = urlPartsRootNodeable(urlParts)

        this._fullMatchedPaths = []
        this.root.__updateUrl(rootNodeableUrlParts)

        this._urlParts = growOldUrlPats(rootNodeableUrlParts, this.root, 0)
    }

    private initGraphState() {
        const [mainUrl, queryParams, hash] = compileUrlQueryParamsAndHashFromUrl(this.urlStore.url)
        const urlParts = getUrlPartsFromUrl(this.urlStyle, mainUrl, queryParams)

        this._url = mainUrl
        this._queryParams = queryParams
        this._hash = hash

        this.updateGraph(urlParts)

        if(this.autofixUrl) {
            this.fixUrl(mainUrl, queryParams)
        }
    }

    private fixUrl(startUrl: string, startQueryParams: TQueryParams) {
        const fixedFullUrl = compileUrl(
            this.urlStyle, 
            urlPartsRootNodeles(this._urlParts as IUrlParts), 
            startQueryParams, 
            this.hash
        )

        if(fixedFullUrl === this.url) {
            return
        }

        this.urlStore.replaceUrl(fixedFullUrl)
        const [mainUrl, queryParams] = compileUrlQueryParamsAndHashFromUrl(fixedFullUrl)

        this._url = mainUrl
        this._queryParams = queryParams
    }

    private updateGraphState(urlParts: IUrlParts, setType: EURL_SET_TYPE) {
        this.updateGraph(urlParts)

        this.onSetUrl.invoke(setType)
    }

    public __onBackNextButton() {
        this.initGraphState()
        this.onSetUrl.invoke(EURL_SET_TYPE_EVENT_ONLY.browserBackNext)
    }

    private devLog() {
        // @ts-ignore
        if(!window.___PartRouter___) {
            // @ts-ignore
            window.___PartRouter___ = []
        }

        // @ts-ignore
        window.___PartRouter___.push(this)
    }
}
