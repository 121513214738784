import api from "../../endpoints"
import { Typography, Button, ButtonBase } from '@mui/material';
import Linely from "../../tools/Components/atoms/Linely"
import useEnObserve from "../../tools/hooks/endpoints/useEnObserve"
import LeftRight from "../../tools/Components/atoms/LeftRight";
import Box from "../../tools/Components/atoms/Box";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Icon from "../widgets/Icon";
import PathLink from "../../tools/Components/paths/PathLink";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getDateFormat1 } from "../../tools/utils/moment";
import { getCancelStatusLocaleKey, getReserveStatusLocaleKey, getTourStatus, tourDaysInfo } from "../../utils/helpers";
import usePathRouter from "../../tools/hooks/paths/usePathRouter";
import { usePaginatedData } from "../../hooks/usePaginatedData";
import TablePagination from "../widgets/TablePagination";
import ForwardIcon from '@mui/icons-material/Forward';
import ChairIcon from '@mui/icons-material/Chair';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ReserveStatus from "../widgets/ReserveStatus";
import CancelStatus from "../widgets/CancelStatus";

export const TouristBooking = ({ booking }) => {
  const { t } = useTranslation()
  
  const [router] = usePathRouter()

  const { startMoment, endMoment } = tourDaysInfo(booking.tour)

  const isReserve = ['prepayment', 'full'].indexOf(booking.status_payment) > -1 && getTourStatus(booking.tour).isComing

  const isCancel = ['cancellation', 'transfer'].indexOf(booking.status_payment) > -1

  return (
    <Box
      mx='-i1'
      fullWidth
    >
      <PathLink
        href={router.root.c.tours.c.one.getUrl({ tourId: booking.tour.id })}
      >
        <ButtonBase
          sx={{ 
            width: '100%', 
            justifyContent: 'flex-start',
            textAlign: 'left'
          }}
        >
          <LeftRight
            px='i1'
            py='i4'
            centered
          >
            <Box>
              <Typography>
                {booking.tour.title}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {getDateFormat1(startMoment)} - {getDateFormat1(endMoment)}
              </Typography>
            </Box>
            {isReserve && (
              <Linely>
                <ReserveStatus status={booking.status_payment} />
                <Typography>{t(getReserveStatusLocaleKey(booking.status_payment))}</Typography>
              </Linely>
            )}
            {isCancel && (
              <Linely>
                <CancelStatus status={booking.status_payment} />
                <Typography>{t(getCancelStatusLocaleKey(booking.status_payment))}</Typography>
              </Linely>
            )}
          </LeftRight>
        </ButtonBase>
      </PathLink>
    </Box>
  )
}

export const TouristToursInfo = ({ tourist }) => {
  const { t } = useTranslation()

  const [{ data }] = useEnObserve(api.tree.tourists.tours, tourist.id)

  const [tab, setTab] = useState()

  const [currentPage, setCurrentPage] = useState(0)

  const overCurentTours = useMemo(
    () => (data || [])
      .filter(({ status_payment, tour }) => ['prepayment', 'full'].indexOf(status_payment) > -1 && !getTourStatus(tour).isComing),
    [data]
  )

  const comingTours = useMemo(
    () => (data || [])
      .filter(({ status_payment, tour }) => ['prepayment', 'full'].indexOf(status_payment) > -1 && getTourStatus(tour).isComing),
    [data]
  )

  const canceledTours = useMemo(
    () => (data || [])
      .filter(({ status_payment }) => ['cancellation', 'transfer'].indexOf(status_payment) > -1),
    [data]
  )

  const waitingTours = useMemo(
    () => (data || [])
      .filter(({ status_payment }) => ['wait_list'].indexOf(status_payment) > -1),
    [data]
  )

  const displayTours = tab === 'overCurent'
    ? overCurentTours
    : tab === 'coming'
      ? comingTours
      : tab === 'canceled'
        ? canceledTours
        : waitingTours

  const [paginatedData, totalPages] = usePaginatedData(displayTours, 10, currentPage)

  const handlePageChange = useCallback(
    (_, val) => setCurrentPage(val),
    [setCurrentPage]
  )

  useEffect(
    () => {
      if(displayTours) {
        setCurrentPage(0)
      }
    },
    [displayTours]
  )

  useEffect(
    () => {
      if(comingTours.length > 0) {
        setTab('coming')
      }

      else if(waitingTours.length > 0) {
        setTab('waiting')
      }

      else if(overCurentTours.length > 0) {
        setTab('overCurent')
      }

      else {
        setTab('canceled')
      }
    },
    [waitingTours, overCurentTours, comingTours]
  )
  
  return data?.length > 0 && (
    <>
      <Box>
        <Typography
          variant='subtitle1'
        >
          {t('Tourist.toursInfo.title')}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          {t('Tourist.toursInfo.subtitle', { count: data.length })}
        </Typography>
      </Box>
      <Linely
        vertical
        gap='i2'
      >
        {data.length === 0
          ? (
            <Typography>
              {t('Tourist.toursInfo.noData')}
            </Typography>
          )
          : (
            <>
              <Linely>
                {overCurentTours.length > 0 && (
                  <Button
                    fullWidth
                    onClick={() => setTab('overCurent')}
                    color={tab === 'overCurent' ? 'primary' : 'inherit'}
                  >
                    <Linely
                      vertical
                      centered
                    >
                      <Icon
                        big
                        Component={ForwardIcon}
                        sx={{
                          transform: 'rotate(180deg)'
                        }}
                      />
                      <LeftRight
                        centered
                      >
                        <Typography
                          variant="body2"
                        >
                          {t('Tourist.toursInfo.overCurent')}
                        </Typography>
                        <Typography variant="body2">({overCurentTours.length})</Typography>
                      </LeftRight>
                    </Linely>
                  </Button>
                )}
                {comingTours.length > 0 && (
                  <Button
                    fullWidth
                    onClick={() => setTab('coming')}
                    color={tab === 'coming' ? 'primary' : 'inherit'}
                  >
                    <Linely
                      vertical
                      centered
                    >
                      <Icon
                        big
                        Component={ChairIcon}
                      />
                      <LeftRight
                        centered
                      >
                        <Typography
                          variant="body2"
                        >
                          {t('Tourist.toursInfo.coming')}
                        </Typography>
                        <Typography variant="body2">({comingTours.length})</Typography>
                      </LeftRight>
                    </Linely>
                  </Button>
                )}
                {waitingTours.length > 0 && (
                  <Button
                    fullWidth
                    onClick={() => setTab('waiting')}
                    color={tab === 'waiting' ? 'primary' : 'inherit'}
                  >
                    <Linely
                      vertical
                      centered
                    >
                      <Icon
                        big
                        Component={HourglassFullIcon}
                      />
                      <LeftRight
                        centered
                      >
                        <Typography
                          variant="body2"
                        >
                          {t('Tourist.toursInfo.waiting')}
                        </Typography>
                        <Typography variant="body2">({waitingTours.length})</Typography>
                      </LeftRight>
                    </Linely>
                  </Button>
                )}
                {canceledTours.length > 0 && (
                  <Button
                    fullWidth
                    onClick={() => setTab('canceled')}
                    color={tab === 'canceled' ? 'primary' : 'inherit'}
                  >
                    <Linely
                      vertical
                      centered
                    >
                      <Icon
                        big
                        Component={DisabledByDefaultIcon}
                      />
                      <LeftRight
                        centered
                      >
                        <Typography
                          variant="body2"
                        >
                          {t('Tourist.toursInfo.canceled')}
                        </Typography>
                        <Typography variant="body2">({canceledTours.length})</Typography>
                      </LeftRight>
                    </Linely>
                  </Button>
                )}
              </Linely>
              <Box>
                {paginatedData.map(booking => (
                  <TouristBooking
                    key={booking.id}
                    booking={booking}
                  />
                ))}
              </Box>
              <TablePagination
                end={totalPages}
                page={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          )
        }
      </Linely>
    </>
  )
}
