import { assert } from "../../../../utils/assert"
import { EndpointModelErrorCiclycLink } from "../../errors"
import { DstRc, DstSnRc } from "../../baseEndpoints"
import { EndpointModel } from "."



export type TEndpointModelId = number | string

export type TEndpointModelIdGeter = (modelData: any) => TEndpointModelId

export type TEndpointModalSubmodelsAndPaths = [string, EndpointModel][]

export type TEndpointModelInheritTheirToBase<Their, TBase> = (heir: Their, oldBase: TBase) => TBase

export type TEndpointModelInheritToBaseTheir<Their, TBase> = (base: TBase, oldHeir: Their) => Their

export type TEndpointModelEndpointAvailable = 
    DstSnRc<any, any, any, any, any, any> | 
    DstRc<any, any, any, any, any>



export const defaultGetId = <T extends { id: number }>(data: T) => data?.id

// нельзя линковаться циклически (1 линкуется на 2, 2 на 3 и 3 на 1. и любые аналоги)
export const checkCiclycLinks = (
    submodelsAndPaths: TEndpointModalSubmodelsAndPaths,
    model: EndpointModel,
    parentPath: string = ''
) => {
    for (const [path, subModel] of submodelsAndPaths) {
        const pathToModel = [parentPath, path].filter(el => el).join('.')

        assert(
            subModel !== model,
            new EndpointModelErrorCiclycLink(model, pathToModel)
        )

        checkCiclycLinks(
            subModel.submodelsAndPaths,
            model,
            pathToModel
        )
    }
}



// Подписываемся на эндпоинты конкретной данной модели
export const subscribeEndpointToModelEndpoints = (
    endpoint: TEndpointModelEndpointAvailable,
    model: EndpointModel,
    parentPath: string
) => {
    // TODO
}

// Подписываемся на эндпоинты конкретной данной модели и на эндпоинты её подмоделей рекурсивно
export const subscribeEndpointToModelSubmodels = (
    endpoint: TEndpointModelEndpointAvailable,
    model: EndpointModel,
    parentPath: string = ''
) => {
    for (const [path, subModel] of model.submodelsAndPaths) {
        const pathToModel = [parentPath, path].filter(el => el).join('.')

        subscribeEndpointToModelEndpoints(
            endpoint,
            subModel,
            pathToModel
        )

        subscribeEndpointToModelSubmodels(
            endpoint,
            subModel,
            pathToModel
        )
    }
}



// Когда создался новый эндпоинт и подписался на текущую модель
// Надо подписать эндпоинт на обновления всех эндпоинтов всех подмоделей
// Надо подписать эндпоинты всех подмоделей на обновления текущего эндпоинта
export const subscribeEndpointToModels = (
    endpoint: TEndpointModelEndpointAvailable,
    model: EndpointModel
) => {
    subscribeEndpointToModelSubmodels(endpoint, model)
}



// Когда подписавшийся ранее эндпоинт перестаёт существовать и отписывается от текущей модели
export const describeEndpointFromModels = (endpoint: TEndpointModelEndpointAvailable) => {
}
