import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import {
  priceToStr, 
} from '../../utils/helpers';

export const ReservePaidRenderer = ({ column, value: reserve }) => {
  const { t } = useTranslation()

  const paidStr = priceToStr(reserve.paid)
  const totalStr = priceToStr(column.tourPrice)

  return (
    <Typography variant='body1'>
      {column.tourPrice === reserve.paid
        ? paidStr
        : t('Common.countOf', { count: paidStr, total: totalStr })
      }
    </Typography>
  )
}
