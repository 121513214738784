import { createContext, forwardRef, useContext, useMemo } from "react";
import { PathNode } from "../../libs/paths";
import OpenDelayable from "../atoms/OpenDelayable";
import { PathRouterContext } from "./PathRouterProvider";
import { ErrorBoundary } from "react-error-boundary";

export interface IPathCaseContext { 
  opened: boolean 
  currentPath: PathNode<any> | null, 
  paths: PathNode<any>[] 
}

export const PathCaseContext = createContext<IPathCaseContext>({ opened: false, paths: [], currentPath: null })

export interface IPathCase { 
  opened: boolean 
  path: PathNode<any>
  children?: any,
  Component?: any,
  outerOpened?: boolean
  openable?: boolean
  removeOnClose?: boolean 
  removeOnCloseDelay?: number
}

function PathCase<T>(
  { 
    opened,
    path, 
    openable, 
    outerOpened,
    children: outerChildren,
    Component: OuterComponent,
    ...props
  }: IPathCase & T,
  ref: any
) {
  const { ErrorViewComponent } = useContext(PathRouterContext)

  const pathCaseContextValue = useContext(PathCaseContext)
  const { paths } = pathCaseContextValue

  const children = useMemo(
    () => outerChildren 
      ? (
        typeof outerChildren === 'function'
          ? (...props: any[]) => (
            <ErrorBoundary FallbackComponent={ErrorViewComponent}>
              {outerChildren(...props)}
            </ErrorBoundary>
          )
          : (
            <ErrorBoundary FallbackComponent={ErrorViewComponent}>
              {outerChildren}
            </ErrorBoundary>
          )
      )
      : outerChildren,
    [outerChildren, ErrorViewComponent]
  )
  
  const Component = useMemo(
    () => OuterComponent
      ? (props: any) => (
        <ErrorBoundary FallbackComponent={ErrorViewComponent}>
          <OuterComponent
            {...props}
          />
        </ErrorBoundary>
      )
      : OuterComponent,
    [OuterComponent, ErrorViewComponent]
  )

  const contextValue = useMemo(
    () => path && {
      opened,
      currentPath: path,
      paths: [...paths, path]
    },
    [opened, path, paths]
  )

  return (
    <PathCaseContext.Provider value={path ? contextValue : pathCaseContextValue}>
      <OpenDelayable
        {...props}
        opened={opened}
        showOnlyOpened={!openable}
        ref={ref}
        Component={Component}
      >
        {children}
      </OpenDelayable>
    </PathCaseContext.Provider>
  )
}

export default forwardRef(PathCase)
