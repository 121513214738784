import api from '../../../endpoints';
import router from '../../../paths';
import { remove } from '../../../tools/libs/paths/helpers';
import Dialog from '../../../ui/widgets/Dialog';
import { useCallback } from 'react'
import { Formik } from 'formik';
import TourModalForm from './TourModalForm';
import { tourFormSchema } from './tourFormSchema';

const CreateTour = ({ ...props }) => {
  const onClose = useCallback(
    ({ id }) => router.pushUrl(router.getUrl([
        [router.root.c.createTour, remove()],
        ...(id ? [[router.root.c.tours.c.one, { tourId: id }]] : [])
      ]
    )),
    []
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { data, error } = await api.tree.tours.create.request({
        ...values,
        start_date: values.start_date?.unix(),
        end_date: values.end_date?.unix(),
      })

      setSubmitting(false)

      if(error) {
        return
      }

      onClose(data)
    },
    [onClose]
  )

  return (
    <Dialog
      {...props}
      onClose={onClose}
      lTitle='Tour.createModal.title'
      lSubmitLable='Common.create'
      withoutBtns
    >
      <Formik
        initialValues={{}}
        validationSchema={tourFormSchema}
        onSubmit={onSubmit}
      >
        <TourModalForm
          lSubmitLable='Common.create'
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  )
}

export default CreateTour
